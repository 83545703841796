@import "../../theme";
@import "../../mixins";
@import "./slider-slick";
.modal-slider {
    height: 100%;
    .slick {
        &-slider {
            width: 100%;
            max-width: 100%;
            height: 100%;
        }

        &-list {
            height: 100%;
        }

        &-track {
            height: 100%;
        }

        &-arrow {
            width: 200px;
            color: #fff;
            height: 100%;
            background-position: 10% center;
            background-size: 32px;
            background-image: url("/images/icons/arrow-left-white.svg");

            &.slick-prev {
            }

            &.slick-next {
                transform: rotate(180deg) translate(0, 50%);
            }
        }

        &-slide {
            padding: 0 24px;
            filter: drop-shadow(0px 32px 64px rgba(17, 17, 17, 0.08));
            border-radius: 24px;

            * {
                height: 100%;
            }

            img {
                width: 100%;
                object-position: center;
                object-fit: cover;
            }
        }
    }
}

.slider-preview {
    margin: 40px 0;

    &__title {
        margin-bottom: 16px;
    }

    &__list {
        display: flex;
        cursor: pointer;
    }

    &__item {
        flex-basis: 220px;
        height: 128px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        flex-grow: 1;

        &:nth-child(2) {
            flex-basis: 94px;
            margin-left: 16px;
            flex-grow: 0;
        }

        &:nth-child(n + 3) {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__item-counter {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
            180deg,
            rgba(20, 20, 43, 0.15) 0%,
            rgba(20, 20, 43, 0.9) 100%
        );
    }
}

.slider-parent {
    max-width: calc(100vw - 16px);
    width: 100%;
    overflow: hidden;

    @include media($tablet-breakpoint) {
        max-width: calc(100vw - 48px);
    }

    @include media($desktop-breakpoint) {
        max-width: calc(100vw - 118px - 24px);
        min-width: calc(100vw - 118px - 24px);
        margin-left: auto;
    }
}

.slick-slider-about {
    max-width: none;
    margin: 0;

    &__item {
        display: block !important;

        @include media($tablet-breakpoint) {
            padding-left: 0;
            margin-left: 24px;
        }
    }

    .slick-track {
        display: flex;
        &::before,
        &::after {
            content: none;
        }
    }

    .slick-list {
        overflow: initial;
        margin: 0 15% 0 16px;

        @include media($tablet-breakpoint) {
            margin: 0 20% 0 0;
        }

        @include media($desktop-breakpoint) {
            margin: 0 180px 0 0;
        }

        @include media(1500) {
            margin: 0 150px 0 0;
        }
    }

    .slick-slide {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        cursor: grab;
        margin: 0 20px;

        &:nth-child(1) {
            @include media($tablet-breakpoint, down) {
                margin-left: 0;
            }
        }

        .img-container {
            display: flex;
            justify-content: center;
            max-height: 625px;

            @include media($desktop-breakpoint) {
                margin: auto;
            }

            img {
                @include shadow(medium);
                max-height: 100%;
                object-fit: contain;
                border-radius: 16px;

                @include media($desktop-breakpoint) {
                    max-height: 52.11vh;
                }
            }
        }

        p {
            margin: auto;

            @include media($desktop-breakpoint) {
                max-width: 13.8vw;
            }
        }
    }
}
