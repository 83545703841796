@import "../../theme";
@import "../../mixins";

.intro {
    margin-bottom: 32px;
    padding: 0 16px;

    @include media($tablet-breakpoint) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 64px;
        margin-top: calc(96px - 16px);
    }

    @include media($desktop-breakpoint) {
        margin-top: calc(160px - 16px);
    }

    &__logo {
        width: 142px;
        height: 64px;
        margin-bottom: 16px;

        @include media($tablet-breakpoint) {
            width: 212px;
            height: 96px;
            margin-bottom: 32px;
        }

        @include media($desktop-breakpoint) {
            width: 248px;
            height: 112px;
        }
    }

    &__text {
        @include font-style(h3);

        @include media($tablet-breakpoint) {
            text-align: center;
        }
    }
}
