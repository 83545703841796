@import "../../theme";
@import "../../mixins";

.game-created {
    &__link {
        margin-bottom: 24px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        border-radius: 0;

        @include media($tablet-breakpoint) {
            padding: 20px 16px;
            margin-bottom: 16px;
        }
    }

    &__goto {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        margin-bottom: 8px;
        color: $gs-medium-em;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            margin-bottom: 24px;
        }
    }

    &__goto-inner {
        display: flex;
        align-items: center;
    }


    &__left {
        > .person {
            margin-bottom: 40px;
            margin-left: 20px;
        }
    }
}
