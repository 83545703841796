@import "../../../theme";
@import "../../../mixins/font";

.form-input__control {
    display: flex;
    flex-flow: row nowrap;
    position: relative;

    &--file {
        justify-content: center;

        label {
            display: flex;
            align-items: center;
            cursor: pointer;

            input[type="file"] {
                position: absolute;
                z-index: -10;
                opacity: 0;
                width: 0;
            }
        }
    }

    &--picker {
        > .react-datepicker-wrapper {
            width: 100%;
        }
    }
}

.form-input_with-shadow {
    box-shadow: 0 4px 8px rgba(17, 17, 17, 0.06);
}

.form-input--special {
    .form-input__input {
        background-color: transparent;
    }

    .form-input__label {
        color: $gs-medium-em;
    }
}
