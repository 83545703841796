@import "../../theme";
@import "../../mixins";

.card {
    padding-top: 128px;
    @include shadow(medium);
    border-radius: 16px;
    overflow: hidden;

    //border: 1px solid white;
    background: {
        repeat: no-repeat;
        size: contain;
        color: rgba(20, 20, 43, 0.4);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        padding: 16px;
        background-color: $white;
        position: relative;
    }

    &__meta-list {
        display: flex;
        margin-top: -36px;
        margin-bottom: 24px;
        z-index: 1;

        @include media(360) {
            flex-wrap: wrap;
        }

        >* {
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    &__meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 8px 16px;
        border-radius: 12px;
        background-color: $white;
        align-items: start;
        @include shadow(small);

        p {
            text-align: center;
            margin-bottom: 4px;

            &:nth-child(1) {
                @include font-style(body2);
                color: $secondary-default;
            }

            &:nth-child(2) {
                @include font-style(caption);
                color: $gs-medium-em;
            }
        }

        &_paid {
            background-color: $primary-default;
        }
    }

    &__meta-day {
        text-transform: capitalize;
    }

    &__game-type {
        @include unstyled();
        @include font-style(subtitle);
        display: flex;
        align-items: center;
        color: $gs-high-em;
        margin-right: auto;

        &:hover,
        &:focus {
            color: $secondary-dark;
        }

        >.icon-container {
            width: 20px;
            height: 20px;
            margin-left: 20px;
        }
    }

    &__conditions {
        @include unstyled();
        display: flex;
        flex-direction: column;
        color: $gs-medium-em;
        margin-bottom: 32px;
        margin-left: 28-16px;

        >li {
            display: inline-flex;
            align-items: center;

            >.icon-container {
                width: 16px;
                height: 16px;
                margin-right: 22px;
            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .station-row {
                margin-bottom: 0;
            }

            .station-row:first-child {
                margin-top: 0;
            }

            .station-row:last-child {
                margin-bottom: 0;
            }
        }

        .station_li {
            display: inline-flex;
            align-items: flex-start;
        }
    }

    &__person {
        display: flex;
        margin-bottom: 32px;

        >* {
            align-self: center;
        }

        .person {
            &__avatar {
                width: 40px;
                height: 40px;
                margin: 0 16px 0 0;
            }

            &__right {
                flex-grow: 1;
            }

            &__name {
                display: flex;
                margin-bottom: 0;
                //grid-row: 1/3;

                p {
                    @include font-style(body2);
                    margin-bottom: 4px;
                }
            }
        }

        &--pos {
            cursor: pointer;

            .person {
                &__name {
                    //grid-row: 1/2;
                }

                &__position {
                    //grid-row: 2/3;
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: auto;
        gap: 16px;

        &--single {
            display: block;

            .btn {
                width: 100%;
            }

            >p {
                margin-top: 16px;
            }
        }

        >p {
            color: $gs-disabled !important;
        }

        >.btn-primary {
            box-shadow: none !important;
        }

        >.btn-secondary {
            border: none;

            &.shadow-medium-desktop-down {
                box-shadow: none !important;
            }
        }
    }

    &__price {
        display: inline-flex;
        align-items: center;
        margin-left: 20px;

        >.icon-container {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }

    &__price-count {
        @include font-style(body1);

        span {
            margin-right: 6px;
        }
    }

    &--closed {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            background-color: rgba(129, 127, 148, 0.38);
            height: 128px;
        }

        * {
            color: $gs-label !important;
        }

        .person__avatar {
            filter: grayscale(1);
        }

        .players-range__filler {
            background-color: rgba(129, 127, 148, 0.38) !important;
        }

        a {
            pointer-events: none;
        }

        .btn {
            background-color: rgba(129, 127, 148, 0.38) !important;
            pointer-events: none;
        }
    }

    &--participates {
        border: 1px solid $secondary-light;
    }
}

.cards {
    display: grid;
    grid-gap: 16px;
    flex-grow: 1;

    @include media(600, down) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @include media(768, down) {
        padding-right: 16px;
        padding-bottom: 16px;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    @include media($tablet-breakpoint) {
        padding-left: 24px;
        //margin-left: -24px;
    }

    @include media(768) {
        align-items: flex-start;
        grid-gap: 24px;
        grid-template-columns: repeat(2, 1fr);
        //padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 32px;
    }

    @include media($desktop-breakpoint) {
        grid-template-columns: repeat(3, 1fr);
    }
}

// animations

.level-info {
    width: 13.33px;
    height: 13.33px;
    margin-left: 8px;
    color: $gs-label;
}

$cards-animation-duration: 0.6s;

@include media($tablet-breakpoint) {
    .cards {
        >* {
            animation: card-anim-open $cards-animation-duration ease 0s;
        }
    }

    body.cards-anim-close {
        .cards {
            >* {
                animation: card-anim-close $cards-animation-duration ease 0s;
            }
        }
    }

    body.cards-anim-close {

        .not-found,
        .preloader-fix {
            display: none;
        }
    }
}

@keyframes card-anim-open {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes card-anim-close {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0);
    }
}