@import "../../theme";
@import "../../mixins";

.players-range {
    margin-bottom: 16px;

    &__progress-bar {
        display: flex;
        height: 4px;
        background-color: $gs-background;
        margin-bottom: 10px;
    }

    &__filler {
        border-radius: 6px;
    }

    &__item {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}
