@import "../../theme";
@import "../../mixins";

.two-step-input {


    &__button {
        @include unstyled();
        @include font-style(body1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 16px 0;
        flex-grow: 1;
        border: 2px solid transparent;
        min-height: 56px;

        @include media($tablet-breakpoint) {
            padding: 16px;
            min-height: 64px;
            background-color: $gs-background;
            border-radius: 8px;
        }

        .person {
            +.icon-container {
                display: none;
            }
        }

        .icon-container {
            margin-right: 20px;
            order: -1;
        }

        .form-input--invalid & {
            border-color: $error-default;
        }

        &_trainer {
            padding: 7px 16px;

            @include media($tablet-breakpoint) {
                padding: 7px 16px;
            }
        }
    }
}