@import "../../theme";
@import "../../mixins";

.tag {
    padding: 6px;
    background-color: $white;
    color: $gs-medium-em;
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;

    &_pointer {
        cursor: pointer;
    }

    & > *:not(:last-child) {
        margin-right: 6px;
    }

    &__icon {
        width: 16px;
        height: 16px;

        &_m-size {
            width: 14px;
            height: 14px;
        }
    }

    &__name {
        font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
    }

    &__controls {
        line-height: 1rem;
    }

    &__remove-control {
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        background: none;
        color: $gs-placeholder;
    }

    &_alert {
        color: $error-dark;
        background-color: $error-light;
    }
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    & > *:not(:last-child) {
        margin-right: 8px;
    }

    &__add {
        &-list {
        }
    }
}

.tag-list__add {
    position: relative;

    &-list {
        position: absolute;
        right: 0;
        margin-top: 8px;
        z-index: 15;
    }
}
