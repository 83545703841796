@import "../../theme";
@import "../../mixins";

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: $gs-background;
    color: $gs-medium-em;
    padding: 16px;
    width: 100%;

    transition: width 0.3s;

    @include media($tablet-breakpoint) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        background: none;
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        height: 72px;
        max-width: 72px;
        padding: 0 0 0 24px;
        z-index: 30;

        transition: max-width 0.5s;

        .sidebar-open & {
            //overflow-x: hidden;
            max-width: $sidebar-width-tablet;
        }
    }

    @include media($desktop-breakpoint) {
        grid-area: header;
        background: $gs-background;
    }

    &__title {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 8px;
    }

    &__slogan {
        @include font-style(body1);
        background-color: $gs-outline;
        padding: 16px 24px;
        border-radius: 0 32px 32px 32px;
        margin-bottom: 32px;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;

            transition: all 0.3s;

            .sidebar-open & {
                padding: 16px 24px;
                margin-bottom: 32px;
            }
        }
    }

    &__burger {
        position: relative;
    }

    &__logout {
        padding: 0 !important;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            opacity: 0;
            // display: block !important;
            display: none;
            transition: opacity 0.3s;
            .sidebar-open & {
                opacity: 1;
                display: block;
            }
        }
    }

    &--fixed {
        @include media($tablet-breakpoint, down) {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
}
