@import "../theme";
@import "../mixins";
@import "fonts";

html,
body {
    margin: 0;
    padding: 0;

    &.sidebar-open {
        @include media($desktop-breakpoint, down) {
            overflow-x: auto;
            overflow-y: hidden;

            @supports (-webkit-overflow-scrolling: touch) {
                /* CSS specific to iOS devices */
                -webkit-overflow-scrolling: touch;
            }
        }
    }
}

html {
    font-size: 16px;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent; //Safari iOS
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

body {
    @include font-style(body1);
    display: flex;
    min-height: 100vh;
    min-width: 320px;
    background: $gs-background;
}

input,
textarea,
select,
button {
    font-family: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    @include unstyled();
    color: inherit;
    cursor: pointer;
}

select {
    -webkit-appearance: none;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    /* for IE */
    -ms-appearance: none;
    appearance: none !important;

    &::-ms-expand {
        display: none;
    }
}

input[type="date"],
input[type="number"],
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

input[type="number"] {
    -moz-appearance: textfield !important; //for FF
}

input {
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
    }
}

input[type="date"] {
    width: 100%;
    min-height: 50px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a,
button {
    cursor: pointer;
}

fieldset {
    @include unstyled();
}

.container {
    @include container();
}

.app {
    display: flex;
    flex-grow: 1;

    @include media($tablet-breakpoint) {
        position: relative;
    }

    @include media($desktop-breakpoint) {
        display: grid;
        grid-template-rows: min-content minmax(0, 1fr);
        grid-template-columns: min-content 1fr;

        grid-template-areas:
            "sidebar main"
            "sidebar main";

        .header {
            display: none;
        }

        .modal-close &,
        .modal-open & {
            grid-template-columns:
                min-content
                calc(100vw - #{$sidebar-width-lg} - 15px);
        }
    }
}

.footer {
    display: none;
}

.unstyled {
    @include unstyled();
}

.fs {
    @each $fs, $i in $font-styles {
        &-#{$fs} {
            @include font-style(#{$fs});
        }
    }

    &-h1-h2 {
        @include font-style(h1);

        @include media($tablet-breakpoint) {
            @include font-style(h2);
        }
    }
}

.c {
    @each $name, $color in $colors {
        &-#{"" + $name} {
            color: #{$color};
        }
    }
}

.bg {
    &-white {
        background-color: $white !important;
    }

    &-primary-default {
        background-color: $primary-default !important;
    }

    &-primary-light {
        background-color: $primary-light !important;
    }
}

.shadow-medium-desktop-down {
    @include media($desktop-breakpoint, down) {
        @include shadow(medium);
    }
}

.shadow-large {
    @include shadow(large);
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.opacity-0 {
    opacity: 0;
}

input#avatar {
    -webkit-user-select: initial !important;
    cursor: pointer;
}

.adaptive-map {
    position: relative;
    min-height: 210px;

    @include media($tablet-breakpoint, down) {
        height: 100%;
        margin: 0 -16px;
        border-radius: 16px 16px 0 0;
        overflow: hidden;
    }

    .map {
        height: 564px;

        @include media($tablet-breakpoint, down) {
            height: 100%;
            max-height: none;
        }

        max-height: 65vh;
    }

    &__btn {
        position: absolute;
        bottom: 16px;
        width: 100%;
        left: 0;
        right: 0;
    }
}

.text {
    &_gs-high-em {
        color: $gs-high-em;
    }

    &_gs-label {
        color: $gs-label;
    }

    &_gs-label-disabled {
        color: $gs-disabled-alfa;
    }
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.double-block {
    display: flex;
    flex-wrap: wrap;

    @include media($desktop-breakpoint) {
        flex-basis: 50%;
    }

    & > * {
        flex-basis: 100%;
        box-sizing: border-box;
        margin-bottom: 30px;

        @include media($desktop-breakpoint) {
            flex-basis: 40%;
            flex-grow: 1;
            max-width: 50%;

            &:nth-child(2n) {
                margin: 0 0 30px 12px;
            }
            &:nth-child(2n + 1) {
                margin: 0 12px 30px 0;
            }
        }
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

//возможно скрыть скролл у списков игр
// #scrollableDiv {
//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

@import "utils";
@import "icons";
@import "btn";
@import "close";
@import "text-block";
@import "typography";
