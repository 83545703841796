@import "../../theme";
@import "../../mixins";

@import "ReactCrop.scss";

.ReactCrop {
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    &__image {
        max-height: calc(100vh - 132px - 80px - 24px - 24px);

        @include media($tablet-breakpoint) {
            max-height: 35vh;
        }
    }
}
