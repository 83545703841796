@import "../../theme";
@import "../../mixins";

.sportcourt {
    &__header {
        margin-top: 16px;
        padding: 48px 118px;
        background-color: #e3eeff;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        position: relative;

        @include media($desktop-breakpoint, down) {
            padding: 48px 23px 32px 45px;
        }

        @include media($tablet-breakpoint, down) {
            padding: 24px 16px;
            margin-top: 0;
            border-radius: 0;
        }

        &::after {
            @include media($tablet-breakpoint, down) {
                content: "";
                background-color: #e3eeff;
                width: calc(100% + 16px);
                height: 16px;
                position: absolute;
                bottom: -16px;
                left: -16px;
            }
        }
    }

    &__back {
        @include media($tablet-breakpoint) {
            width: 32px;
            height: 32px;
        }
    }

    &__title {
        color: #14142b;
        font-family: Comfortaa;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 64px;
        padding-top: 30px;

        @include media($desktop-breakpoint, down) {
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 0.25px;
        }

        @include media($tablet-breakpoint, down) {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15px;
            padding-top: 24px;
        }
    }

    &__header-btn-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media($tablet-breakpoint, down) {
            .btn--small {
                padding: 0;
            }
        }
    }

    &__header-container {
        padding-top: 8px;
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 24px;

        .sportcourts-card__info-item {
            color: $gs-medium-em;
        }

        .sportcourts-card__info {
            gap: 8px;
        }

        .sportcourts-card__info-text {
            @include font-style(body1);
        }

        @include media($tablet-breakpoint, down) {
            grid-template-columns: 1fr;
        }
    }

    &__header-price {
        color: $gs-medium-em;
        font-family: Comfortaa;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    &__header-pay {
        color: $gs-label;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-bottom: 8px;
    }

    &__content {
        background-color: $white;
        padding: 48px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        height: 100%;
        // position: relative;

        .school__slider-dots {
            margin-bottom: 0px;
        }

        // &::after {
        //     @include media($tablet-breakpoint, down) {
        //         content: "";
        //         background-color: #E3EEFF;
        //         width: 100%;
        //         height: 16px;
        //         position: absolute;
        //     }
        // }

        @include media($tablet-breakpoint, down) {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
    }

    &__subtitle {
        @include font-style(h3);
        margin-bottom: 16px;
    }

    &__info-container {
        width: 100%;
        padding: 0px 116px;

        @include media($desktop-breakpoint, down) {
            padding: 0px 44px;
        }

        @include media($tablet-breakpoint, down) {
            padding: 0px 16px;
        }
    }

    &__info-list {
        list-style: none;
        padding: 0;
        margin: 16px 0 0;
        display: grid;
        gap: 6px;
    }

    &__info-item {
        display: flex;
        gap: 20px;
        color: $gs-medium-em;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.5px;
        align-items: center;
    }

    &__about {
        color: $gs-medium-em;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin: 16px 0 0;
        white-space: pre-wrap;
    }

    &__caption {
        @include font-style(caption);
        text-transform: none;

        &_gray {
            color: $gs-label;
        }

        &_black {
            color: $gs-medium-em;
        }
    }

    &__playgrounds-tab {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__playground-tab {
        width: max-content;
        display: grid;
        justify-items: center;
        padding: 8px 16px;
        align-items: center;
        background-color: $gs-background;
        border-radius: 100px;

        &_active {
            background-color: $primary-default;
        }
    }

    &__table {
        width: 100%;
        margin: 0;

        thead {
            width: 100%;
        }
    }

    &__table-row {
        width: 100%;
        display: grid;
        grid-template-columns: 0.5fr repeat(7, 1fr) min-content;

        @include media($desktop-breakpoint, down) {
            grid-template-columns: 0.5fr repeat(5, 1fr) min-content;
        }

        @include media($tablet-breakpoint, down) {
            grid-template-columns: 0.5fr repeat(2, 1fr) min-content;
        }
    }

    &__table-date {
        @include font-style(body1);
        margin: 2px;

        &_gray {
            color: $gs-label;

            @include media($desktop-breakpoint, down) {
                @include font-style(caption);
            }
        }

        &_black {
            color: $gs-medium-em;
        }

        &_relative {
            position: relative;
        }
    }

    &__table-date-year {
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $gs-disabled;
        transform: rotate(-90deg);
        position: absolute;
        top: 8px;
        right: -15px;
    }

    &__table-body {
        background-color: $gs-background;
        width: 100%;
        padding: 0 116px;
        display: grid;
        user-select: none;

        @include media($desktop-breakpoint, down) {
            padding: 0 16px;
        }
    }

    &__table-head {
        width: 100%;
        padding: 0 100px;
        display: grid;
        position: sticky;
        top: 0;
        left: 0;

        @include media($desktop-breakpoint, down) {
            padding: 0 16px;
        }

        &_sticky {
            background-color: white;
            padding-top: 16px;
            padding-bottom: 16px;
            box-shadow: 0px 4px 8px 0px rgb(17 17 17 / 6%);
            z-index: 10;
        }

        .sportcourt__table-row {
            align-items: center;
        }
    }

    &__table-time {
        @include font-style(body1);
        color: $gs-medium-em;
        justify-self: center;
        align-self: center;

        &_small {
            @include font-style(caption);
        }
    }

    &__table-price {
        border-radius: 4px;
        background-color: #fff;
        padding: 12px;
        @include font-style(body1);
        color: $gs-label;
        margin: 2px;
        cursor: pointer;

        &_empty {
            background-color: #eaeaf3;
            cursor: default;
        }

        &_active {
            background-color: $primary-default;
            color: $gs-medium-em;
        }
    }

    &__booking {
        cursor: pointer;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0px;

        &_red {
            background-color: #ffd8d6;

            .sportcourt__booking-info-icon {
                color: #ff5252;

                &:hover {
                    color: #ff5252;
                }
            }
        }

        &_dark-red {
            background-color: #F9CAC7;

            .sportcourt__booking-info-icon {
                color: #ff5252;

                &:hover {
                    color: #ff5252;
                }
            }
        }

        &_blue {
            background-color: #e3eeff;

            .sportcourt__booking-info-icon {
                color: #579bff;

                &:hover {
                    color: #579bff;
                }
            }
        }

        &_dark-blue {
            background-color: #C4DCFF;

            .sportcourt__booking-info-icon {
                color: #579bff;

                &:hover {
                    color: #579bff;
                }
            }
        }
    }

    &__booking-day-from {
        border-top: solid 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin-top: 2px;
        position: relative;
        padding: 8px;

        &_red {
            border-top-color: #ff5252;
        }

        &_dark-red {
            border-top-color: #ff5252;
        }

        &_blue {
            border-top-color: #579bff;
        }

        &_dark-blue {
            border-top-color: #579bff;
        }
    }

    &__booking-day-to {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-bottom: 2px;
    }

    &__booking-info {
        overflow: hidden;
        position: absolute;
        @include font-style(caption);

        &_gray {
            color: $gs-label;
        }

        &_black {
            color: $gs-high-em;
        }
    }

    &__booking-info-header {
        display: flex;
    }

    &__booking-container {
        background: #fff;
        position: sticky;
        bottom: 0;
        width: 100%;
        box-shadow: 0px -4px 8px 0px rgb(17 17 17 / 6%);
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr min-content;
        gap: 26px;
    }

    &__booking-container-info {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;

        @include media($tablet-breakpoint, down) {
            grid-template-columns: 1fr;
        }
    }

    &__booking-price-container {
        @include media($tablet-breakpoint, down) {
            display: flex;
            align-items: end;
            gap: 8px;
        }
    }

    &__booking-title {
        color: $gs-medium-em;
        font-family: Comfortaa;
        font-size: 24px;
        line-height: 32px;
        font-style: normal;
        font-weight: 700;

        &_small {
            font-size: 20px;
            line-height: 24px;
        }

        @include media($desktop-breakpoint, down) {
            font-size: 20px;
            line-height: 24px;
        }

        @include media($tablet-breakpoint, down) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &__booking-subtitle {
        @include font-style(body1);
        color: $gs-medium-em;

        @include media($tablet-breakpoint, down) {
            @include font-style(caption);
        }

        &_grey {
            color: #9F9EB0;
        }
    }

    &__booking-container-title {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__add-booking-info {
        border-radius: 16px;
        border: 1px solid #d9dbe9;
        padding: 24px;
        width: 100%;

        @include media($tablet-breakpoint, down) {
            padding: 16px;
        }
    }

    &__add-booking-additional {
        padding-top: 24px;
        margin-top: 24px;
        border-top: 1px solid #D9DBE9;
    }

    &__add-booking-content {
        display: grid;
        gap: 16px;
        grid-template-rows: max-content 1fr max-content;

        .form-input--textarea .form-input__control {
            height: 100%;
        }
    }

    &__add-booking-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }

        .fieldset__group {
            display: grid;
            grid-gap: 16px;
        }

        @include media($tablet-breakpoint, down) {
            gap: 32px;
        }
    }

    &__booking-hint {
        position: absolute;

        .react-tooltip {
            background-color: #14142bbf;
            color: white;
            border-radius: 16px;
            padding: 16px 24px;
            position: relative;
            min-width: 339px;
            width: max-content;
        }

        .react-tooltip-content-wrapper {
            .btn-group-row {
                gap: 10px;
            }

            .btn {
                margin-top: 8px;
            }
        }

        &-container {
            display: grid;
            gap: 8px;
        }

        &-title {
            @include font-style(h4);

            &__client {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
                margin-bottom: 8px;
            }
        }

        &-subtitle {
            @include font-style(body1);
            max-width: 387px;

            &_underline {
                text-decoration: underline dashed;
                text-underline-offset: 4px;
                text-decoration-thickness: 1px;
                cursor: pointer;
            }
        }

        &-info {
            display: flex;
            align-items: center;

            &_gap {
                gap: 16px;
            }

            &_blue {
                color: #579bff;
            }

            &_yellow {
                color: #ffc947;
            }
        }

        &-edit-btn {
            color: #FF9800;
            cursor: pointer;
        }
    }

    &__booking-section {
        border-radius: 16px;
        background: #f7f7fc;
        display: flex;
        padding: 24px;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media($tablet-breakpoint, down) {
            align-items: start;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
        }

        .btn-group-row {
            gap: 8px;

            .btn {
                max-height: 40px;
                width: max-content;

                @include media($tablet-breakpoint, down) {
                    width: unset;
                }
            }

            .btn--text {
                background-color: #f7f7fc;
                padding: 16px;
            }

            @include media($desktop-breakpoint, down) {
                flex-direction: column-reverse;
                align-self: start;
                align-items: end;
            }

            @include media($tablet-breakpoint, down) {
                flex-direction: row;
            }
        }

        &-details {
            display: grid;
            gap: 16px;
            margin: 16px 0;
        }
    }
}

.sportcourt-page {
    .content {
        min-height: min-content;
    }

    .sportcourt__content {
        height: initial;
        padding: 32px 0 0;

        @include media($tablet-breakpoint, down) {
            padding: 24px 0 0;
        }
    }

    .school__nav-bar {
        padding-top: 27px;
        padding-bottom: 20px;

        @include media($desktop-breakpoint, down) {
            padding-top: 6px;
            padding-bottom: 0;
        }
    }

    #schedule {
        @include media($desktop-breakpoint, down) {
            padding-top: 32px;
        }
    }
}