@import "../../theme";
@import "../../mixins";

.game-editor {
    position: relative;

    &__grid {
        display: grid;
        grid-gap: 16px;

        @include media($tablet-breakpoint, down) {
            margin-top: -8px;

            @supports not (grid-template-rows: min-content) {
                display: block;

                >* {
                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }
        }

        @include media($tablet-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
            align-items: flex-start;

            >.two-step-input,
            >.game-editor__radio-group--type,
            >.game-editor__gender,
            >.form-input--textarea,
            >.game-editor__toggles,
            >.game-editor__submit {
                grid-column: 1/-1;
            }
        }

        @include media($desktop-breakpoint) {
            grid-template-rows: repeat(6, auto);
            grid-template-columns: repeat(4, 1fr);
            align-items: flex-start;

            >.two-step-input,
            >.form-input--select,
            >.form-input--select-with-tooltip,
            >.form-input--date {
                grid-column: span 2;
            }

            >.game-editor__gender,
            >.game-editor__toggles {
                grid-column: 1/3;
            }

            >.game-editor__playground,
            >.game-editor__submit,
            >.form-input--textarea {
                grid-column: 3/5;
            }

            >.form-input--textarea {
                grid-row: 5/-1;
            }

            >.form-input--select-payment {
                grid-column: auto;
            }
        }

        &--training {
            @include media($desktop-breakpoint) {

                >.form-input--select-payment,
                >.form-input--select-with-tooltip {
                    grid-column: auto;
                }

                >.form-input--textarea {
                    grid-row: 6/-1;
                }

                &--with-pay {
                    >.form-input--date {
                        grid-column: auto;
                    }
                }
            }
        }
    }

    &__radio-group {
        display: grid;
        grid-gap: 32px;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }

        @include media($tablet-breakpoint) {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 0;

            >* {
                width: max-content;
                margin-bottom: 32px;

                &:not(:last-child) {
                    margin-right: 40px;
                }
            }
        }

        &--type {
            margin-bottom: 16px;

            @include media($tablet-breakpoint) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 24px;
                margin-bottom: 0;

                >* {
                    margin-bottom: 16px;
                }
            }

            @include media($desktop-breakpoint) {
                display: grid;
                align-items: center;
                grid-template-columns: repeat(4, 1fr);
                min-height: 64px;

                >* {
                    margin-bottom: 0;

                    &:not(:last-child) {
                        margin-right: 40px;
                    }
                }
            }
        }
    }

    &__playground-btn {
        @include unstyled();
        @include font-style(body1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 20px;
        flex-grow: 1;
        border: 2px solid transparent;
        height: 56px;

        @include media($tablet-breakpoint) {
            min-height: 64px;
            background-color: $gs-background;
            border-radius: 8px;
        }

        .icon-container {
            margin-right: 20px;
        }

        .form-input--invalid & {
            border-color: $error-default;
        }
    }

    &__playground-text {
        .form-input--invalid & {
            color: $error-dark;
        }
    }

    &__gender {
        margin-bottom: 16px;
    }

    &__submit {
        @include media($tablet-breakpoint, down) {
            margin-top: 8px;
        }

        @include media($desktop-breakpoint) {
            margin-left: auto;
            width: fit-content;
        }
    }

    &__list {
        @include unstyled();
        color: $gs-medium-em;

        li {
            cursor: pointer;
            display: flex;
            align-items: center;

            padding: 16px 24px;
            border-radius: 8px;

            @include media($tablet-breakpoint) {}

            .icon-container {
                margin-right: 20px;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            >.btn-text {
                padding: 0 32px;
            }

            &:hover,
            &:focus {
                background-color: $gs-background;
            }
        }
    }

    &__place {
        display: flex;
        margin-right: 36px;
        flex-grow: 1;

        @include media($tablet-breakpoint, down) {
            margin-right: 0;
            justify-content: space-between;
        }
    }

    .transparent-primary {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        color: $secondary-default;
        text-transform: uppercase;
    }

    &__place-text {
        >p:first-child {
            margin: 0;

            @include media($tablet-breakpoint) {
                margin-right: 20px;
            }
        }

        @include media($tablet-breakpoint) {
            display: flex;

            flex-grow: 1;
            align-items: baseline;
        }
    }

    &__add-btn {
        position: sticky;
        bottom: 16px;

        @supports not (position: sticky) {
            position: fixed;
        }

        @include media($tablet-breakpoint) {
            bottom: 24px;
        }

        @include media($desktop-breakpoint) {
            width: fit-content;
            margin-top: 20px;
            margin-left: auto;
            bottom: 56px;
        }
    }

    &__place-grid {
        display: grid;
        grid-gap: 32px;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }

        >.btn-group {
            .btn {
                width: 100%;
            }
        }

        @include media($tablet-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
            align-items: flex-start;

            >* {
                grid-column: 1/-1;
            }

            >.form-input--title {
                grid-column: 1/1;
            }

            >.form-input--special {
                grid-column: 1/2;

                @include media(900, down) {
                    grid-column: 1/-1;
                }
            }

            >.form-input-phone {
                grid-column: 2/2;
            }
        }

        @include media($desktop-breakpoint, down) {
            >* {
                grid-column: 1/-1;
            }

            >.btn-group,
            >.image-loader-wrapper,
            >.game-editor__options,
            >.label-field {
                grid-column: 1/-1;
            }

            >.btn-group {
                margin-left: auto;
            }
        }
    }

    &__sportcourts-grid {
        display: grid;
        grid-gap: 32px 24px;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }

        >.btn-group {
            .btn {
                width: 100%;
            }
        }

        .game-editor__save-btn {
            display: flex;
            gap: 24px;
            justify-self: end;
        }

        .fieldset__group-top {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
            align-items: flex-start;
            display: grid;

            @include media($desktop-breakpoint, down) {
                grid-template-columns: 1fr;
            }
        }

        @include media($tablet-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 32px 24px;
            align-items: flex-start;

            >* {
                grid-column: 1/-1;
            }

            >.sportcourts__address-input {
                grid-column: 1/1;
            }

            >.form-input--title {
                grid-column: 1/1;
            }

            >.form-input--special {
                grid-column: 1/2;

                @include media(900, down) {
                    grid-column: 1/-1;
                }
            }

            >.form-input-phone {
                grid-column: 2/2;
            }

            .add-btn {
                grid-column: 1/1;
            }

            .fieldset__group {
                gap: 16px;
            }
        }

        @include media($desktop-breakpoint, down) {
            grid-gap: 24px;

            >* {
                grid-column: 1/-1;
            }

            >.sportcourts__address-input,
            >.form-input--title,
            >.form-input-phone {
                grid-column: 1/-1;
            }

            >.btn-group,
            >.image-loader-wrapper,
            >.game-editor__options,
            >.label-field {
                grid-column: 1/-1;
            }

            >.btn-group {
                margin-left: auto;
            }

            .form-input {
                flex-grow: 1;
                width: 100%;
                font-size: inherit;
                resize: none;
                min-height: 56px;
                border: 2px solid transparent;
                border-radius: 8px;
                outline: none;
            }

            .game-editor__playground-text {
                color: $gs-placeholder;
            }

            .fieldset__group {
                gap: 8px;
                display: flex;
                flex-wrap: wrap;
            }
        }

        .add-btn {
            @include media($tablet-breakpoint, down) {
                width: 100%;
            }
        }
    }

    &__sportcourt-container {
        border-radius: 16px;
        border: 1px solid $gs-outline;
        padding: 32px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;

        @include media($desktop-breakpoint, down) {
            padding: 32px;
            grid-template-columns: 1fr max-content;
            gap: 0;

            .fieldset__legend {
                margin-bottom: 8px;
            }
        }

        @include media($tablet-breakpoint, down) {
            padding: 16px;
        }

        .form-input--name {
            grid-column: 1/4;
        }

        .fieldset {
            grid-column: 1/5;
        }

        .btn--reset {
            justify-self: flex-start;
        }

        .delete-icon {
            color: #D9DBE9;
        }

        .game-editor__playground-name {
            font-family: Comfortaa;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: $gs-medium-em;

            @include media($tablet-breakpoint, down) {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
            }
        }

        &_modal {
            border: none;
            padding: 0;
            grid-template-columns: repeat(2, 1fr);

            .game-editor__type {
                grid-column: 1/4;
            }

            .btn-group-col {
                grid-column: 1/4;
                grid-template-columns: 1fr;

                .btn-price {
                    padding: 16px 32px;
                }
            }

            @include media($desktop-breakpoint, down) {
                .game-editor__type {
                    grid-column: 1/5;
                }

                .btn-group-col {
                    grid-column: 1/5;
                }
            }

        }

        &_intervals {
            display: grid;
            grid-template-columns: max-content 1fr;
            gap: 24px;
            grid-column: span 2;

            @include media($tablet-breakpoint, down) {
                gap: 8px;
                grid-template-columns: 1fr min-content;
            }

            .items {
                display: grid;
                gap: 8px;
            }

            .time-item {
                @include font-style(body1);
                color: $gs-medium-em;
            }

            .price-item {
                @include font-style(body1);
                color: $gs-medium-em;
                font-weight: 700;

                span {
                    font-weight: 400;
                    color: $gs-label;
                }
            }
        }

    }

    &__sportcourt-price {
        display: grid;
        grid-template-columns: repeat(4, 1fr) max-content;
        gap: 24px;
        align-items: center;

        .icon {
            color: #D9DBE9;
        }

        @include media($desktop-breakpoint, down) {
            grid-template-columns: 1fr 1fr;
            grid-column: 1/4;

            .form-input--select--multi,
            .form-input--price,
            .line {
                grid-column: 1/4;
            }

            .form-input--time-to {
                grid-column: 2/4;
            }
        }

        @include media($tablet-breakpoint, down) {
            grid-template-columns: 1fr;
            grid-column: 1/5;

            .form-input--select--multi,
            .form-input,
            .form-input--price,
            .line {
                grid-column: 1/5;
            }

            .form-input--time-to {
                grid-column: 1/5;
            }
        }

        .line {
            border: 1px solid #D9DBE9;
            width: 100%;
        }
    }

    &__sportcourt-error {
        color: #C50E29;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        padding-top: 8px;
    }

    &__sportcourt-services {
        display: grid;
        gap: 16px;

        .icon {
            color: #D9DBE9;
        }

        .game-editor__service-item {
            display: grid;
            grid-template-columns: 3fr 1fr max-content;
            gap: 24px;

            &:last-child {
                margin-bottom: 16px;
            }

            @include media($desktop-breakpoint, down) {
                gap: 16px;
                justify-items: end;
                grid-template-columns: max-content 1fr max-content max-content;
            }
        }

        .game-editor__service-title {
            color: #14142BBF;
        }
    }

    &__toggles {
        @include media($desktop-breakpoint) {
            margin-top: 16px;
        }
    }

    &__wrapper {
        display: flex;
        flex-grow: 1;

        >.infinite-scroll-component__outerdiv {
            flex-grow: 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            >.infinite-scroll-component {
                flex-grow: 1;
            }
        }
    }

    .form-input--textarea {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            margin-top: -32px;
        }
    }

    .top-info {
        padding-bottom: 46px;
        border-bottom: 1px solid #D9DBE9;
        margin-bottom: 16px
    }
}

.station {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media($tablet-breakpoint, down) {
        align-items: flex-start;
        margin-top: 8px;
    }

    &-row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        width: 100%;

        span {
            margin-right: auto;
            color: #14142B;
        }

        svg {
            cursor: pointer;
            width: 18px;
            height: 18px;
        }

        @include media($tablet-breakpoint, down) {
            margin-top: 8px;
        }
    }

    &_detail {
        display: block;

        span {
            font-size: 12px;
            color: $gs-label;
        }

        .profile__value {
            display: block;
        }

        .icon-container {
            .station {
                &-row:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-row:first-child {
        margin-top: 8px;
    }

    button {
        @include media($tablet-breakpoint, down) {
            margin-left: 14px;
        }
    }

    &-marker {
        min-width: 10px;
        min-height: 10px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.input-comment {
    width: 397px;
    position: absolute;
    z-index: 10;
    bottom: 80px;
    background: rgba(20, 20, 43, 0.75);
    border-radius: 16px;
    color: white;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -32px;
        margin-left: -16px;
        border: 16px solid transparent;
        border-top: 16px solid rgba(20, 20, 43, 0.75);
    }

    & .input-comment-container {
        display: flex;
    }

    & .input-comment-text {
        padding: 16px 14px 16px 24px;
    }

    & .input-comment-btn {
        cursor: pointer;
        min-width: 16px;
        height: 16px;
        margin-top: 20px;
        margin-right: 28px;
        background-image: url("/images/icons/close-white.svg");
    }

    @include media($desktop-breakpoint, down) {
        right: 0;
    }

    @include media($tablet-breakpoint, down) {
        width: auto;
    }
}