@import "../theme";
@import "../mixins";

form.btn {
    padding: 0;
}

.btn {
    @include font-style(button);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    transition: all 0.3s;
    background: transparent;
    outline: none;
    border-radius: 40px;

    &--link {
        width: max-content;
        display: inline-flex;
    }

    &:disabled,
    &--disabled {
        cursor: default;
        box-shadow: none;
        pointer-events: none;
    }

    &--small {
        padding: 12px 16px;
    }

    &--medium {
        padding: 20px 32px;
    }

    &--large {
        padding: 24px 32px;
    }

    &--extra-small {
        padding: 8px 16px;
    }

    &--primary {
        background-color: $primary-default;
        color: $gs-medium-em;

        &:hover {
            background-color: $primary-dark;
        }

        &:focus {
            opacity: 0.8;
            outline: none;
            box-shadow: 0 0 0 8px $primary-light;
        }

        &:disabled,
        .btn--disabled & {
            background-color: $gs-disabled;
            color: $gs-medium-em;
        }
    }

    &--secondary {
        background-color: $white;
        border: 2px solid $secondary-default;
        color: $secondary-default;

        &:hover {
            border-color: $secondary-dark;
            color: $secondary-dark;
        }

        &:focus {
            //opacity: 0.8;
            outline: none;
            box-shadow: 0 0 0 8px $secondary-light;
        }

        &:disabled,
        .btn--disabled & {
            border-color: $gs-disabled;
            color: $gs-disabled;
        }
    }

    &--text {
        color: $secondary-default;
        background-color: $white;

        &:hover {
            color: $secondary-dark;
        }

        &:focus {
            background-color: $gs-background;
        }

        &:disabled {
            color: $gs-disabled;
        }

        &.btn--small {
            background-color: initial;
        }

        &-padding-auto-0 {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--subtle {
        color: $secondary-default;
        border: 2px solid $gs-outline;
        background-color: $gs-surfaces;

        &:hover {
            color: $secondary-dark;
        }

        &:focus {
            //background-color: $gs-background;
        }

        &:disabled {
            color: $gs-disabled;
        }

        &--primary {
            border-color: $primary-default;
        }
    }

    &--full-width {
        width: 100%;
    }

    &--grey {
        border: 1px solid #D9DBE9;
        border-radius: 100px;
        background: #F7F7FC;
        text-transform: none;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 43, 0.75);
    }

    &--minimized {
        display: flex;
        align-items: center;
        padding: 16px 8px;
        margin-bottom: 8px;
        color: $gs-medium-em;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            margin-bottom: 24px;
        }

        &-active {
            color: $secondary-dark;
        }
    }

    &--auth {
        >.icon-container {
            margin-right: 24px;
        }
    }

    &--centered {
        margin: 0 auto;
    }

    &--with-count {
        .count {
            margin-left: 30px;
        }
    }

    &--reset {
        padding: 0;
    }

    &--no-flex {
        @include media($tablet-breakpoint) {
            width: fit-content;
        }

    }

    &__span {
        display: block;
        width: 100%;
        text-align: center;
        margin: auto;
    }

    &--tab {
        border: 1px solid $gs-outline;
        color: rgba(20, 20, 43, 0.75);
        font-weight: 400;
        text-transform: none;
        padding: 8px 16px;

        @include media($desktop-breakpoint) {
            &:hover {
                background-color: $gs-outline;
            }
        }

        &:disabled {
            color: $gs-label;
            background-color: $gs-outline;
        }

        &-active {
            background-color: $secondary-light;
            border: none;
        }
    }
}

.btn-group {
    &--bottom {
        @include media($tablet-breakpoint, down) {
            position: absolute;
            left: 16px;
            width: 100%;
            bottom: 12px;
            max-width: calc(100vw - 32px);

            .password-change & {
                position: fixed;
            }
        }
    }

    &--bottom-static {
        @include media($tablet-breakpoint, down) {
            margin-top: auto;
        }
    }

    &--code {
        display: grid;
        grid-row-gap: 24px;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    &--col-center {
        display: contents;

        @include media($tablet-breakpoint, down) {
            display: grid;
        }
    }
}

.btn-group-col {
    display: grid;
    justify-content: center;
    grid-gap: 16px;
    margin-top: 24px;

    @include media($desktop-breakpoint) {
        grid-template-columns: 1fr 1fr;

        >* {
            &:first-child {
                grid-column: 1/-1;
            }
        }
    }
}

.btn-group-row {
    display: flex;

    &--16 {
        justify-content: center;

        >*:not(:last-child) {
            margin-right: 16px;
        }
    }

    &--24 {
        justify-content: center;

        >*:not(:last-child) {
            margin-right: 24px;
        }
    }
}

.btn-group-col-row {
    display: flex;

    @include media($tablet-breakpoint, down) {
        flex-direction: column;
        align-items: center;

        >* {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    @include media($tablet-breakpoint) {
        justify-content: space-between;
    }
}

.btn-group-photo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    >* {
        @include media($tablet-breakpoint, down) {
            flex: 1 0 100%;
            max-width: 100%;

            &:first-child {
                margin-bottom: 16px;
            }
        }

        margin-left: 8px;
        margin-right: 8px;
    }
}