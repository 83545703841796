.add-city {
    form {
        width: 510px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;

        @include media($tablet-breakpoint, down) {
            width: auto;
        }

        .top-info {
            margin-bottom: 40px;
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        .form-input {
            grid-column-start: 1;
            grid-column-end: 4;
        }

        .btn {
            width: 155px;
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 4;
            grid-row-end: 5;
        }

        .form-input__prefix {
            top: 15px;

            @include media($tablet-breakpoint, down) {
                top: 10px;
            }
        }
    }
}
