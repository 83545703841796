@import "../../theme";
@import "../../mixins";

.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-left: 16px;
    padding-right: 16px;
    margin: 0 auto 0 auto;

    @include media($desktop-breakpoint) {
        margin-top: -120px;
        min-height: 100%;
    }

    @include media($tablet-breakpoint, down) {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
    }

    &--group {
        text-align: center;
        margin: 0 auto;

        @include media($tablet-breakpoint) {
            padding-left: 136px;
            padding-right: 136px;
            max-width: 952px;
        }

        > .icon-container {
            width: 80px;
            height: 80px;
            margin-bottom: 24px;

            @include media($tablet-breakpoint) {
                width: 120px;
                height: 120px;
            }
        }
    }
}
