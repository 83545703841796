@import "../../theme";
@import "../../mixins";

.cabinet {

    &__details-header {
        display: grid;
        // gap: 8px;
        margin-bottom: 24px;

        .btn--reset {
            width: fit-content;
            justify-self: center;
        }

        .btn--large {
            margin-top: 8px;
        }

        .fs-caption {
            text-align: center;
            margin-top: 8px;
        }
    }

    &__details-title {
        font-family: Comfortaa;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-align: center;

    }

    &__details-subtitle {
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $gs-label;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 4px;
    }

    &__details-admin {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $gs-medium-em;
        margin: 8px 0 16px;
    }

    &__details-admin-list {
        display: grid;
        gap: 12px;
    }

    &__details-list {
        margin-left: 16px;
        padding-top: 16px;

        &_item {
            font-family: 'Nunito Sans';
            line-height: 24px;
            color: #14142B;
        }

        .cabinet__details-list_number {
            margin-bottom: 20px;
        }
    }

    &__transactions-list {
        display: grid;
        // gap: 32px;
        margin: 0;
        padding: 0;
    }

    &__transactions-header {
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #9F9EB0;
    }

    &__transactions-item {
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        gap: 24px;
        padding: 16px 0;

        @include media($tablet-breakpoint, down) {
            grid-template-columns: 1fr max-content;
            gap: 0 8px;
        }

        &_header {
            grid-template-columns: 86px 1fr max-content;
            padding: 0;

            @include media($tablet-breakpoint, down) {
                grid-template-columns: 1fr max-content;
                justify-content: space-between;
            }
        }
    }

    &__item-container {
        display: grid;
        gap: 4px;
        height: min-content;

        @include media($tablet-breakpoint, down) {
            gap: 0;
        }
    }

    &__transactions-info {
        @include media($tablet-breakpoint, down) {
            grid-column: 1;

            .cabinet__transactions-title {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
            }
        }
    }

    &__transactions-summ {
        display: grid;
        justify-items: end;

        @include media($tablet-breakpoint, down) {
            grid-row: 1 / span 2;
            grid-column: 2;
        }
    }

    &__transactions-title {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $gs-medium-em;

        &_red {
            color: $error-default;
        }
    }

    &__transactions-subtitle {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: $gs-label;

        &_red {
            color: $error-default;
        }
    }
}