.balloon {
    border-radius: 16px;
    display: block;
    padding: 8px;
    background-color: white;
    width: 460px;
    max-height: 260px;
    z-index: 1000;
    position: relative;
    box-shadow: 0px 20px 24px 0px rgba(17, 17, 17, 0.06);

    .arrow {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-color: #fff transparent transparent transparent;
        border-style: solid;
        border-width: 9px 10px 0 10px;
    }

    &__inner {
        max-height: 246px;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 100%;
            border-radius: 4px;
            background-color: #F7F7FC;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            height: 80px;
            border-radius: 4px;
            background-color: #D9DBE9;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        gap: 8px;


        @include media($tablet-breakpoint, down) {
            display: flex;
            white-space: nowrap;
        }

    }

    &__card {
        display: grid;
        grid-template-columns: max-content 1fr;
        align-items: center;
        gap: 8px;
        position: relative;

        @include media($tablet-breakpoint, down) {
            min-width: 316px;
            grid-template-columns: 1fr;
            gap: 32px;
        }
    }

    &__card-img {
        background-image: url(../../../images/classic_volleyball.png);
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        width: 106px;
        height: 76px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            min-height: 100%;
            object-position: center;
            object-fit: cover;
        }

        @include media($tablet-breakpoint, down) {
            border-radius: 16px 16px 0px 0px;
            height: 176px;
            width: 100%;
        }

    }

    &__card-content {
        @include media($tablet-breakpoint, down) {
            padding: 16px 24px;

            .card__meta {
                position: absolute;
                padding: 8px 12px;
                top: 144px;

                p {
                    margin-bottom: 0;
                }

                p:nth-child(2) {
                    color: #9F9EB0;
                }
            }
        }
    }

    &__card-title {
        @include font-style(body1);
        color: $gs-medium-em;

        @include media($tablet-breakpoint, down) {
            @include font-style(body2);
            padding-bottom: 5px;
        }
    }

    &__card-subtitle {
        @include font-style(caption);
        color: $gs-label;
        display: flex;
        align-items: center;

        @include media($tablet-breakpoint, down) {
            &.games::before {
                content: "";
                display: inline-block;
                background-image: url('../../../images/icons/ball.svg');
                margin-right: 8px;
                width: 16px;
                height: 16px;
                background-size: cover;
                opacity: 0.31;
            }

            &.address::before {
                content: "";
                display: inline-block;
                background-image: url('../../../images/icons/location.svg');
                margin-right: 8px;
                width: 16px;
                height: 16px;
                background-size: cover;
                opacity: 0.31;
            }
        }

        &_active {
            color: $secondary-default;
        }
    }
}