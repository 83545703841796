@import "../../theme";
@import "../../mixins";

.filters {
    max-width: 920px;

    @include media($tablet-breakpoint, down) {
        display: flex;
        position: fixed;
        z-index: 20;
        top: 16px;
        right: 16px;
        left: 72px;
        transition: left 0.3s ease-out;

        .sidebar-open & {
            left: calc(284px + 32px);
        }
    }

    @include media($tablet-breakpoint) {
        width: 100%;
    }

    @include media($desktop-breakpoint) {
        grid-gap: 16px;
    }

    >.dropdown-parent {
        @include media($tablet-breakpoint, down) {
            display: none;
        }
    }

    &--login {
        max-width: none;
        grid-area: sport;

        @include media($tablet-breakpoint) {}

        >.dropdown-parent {
            display: block;
        }
    }

    &--school {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr max-content;
        gap: 16px;
    }

    &__radio-group {
        display: flex;
        padding-left: 8px;
        margin-bottom: 8px;
        flex-shrink: 0;
        grid-area: radio;

        >* {
            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }

    &__dates {
        display: grid;
        grid-template-columns: repeat(7, max-content);
        grid-area: dates;
        gap: 8px;
        margin-bottom: 16px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

}

.filters-parent {
    align-items: center;
    align-self: flex-start;
    padding-left: 24px;
    padding-right: 16px;
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 16px 40px;
    grid-template-areas:
        "sport radio"
        "dates dates";

    @include media($tablet-breakpoint) {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $gs-background;
        margin: -20px 0 44-16px 0;
        padding-top: 28px;
    }

    @include media($desktop-breakpoint) {
        margin-bottom: 56-16px;
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        .sidebar-open & {
            position: fixed;
            top: 20px;
            left: $sidebar-width-tablet + 16px;
            min-width: 100%;
            margin-left: -16px;
            padding-left: 24px;
        }
    }

    @include media($tablet-breakpoint, down) {
        grid-template-areas:
            "radio radio"
            "dates dates";
        position: sticky;
        top: 59px;
        left: 0;
        background: #f7f7fc;
        z-index: 200;
        gap: 0;
    }

    &--trainings {
        grid-template-areas: "sport dates";
        align-items: baseline;

        @include media(1440, down) {
            grid-template-areas:
                "sport"
                "dates";
            grid-template-columns: 1fr;
        }

        @include media($tablet-breakpoint, down) {
            gap: 16px;
            margin: 0;
            margin-bottom: 28px;
            top: 0;
            padding-top: 16px;
        }

        @include media($desktop-breakpoint) {
            margin: 0;
            margin-bottom: 28px;
        }

        .filters {
            @include media($tablet-breakpoint, down) {
                display: block;
                position: unset;
                z-index: 20;
                top: 16px;
                right: 16px;
                left: 72px;
                transition: left 0.3s ease-out;
            }
        }


        @include media($tablet-breakpoint, down) {
            .dropdown-parent .dropdown {
                background-color: $white;
                @include shadow(small);
                border-radius: 8px;
            }

            .dropdown-parent .dropdown__value-container {
                font-family: Roboto, "Arial", sans-serif;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.5px;
                padding: 14px 16px;
                max-height: unset;
            }

            .dropdown-parent .dropdown__indicators {
                height: unset;
            }
        }
    }

    &--school {
        grid-template-areas: "sport"
            "dates";
        top: 0;
        padding-top: 16px;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            .sidebar-open & {
                position: relative;
                top: 0px;
                left: 0px;
                min-width: unset;
                margin-left: 0px;
                padding-left: 0px;
            }
        }

    }
}