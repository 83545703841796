@import "../../theme";
@import "../../mixins";

.rc-menu-container {
    position: relative;
    width: 0;
    height: 0;
}

.rc-menu {
    @include shadow(medium);
    margin: 0;
    padding: 0;

    list-style: none;
    display: none;
    width: max-content;
    position: absolute;
    z-index: 100;

    user-select: none;
    color: $gs-high-em;
    border-radius: 8px;
    min-width: 10rem;

    margin-top: -42px;

    @include media($tablet-breakpoint) {
        margin-left: -42px;
        margin-top: -56px;
    }

    @include media($desktop-breakpoint) {
        margin-top: -42px;
        margin-left: -237px;
    }
}
.rc-menu:focus {
    outline: none;
}
.rc-menu--open,
.rc-menu--closing {
    display: block;
}
.rc-menu__arrow {
    box-sizing: border-box;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #fff;
    border: 1px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.1);
    border-top-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: -1;
}
.rc-menu__arrow--dir-left {
    right: -0.375rem;
    transform: translateY(-50%) rotate(135deg);
}
.rc-menu__arrow--dir-right {
    left: -0.375rem;
    transform: translateY(-50%) rotate(-45deg);
}
.rc-menu__arrow--dir-top {
    bottom: -0.375rem;
    transform: translateX(-50%) rotate(-135deg);
}
.rc-menu__arrow--dir-bottom {
    top: -0.375rem;
    transform: translateX(-50%) rotate(45deg);
}
.rc-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 12px 24px;
    border-radius: 8px;
    background-color: $white;
    max-width: 350px;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.rc-menu__item:focus {
    outline: none;
}
.rc-menu__item--hover {
    background-color: $gs-background;
}
.rc-menu__item--focusable {
    cursor: default;
    background-color: inherit;
}
.rc-menu__item--disabled {
    cursor: default;
    color: #aaa;
}
.rc-menu__submenu {
    position: relative;
}
.rc-menu__group {
    box-sizing: border-box;
}
.rc-menu__radio-group {
    margin: 0;
    padding: 0;
    list-style: none;
}
.rc-menu__divider {
    height: 1px;
    margin: 0.5rem 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.rc-menu-button {
    @include unstyled();
    width: 24px;
    margin-left: auto;
}

.rc-menu--dir-top {
    margin-top: 42px;

    > li {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4px;
        //margin-bottom: 4px;
    }
}

.rc-menu--open.rc-menu--dir-left {
    animation: rc-menu-show-slide-left 0.15s ease-out;
}
.rc-menu--closing.rc-menu--dir-left {
    animation: rc-menu-hide-slide-left 0.15s ease-in;
}
.rc-menu--open.rc-menu--dir-right {
    animation: rc-menu-show-slide-right 0.15s ease-out;
}
.rc-menu--closing.rc-menu--dir-right {
    animation: rc-menu-hide-slide-right 0.15s ease-in;
}
.rc-menu--open.rc-menu--dir-top {
    animation: rc-menu-show-slide-top 0.15s ease-out;
}
.rc-menu--closing.rc-menu--dir-top {
    animation: rc-menu-hide-slide-top 0.15s ease-in;
}
.rc-menu--open.rc-menu--dir-bottom {
    animation: rc-menu-show-slide-bottom 0.15s ease-out;
}
.rc-menu--closing.rc-menu--dir-bottom {
    animation: rc-menu-hide-slide-bottom 0.15s ease-in;
}

.rc-menu--animation .rc-menu__item {
    transition-property: background-color, color;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
}
.rc-menu__item--active {
    //color: #fff;
    //background-color: #007bff;

    font-weight: bold;
}
.rc-menu__item--type-radio {
    padding-left: 2.2rem;
}
.rc-menu__item--type-radio::before {
    content: "○";
    position: absolute;
    left: 0.8rem;
    top: 0.55rem;
    font-size: 0.8rem;
}
.rc-menu__item--type-radio.rc-menu__item--checked::before {
    content: "●";
}
.rc-menu__item--type-checkbox {
    padding-left: 2.2rem;
}
.rc-menu__item--type-checkbox::before {
    position: absolute;
    left: 0.8rem;
}
.rc-menu__item--type-checkbox.rc-menu__item--checked::before {
    content: "";
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.6088 5.2507C20.0647 5.63852 20.1319 6.33716 19.7589 6.81116L10.8064 18.1865C10.0825 19.1063 8.78987 19.2694 7.87533 18.5563L4.42673 15.8674C3.95545 15.5 3.85993 14.8049 4.21339 14.3149C4.56686 13.825 5.23544 13.7257 5.70672 14.0932L9.15533 16.7821L18.1078 5.40674C18.4808 4.93274 19.1528 4.86288 19.6088 5.2507Z' /%3E%3C/svg%3E%0A");
    font-weight: bold;
}

.rc-menu__submenu > .rc-menu {
    padding: 16px 0;
    margin: 0;
    position: static;
    min-width: 100%;
    background-color: #fff;
    margin-top: 4px;

    @include media($desktop-breakpoint) {
        margin-top: 0;
        margin-left: 4px;
        position: absolute;
    }
}

.rc-menu__submenu > .rc-menu__item {
    padding: 28px 60px 28px 24px;
    min-width: 277px;
}

.rc-menu__submenu > .rc-menu__item::after {
    content: "";
    position: absolute;
    right: 24px;
    width: 24px;
    height: 24px;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.32677 4.29425C7.76247 3.90192 8.46888 3.90192 8.90458 4.29425L16.6732 11.2896C16.8825 11.478 17 11.7336 17 12C17 12.2664 16.8825 12.522 16.6732 12.7104L8.90458 19.7058C8.46888 20.0981 7.76247 20.0981 7.32677 19.7058C6.89108 19.3134 6.89108 18.6773 7.32677 18.285L14.3065 12L7.32677 5.715C6.89108 5.32267 6.89108 4.68658 7.32677 4.29425Z' /%3E%3C/svg%3E%0A");
}

.rc-menu__header {
    color: #888;
    font-size: 0.8em;
    padding: 0.2rem 1.5rem;
    text-transform: uppercase;
}

@keyframes rc-menu-show-slide-left {
    from {
        opacity: 0;
        transform: translateX(0.75rem);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes rc-menu-hide-slide-left {
    from {
        opacity: 1;
        transform: none;
    }
    to {
        opacity: 0;
        transform: translateX(0.75rem);
    }
}
@keyframes rc-menu-show-slide-right {
    from {
        opacity: 0;
        transform: translateX(-0.75rem);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes rc-menu-hide-slide-right {
    from {
        opacity: 1;
        transform: none;
    }
    to {
        opacity: 0;
        transform: translateX(-0.75rem);
    }
}
@keyframes rc-menu-show-slide-top {
    from {
        opacity: 0;
        transform: translateY(0.75rem);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes rc-menu-hide-slide-top {
    from {
        opacity: 1;
        transform: none;
    }
    to {
        opacity: 0;
        transform: translateY(0.75rem);
    }
}
@keyframes rc-menu-show-slide-bottom {
    from {
        opacity: 0;
        transform: translateY(-0.75rem);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes rc-menu-hide-slide-bottom {
    from {
        opacity: 1;
        transform: none;
    }
    to {
        opacity: 0;
        transform: translateY(-0.75rem);
    }
}
