@import "../theme";
@import "../mixins";

.close {
    $size: 26px;
    $line-height: 2px;

    position: absolute;
    right: 10px;
    top: 10px;
    width: $size;
    height: $size;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $white;
    font-size: 0;
    cursor: pointer;
    outline: none;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 140%;
        color: inherit;
        height: $line-height;
        //border-radius: ;
        background: currentColor;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover,
    &:focus {
        opacity: 0.5;
    }

    > span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250%;
        height: 250%;
    }

}
