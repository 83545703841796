@import "../../theme";
@import "../../mixins";

$parallax-offset: 0;
$content-offset: 0;
$transition-speed: 1.2s;
$slide-number: 2;

.sheet {
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
}

.about--desktop .parallax-sheet {
    height: 100vh + $parallax-offset;
    will-change: transform;
    backface-visibility: hidden;

    transform: translateY($parallax-offset);
    @include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));

    background-color: $gs-background;
    color: $gs-medium-em;


}

/* Set stacking context of slides */
@for $i from 1 to ($slide-number + 1) {
    .parallax-sheet:nth-child(#{$i}) {
        z-index: ($slide-number + 1) - $i;
    }
}

.sheet__content {
    display: flex;
    margin: auto;
    flex-grow: 1;
    flex-flow: column nowrap;
    padding: 16px 8px;

    @include media($tablet-breakpoint) {
        padding: 24px;
        text-align: left;
    }

    .about--desktop & {

        @include transition(
            $transition-speed + 0.5,
            all,
            cubic-bezier(0.22, 0.44, 0, 1)
        );
    }

    &--first {
        margin-top: 0;
    }
}

// ------------- SET TRANSFORM VALUES ------------- //
/* behaviour for desktop browsers */
.about--desktop {
    .parallax-sheet.up-scroll {
        margin-top: -$parallax-offset / 2;

        + .parallax-sheet {
            margin-top: $parallax-offset;
        }
    }

    .parallax-sheet.down-scroll {
        margin-top: -(100vh + $parallax-offset);

        + .parallax-sheet:not(.down-scroll) {
            margin-top: -$parallax-offset / 2;
        }
    }
}
