@import "../../theme";
@import "../../mixins";

.react-tabs {
    &__tab-list {
        @include unstyled();
        display: flex;
        margin-bottom: 32-16px;

        @include media($tablet-breakpoint) {
            margin-bottom: 40-16px;
        }

        > * {
            min-width: 50%;

            @include media($tablet-breakpoint) {
                min-width: 200px;
            }
        }

        &--center {
            justify-content: center;
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;
            background: $white;
            z-index: 1;

            @include media($desktop-breakpoint) {
                padding: 15px 0;
            }
        }
    }

    &__tab {
        @include font-style(body2);
        color: $gs-label;
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0 16px 0;
        min-height: 50px;
        cursor: pointer;
        margin-bottom: 16px;
        outline: none;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        &:hover {
            color: $gs-medium-em;
        }

        &:focus {
            color: $gs-medium-em;
        }

        &--selected {
            color: $gs-high-em;

            &::before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 8px;
                width: 4px;
                height: 4px;
                background: $gs-high-em;
                border-radius: 50%;
                transform: translateX(-50%);
            }
        }

        &--h4 {
            @include font-style(h4);
            margin: 0;
            min-width: auto;

            &:not(:last-child) {
                margin-right: 24px;

                @include media($tablet-breakpoint) {
                    margin-right: 56px;
                }
            }
        }
    }
}
