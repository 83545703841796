@import "../../theme";
@import "../../mixins";

.notification {
    display: block;
    position: relative;
    margin-left: 16px;
    margin-bottom: 32px;
    cursor: pointer;

    @include media($tablet-breakpoint) {
        margin-left: 0;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
    }

    &.new {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: -16px;
            top: 4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $secondary-default;
        }

        @include media($tablet-breakpoint) {
            background-color: $gs-outline;

            &::before {
                content: none;
            }
        }
    }

    &__title {
        @include font-style(body2);
        color: $gs-medium-em;
    }

    &__time {
        @include font-style(caption);
        color: $gs-label;
    }

    &__content {
        > * {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    &__main {
        color: $gs-label;
    }

    &__footer {
        color: $gs-medium-em;
    }

    &__additional {
        > * {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    &__changed-field {
        color: $gs-label;

        .arrow {
            position: relative;
            top: -1px;
            font-size: 20px;
            line-height: 12px;
        }
    }

    &__new-field {
        @include font-style(body2);
    }

    &--not-menu {
        background-color: $secondary-light;
        border-radius: 16px;
        margin-left: 0;

        @include media($tablet-breakpoint, down) {
            padding: 12px 16px;
        }

        * {
            color: $gs-medium-em;
        }

        .notification {
            &__additional {
                @include font-style(caption);
            }

            &__new-field {
                @include font-style(caption);
            }
        }
    }
}

.notifications {
    margin-bottom: 16px;

    &:empty {
        margin-bottom: 0;
    }

    @include media($tablet-breakpoint) {
        margin-bottom: 40px;
    }

    @include media($desktop-breakpoint) {
        margin-bottom: 48px;
    }

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.notification-preloader {
    position: absolute;
    bottom: 20px;
    left: calc(50% + 35px);
}
