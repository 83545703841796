@import "../theme/colors";

.text-color-default {
    color: $secondary-default;
}

.text-size-14 {
    font-size: 14px;
}

.text-uppercase {
    text-transform: uppercase;
}
