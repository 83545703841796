@import "../../theme";
@import "../../mixins";

.logout {
    @include unstyled();
    position: relative;
    color: $gs-medium-em;
    padding: 12px 24px;


    &:hover,
    &:focus {
        color: $secondary-dark;
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        display: none;
    }


    &__link {
        @include unstyled();
        @include font-style(body2);
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        text-align: left;

        @include media($tablet-breakpoint) {
            @include font-style(caption);
        }

        > * {
            &:first-child {
                margin-right: 16px;

                @include media($tablet-breakpoint) {
                    margin-right: 10px;
                }

                @include media($desktop-breakpoint) {
                    margin-right: 0;
                }
            }
        }
    }
}
