@import "../../theme";
@import "../../mixins";

.players-stack {
    @include unstyled();
    display: flex;
    margin-right: 12px;

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        border: 2px solid $white;

        background: {
            image: url(/images/icons/camera.svg);
            repeat: no-repeat;
            position: center center;
            color: $primary-default;
        }

        &__small {
            width: 24px;
            height: 24px;
        }

        img {
            object-fit: contain;
            max-height: 100%;
            font-size: 0;
        }

        &:not(:first-child) {
            margin-left: -16px;
        }
    }
}
