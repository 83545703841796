@import "../../theme";
@import "../../mixins";

.image-loader {
    position: relative;
    display: grid;
    grid-gap: 16px;
    //grid-auto-rows: 128px;

    @include media($tablet-breakpoint) {
        //grid-auto-rows: 192px;
    }

    &--filled {
        grid-template-columns: repeat(auto-fit, minmax(218px, 100%));

        @include media($tablet-breakpoint) {
            grid-template-columns: repeat(auto-fit, minmax(218px, max-content));
        }
    }

    &__dnd-container {}

    &__thumb {
        display: flex;
        //align-items: center;
        justify-content: center;
        position: relative;
        background-color: #eceef3;
        border-radius: 8px;
        overflow: hidden;
        min-height: 100%;
    }

    &__holder {
        display: flex;
        height: 128px;
        max-width: 218px;

        @include media($tablet-breakpoint) {
            height: 192px;
            max-width: 330px;
        }

        img {
            object-fit: cover;
        }
    }

    &__delete {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 8px;
        top: 8px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $primary-default;
    }

    &__fav {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 58px;
        top: 8px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $gs-disabled-alfa;
        
        &_active {
            background-color: $primary-default;
        }
    }
}

.drag-and-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;

    border-radius: 8px;
    border: 2px dashed $gs-outline;
    background-color: $gs-background;

    padding: 36px 48px;

    @include media($tablet-breakpoint) {
        padding: 48px;
    }

    >p {
        color: $gs-placeholder;
        margin-bottom: 24px;
    }

    &--filled {
        display: none;
    }
}