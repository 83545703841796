@import "../../mixins";

.school-card {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 0px 32px;
    padding: 16px;

    &_edit {
        background: #F7F7FC;
        border-radius: 16px;
    }

    @media(max-width: 670px) {
        grid-template-columns: max-content 1fr;
        gap: 0px 16px;
    }


    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        overflow: hidden;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        flex-shrink: 0;
        background-image: url(/images/icons/camera.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #fff176;
        grid-row-start: 1;
        grid-row-end: 10;
        align-self: center;

        img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            object-fit: contain;
            overflow: hidden;
            font-size: 0;
            margin: -2px;
            max-height: 100%;
        }

        @media(max-width: 670px) {
            grid-row-start: 2;
            grid-row-end: 3;
        }

    }

    &__info {
        display: grid;
        gap: 8px;
        grid-row: 2;

    }

    &__title {
        @include font-style(body2);
        color: rgba(20, 20, 43, 0.75);
        overflow-wrap: anywhere;
    }

    &__types {
        padding: 0;
        list-style: none;
        display: flex;
        margin: 0;
        flex-flow: wrap;

        li:not(:last-child) {
            &:after {
                content: " ";
                width: 4px;
                height: 4px;
                background: #9F9EB0;
                border-radius: 50%;
                display: block;
                margin: 0 8px;
            }
        }
    }

    &__type {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #9F9EB0;
        display: flex;
        align-items: center;
    }


    &__review {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #FF9800;
        mix-blend-mode: normal;
    }

    &__container {
        display: flex;
        grid-row: 2;

        @include media($tablet-breakpoint, down) {
            grid-row: 3;
            grid-column: 2/span 2;
            flex-flow: wrap;
        }
    }

    &__links {
        display: flex;
        gap: 8px;

        &_tablet {
            gap: 16px;
        }
    }

    &__link {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 43, 0.75);
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &__status {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 43, 0.75);
        height: 24px;
        padding: 4px 8px;
        border-radius: 8px;
        width: max-content;

        &_public {
            background: #FFFFA8;

        }

        &_unpublic {
            background: rgba(255, 134, 127, 0.2);
        }
    }

    &__edit-container {
        grid-column: 2 / span 2;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__btn-group {
        display: flex;
        gap: 8px;

        @media(max-width: 1280px) {
            grid-row: 4;
            grid-column: 2 / span 2;
            margin-top: 12px;
        }

        @media(max-width: 670px) {
            grid-column: 1/ span 2;
            justify-content: center;
            margin-top: 16px;
        }
    }

    &__tablet-container {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__btn-tablet {
        text-transform: none;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding: 0 16px;
    }

    &__btn_no-hover {
        display: flex;
        align-items: center;

        .btn--text {
            &:hover {
                color: #ff9800;
            }
        }
    }

    &__mobile-menu {
        position: relative;
    }

    &__menu {
        position: absolute;
        right: 0px;
        background-color: white;
        box-shadow: 0px 20px 24px 0px #1111110F;
        border-radius: 8px;
        padding: 16px;
        z-index: 2;
        min-width: 277px;

        .btn {
            color: black;
            text-transform: none;
            font-weight: 400;
        }
    }
}

.schools-list {
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 16px;
    background-color: white;
    padding: 44px;
    display: grid;
    margin-bottom: 40px;

    @media(max-width: 670px) {
        padding: 0px;
    }

    &__head {
        display: grid;
        grid-template-columns: 1fr max-content;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @media(max-width: 670px) {
            grid-template-columns: 1fr;
            gap: 24px;
            align-items: start;
            padding: 16px;
        }

    }

    &__title {
        font-family: 'Comfortaa';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #14142B;
        mix-blend-mode: normal;

        @media(max-width: 670px) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__add-btn {
        padding: 16px 32px;
    }

    &__list {
        display: grid;
        gap: 4px;
        margin: 0;
        padding: 0;
    }
}