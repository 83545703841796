@import "../../theme";
@import "../../mixins";

.preloader {
    position: fixed;
    z-index: 999999;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    &-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 35px;
        width: 35px;

        //&::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: {
                image: url("/images/load.svg");
                size: 100%;
                repeat: no-repeat;
                position: 0 0;
            }
            -webkit-animation: rotate 1s linear infinite;
            animation: rotate 1s linear infinite;
        }

        &_basket {
            width: 20px;
            height: 20px;
            position: absolute;
            z-index: 100;
        }
    }

    &-boundary {
        position: relative;

        .preloader {
            position: absolute;
        }
    }
}

.preloader-fix {
    .preloader {
        background-color: $gs-background;

        &-boundary {
            position: absolute;
            top: 72+50px;
            left: 0;
            right: 0;
            bottom: 0;

            @include media($tablet-breakpoint) {
                top: 95px;
            }
        }
    }
}

.preloader-static {

    .preloader,
    .preloader-boundary,
    .preloader-spinner {
        position: static;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
