@import "../../theme";
@import "../../mixins";

.tooltip {
    position: relative;

    .react-tooltip {
        opacity: 1 !important;
        z-index: 100 !important;
    }

    .react-tooltip__place-left {
        &::before {
            content: "";
            position: absolute;
            right: -24px;
            top: 43%;
            border: 12px solid transparent;
            border-left: 12px solid #14142BBF;
        }
    }

    .react-tooltip__place-right {
        &::before {
            content: "";
            position: absolute;
            left: -24px;
            top: 43%;
            border: 12px solid transparent;
            border-right: 12px solid #14142BBF;
        }
    }

    .react-tooltip__place-top {
        &::before {
            content: "";
            position: absolute;
            bottom: -24px;
            left: 43%;
            border: 12px solid transparent;
            border-top: 12px solid #14142BBF;
        }
    }

    .react-tooltip__place-bottom {
        &::before {
            content: "";
            position: absolute;
            top: -24px;
            left: 43%;
            border: 12px solid transparent;
            border-bottom: 12px solid #14142BBF;
        }
    }

    .react-tooltip-arrow {
        display: none;
    }

    &-react-black-list {
        font-family: Roboto, "Arial", sans-serif !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 20px !important;
        letter-spacing: 0.5px !important;
        box-shadow: 0px 20px 24px rgb(17 17 17 / 6%) !important;
        background: rgba(20, 20, 43, 0.75) !important;
        color: #ffffff !important;
        padding: 16px 24px !important;
        border-radius: 16px !important;
        width: 350px !important;
        z-index: 1000;

        @include media($tablet-breakpoint, down) {
            left: 0 !important;
            right: 0 !important;
            width: calc(100vw - 32px) !important;
        }
    }

    &-invite-black-list {
        position: absolute;
        min-width: 350px !important;
        width: 450px !important;

        @include media($tablet-breakpoint, down) {

            .tooltip-react-black-list {
                width: calc(100vw - 48px) !important;
            }
        }

        .tooltip-black-list-container {
            display: grid;
            gap: 8px;
        }
    }

    &-react-level-type {
        font-family: Roboto, "Arial", sans-serif !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 20px !important;
        letter-spacing: 0.5px !important;
        box-shadow: 4px 4px 5px rgb(17 17 17 / 6%) !important;
        background: rgba(20, 20, 43, 0.75) !important;
        color: #ffffff !important;
        padding: 16px 24px !important;
        border-radius: 16px !important;
        max-width: 328px !important;
        z-index: 2 !important;

        @include media($tablet-breakpoint, down) {
            left: 25px !important;
            width: calc(100vw - 90px) !important;
        }
    }

    &__btn {
        @include unstyled();
        color: $gs-medium-em;
        position: relative;
        text-align: left;

        &::before {
            display: none;
            visibility: hidden;
            opacity: 0;

            content: "";
            position: absolute;
            width: 24px;
            height: 12px;
            transition: opacity 0.3s;

            background: {
                repeat: no-repeat;
                image: url("data:image/svg+xml,%3Csvg width='25' height='12' viewBox='0 0 25 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 0L24.5 12H0.5L12.5 0Z' fill='%2314142B' fill-opacity='0.75'/%3E%3C/svg%3E%0A");
            }

            @include media(700) {
                width: 12px;
                height: 24px;

                background: {
                    image: url("data:image/svg+xml,%3Csvg width='12' height='24' viewBox='0 0 12 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-5.19172e-07 12.1227L12 -5.24537e-07L12 24L-5.19172e-07 12.1227Z' fill='%2314142B' fill-opacity='0.75'/%3E%3C/svg%3E%0A");
                }
            }
        }

        &:hover,
        &:focus {

            &::before,
            ~.popup {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }

        &_placement {
            &-top {
                &::before {
                    left: calc(50% - 6px);
                    bottom: 100%;
                    transform: rotate(-90deg);

                    @include media($tablet-breakpoint, down) {
                        transform: rotate(-180deg);
                    }
                }
            }

            &-right {
                &::before {
                    left: 0;
                    top: 100%;

                    @include media(700) {
                        left: 36px;
                        top: auto;
                    }
                }
            }

            &-bottom {
                &::before {
                    left: calc(50% - 6px);
                    top: 100%;
                    transform: rotate(90deg);

                    @include media($tablet-breakpoint, down) {
                        transform: rotate(0deg);
                        left: calc(50% - 12px);
                    }
                }
            }

            &-left {
                &::before {
                    right: 0;
                    top: 100%;
                    transform: rotate(180deg);

                    @include media(700) {
                        left: calc(100% + 24px);
                        top: auto;
                    }
                }
            }
        }
    }

    &__popup {
        visibility: hidden;
        display: none;
        opacity: 0;
        transition: opacity 0.3s;

        &_top.popup {
            bottom: calc(100% + 18px);
            width: 320px;

            left: 50%;
            transform: translateX(-50%);

            @include media($tablet-breakpoint, down) {
                width: 100vw;
                bottom: calc(100% + 12px);
            }
        }

        &_right.popup {
            left: -100px;
            top: 36px;

            @include media($tablet-breakpoint) {
                width: 312px;
            }

            @include media(700) {
                left: calc(100% + 24px);
                top: 50%;
                transform: translateY(-50%);
                max-width: 312px;
            }
        }

        &_bottom.popup {
            top: calc(100% + 18px);
            width: 320px;

            left: 50%;
            transform: translateX(-50%);

            @include media($tablet-breakpoint, down) {
                width: 100vw;
                top: calc(100% + 12px);
                left: 80%;
            }
        }

        &_left.popup {
            right: 0;
            top: 36px;
            width: calc(100vw - 32px);

            @include media($tablet-breakpoint) {
                width: 312px;
            }

            @include media(700) {
                right: calc(100% + 24px);
                top: 50%;
                transform: translateY(-50%);
                max-width: 312px;
            }
        }
    }
}
