@import "../../theme";
@import "../../mixins";

.radio-check-box {
    display: block;

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        z-index: -10;
        opacity: 0;

        +label,
        +.radio-check-box__label {
            //@include font-style(caption2);

            color: $gs-medium-em;
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 0 0 16px+32px;
            min-height: 32px;
            cursor: pointer;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                box-sizing: border-box;
            }

            &::before {
                width: 32px;
                height: 32px;
                background: $gs-outline;
                border-radius: 50%;
            }

            &::after {}
        }

        &:hover {

            +.radio-check-box__label,
            +label {
                &::before {
                    background: $secondary-dark;
                }
            }
        }

        &:focus {

            +.radio-check-box__label,
            +label {
                &::before {
                    outline: none;
                    box-shadow: 0 0 0 8px $secondary-light;
                    background: $secondary-default;
                }
            }
        }

        &:disabled {
            background: $gs-disabled;
        }

        &:not(:checked) {
            &:hover {

                +.radio-check-box__label,
                +label {
                    &::before {
                        background: $gs-placeholder;
                    }
                }
            }

            &:focus {

                +.radio-check-box__label,
                +label {
                    &::before {
                        outline: none;
                        box-shadow: 0 0 0 8px $gs-background;
                        background: $gs-outline;
                    }
                }
            }

            &:disabled {
                background: $gs-disabled;
            }
        }

        &:checked {

            +.radio-check-box__label,
            +label {
                //color: gray;

                &::before {
                    background: $secondary-default;
                }
            }
        }
    }

    input[type="checkbox"] {

        &:checked {

            +.radio-check-box__label,
            +label {
                &::before {
                    border-color: $secondary-default;
                    background: $secondary-default;
                }

                &::after {
                    width: 16px;
                    height: 10px;
                    transform: translate(50%, 80%) rotate(-45deg);

                    border: {
                        top: 0;
                        right: 0;
                        left: 2px solid $white;
                        bottom: 2px solid $white;
                    }
                }
            }

            &:hover,
            &:focus {

                +.radio-check-box__label,
                +label {
                    &::before {
                        border-color: $secondary-dark;
                        background-color: $secondary-dark;
                    }
                }
            }
        }

        &:disabled {

            +.radio-check-box__label,
            +label {
                cursor: default;
                opacity: 0.3;

                &::before {
                    border-color: lightblue;
                    background: lightblue;
                }
            }

            &:checked {

                +.radio-check-box__label,
                +label {
                    &::after {
                        //background-image: url('/images/check-disabled.svg');
                    }
                }
            }
        }
    }

    input[type="radio"] {

        +.radio-check-box__label,
        +label {
            &::before {
                border-radius: 50%;
            }
        }

        &:checked {

            +.radio-check-box__label,
            +label {
                &::before {
                    border-color: $gs-outline;
                }

                &::after {
                    border-radius: 50%;
                    top: 8px;
                    left: 8px;
                    width: 16px;
                    height: 16px;
                    background: $white;
                }
            }

            &:hover {

                +.radio-check-box__label,
                +label {
                    &::before {
                        border-color: $secondary-dark;
                        background: $secondary-dark;
                    }

                    &::after {
                        background: $white;
                    }
                }
            }

            &:focus {

                +.radio-check-box__label,
                +label {
                    &::before {
                        border-color: $secondary-default;
                        background: $secondary-default;
                    }

                    &::after {
                        background: $white;
                    }
                }
            }
        }

        &:disabled {

            +.radio-check-box__label,
            +label {
                cursor: default;

                &::before {
                    border-color: lightblue;
                    //background: $light;
                    background: none;
                }
            }

            &:checked {

                +.radio-check-box__label,
                +label {
                    &::before {
                        border-color: lightblue;
                        background: transparent;
                    }

                    &::after {
                        background: lightblue;
                    }
                }
            }
        }
    }

    &--square-marker {

        input[type="radio"],
        input[type="checkbox"] {

            +.radio-check-box__label,
            +label {
                &::before {
                    border-radius: 8px;
                }
            }
        }
    }

    &--center {
        input[type="radio"] {

            +.radio-check-box__label,
            +label {

                &::before,
                &::after {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &:checked {

                +.radio-check-box__label::after,
                +label::after {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &--block {

        input[type="radio"],
        input[type="checkbox"] {

            +.radio-check-box__label,
            +label {
                display: block;
            }
        }
    }

    &--toggle {
        display: inline-flex;

        input[type="checkbox"] {

            +.radio-check-box__label,
            +label {
                display: inline-flex;
                align-items: center;
                padding-left: 0;

                &::before,
                &::after {
                    display: none;
                }

                .radio-check-box__icon {
                    position: relative;
                    display: block;
                    width: 64px;
                    height: 36px;
                    border-radius: 20px;
                    border: 1px solid $gs-outline;
                    background: $gs-outline;
                    margin: 0 10px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        display: block;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background: $white;
                        transition: background-color 0.3s, left 0.3s;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .radio-check-box__right {
                    @include font-style(body1);
                }
            }

            &:checked {

                +.radio-check-box__label,
                +label {
                    .radio-check-box__icon {
                        border-color: $secondary-default;
                        background-color: $secondary-default;

                        &::before {
                            left: 30px;
                        }
                    }
                }
            }
        }
    }

    &--btn {
        input[type="checkbox"] {

            +.radio-check-box__label,
            +label {
                padding: 8px 16px;
                background-color: #f7f7fc;
                border-radius: 100px;

                &::before {
                    display: none;
                }
            }

            &:checked {

                +.radio-check-box__label,
                +label {
                    background-color: $primary-default;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}


@import "radio-tab";