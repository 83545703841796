@import "../../../theme";
@import "../../../mixins";

.dropdown-parent {

    &--city {
        @include media($tablet-breakpoint, down) {
            margin-left: auto;
        }
    }

    .dropdown {
        @include media($tablet-breakpoint) {
            background-color: $white;
            @include shadow(small);
            border-radius: 8px;
        }

        &__control {
            color: $gs-high-em;
            padding: 0;
            font-size: inherit;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            outline: 0 !important;
            position: relative;
            transition: all 100ms;
            border: 2px solid transparent;
            background-color: transparent;
            min-height: 20px;
            box-shadow: none;

            &:hover {
                border: 2px solid transparent;

                @include media($tablet-breakpoint) {
                    border: 2px solid $primary-default;
                }
            }

            &--menu-is-open {
                z-index: 1;
                color: $gs-placeholder;
                border-color: transparent !important;

                @include media($tablet-breakpoint) {
                    border-color: $primary-default !important;
                }
            }
        }

        &__indicators {

            //margin-right: 15px;
            //margin-bottom: 5px;
            @include media($tablet-breakpoint, down) {
                height: 28px;
            }

            @include media($tablet-breakpoint) {
                margin-right: 20px;
            }
        }

        &__indicator {
            display: flex;
            padding: 0;
            margin-top: -5px;
        }

        &__value-container {
            @include font-style(h1);
            color: inherit;

            @include media($tablet-breakpoint, down) {
                max-height: 40px;
                max-width: calc(100vw - 72px - 68px);
                padding: 0;
            }

            @include media($tablet-breakpoint) {
                @include font-style(body1);
                padding: 14px 16px;
            }
        }

        &__input {
            @include media($tablet-breakpoint, down) {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &__input input,
        &__placeholder,
        &__single-value {
            padding: 0;
        }

        &__single-value {
            color: inherit;

            @include media($tablet-breakpoint, down) {
                position: static;
                max-width: none;
                top: 0;
                margin: 0;
                //min-width: 164px;
                transform: none;
            }
        }

        &__menu {
            position: absolute;
            background-color: $white;
            @include shadow(medium);
            z-index: 1;
            top: 100%;
            width: 100%;
            padding-right: 0;
            min-width: 250px;
            //right: 0;

            @include media($tablet-breakpoint) {
                margin: 4px 0 0 0;
                padding-right: 3px;
                border-radius: 8px;
                //border: {
                //    width: 0 1px 1px 1px;
                //    style: solid;
                //    color: $gs-placeholder;
                //}
            }

            &-list {
                max-height: 300px;
                overflow-y: auto;
                padding-bottom: 16px;
                padding-top: 16px;
                position: relative;
                //-webkit-overflow-scrolling: touch;
                box-sizing: border-box;


                @include media($tablet-breakpoint) {
                    max-height: 30vh;
                }

                //&::-webkit-scrollbar {
                //    width: 4px;
                //}
                //
                //&::-webkit-scrollbar-track {
                //    background: $white;
                //}
                //
                //&::-webkit-scrollbar-thumb {
                //    border-radius: 4px;
                //}
            }
        }

        &__option {
            display: block;
            text-align: left;
            width: 100%;
            padding: 12px 24px;
            color: $gs-high-em;
            cursor: pointer;
            background-color: transparent;

            @include media($tablet-breakpoint, down) {

                &:hover,
                &:focus {
                    background-color: $gs-background;
                }

                &:active {
                    background-color: transparent;
                }
            }

            &:hover,
            &:focus {
                font-weight: bold;
            }

            &:active {
                background-color: transparent;
            }

            &--is-selected {
                font-weight: bold;
            }
        }

        &--city {
            @include media($tablet-breakpoint, down) {
                min-width: 40px;

                background: {
                    repeat: no-repeat;
                    position: center center;
                    image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 7.71541C10.1925 7.71541 8.72727 9.17762 8.72727 10.9814C8.72727 12.7851 10.1925 14.2473 12 14.2473C13.8075 14.2473 15.2727 12.7851 15.2727 10.9814C15.2727 9.17762 13.8075 7.71541 12 7.71541ZM10.3636 10.9814C10.3636 10.0795 11.0963 9.34838 12 9.34838C12.9037 9.34838 13.6364 10.0795 13.6364 10.9814C13.6364 11.8832 12.9037 12.6143 12 12.6143C11.0963 12.6143 10.3636 11.8832 10.3636 10.9814Z' fill-opacity='0.75'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 2C7.02944 2 3 6.02109 3 10.9814C3 12.0539 3.37947 13.139 3.92161 14.1535C4.4674 15.1749 5.20759 16.1794 5.99859 17.1057C7.58031 18.9578 9.42866 20.5701 10.5241 21.4692C11.3864 22.1769 12.6136 22.1769 13.4759 21.4692C14.5713 20.5701 16.4197 18.9578 18.0014 17.1057C18.7924 16.1794 19.5326 15.1749 20.0784 14.1535C20.6205 13.139 21 12.0539 21 10.9814C21 6.02109 16.9706 2 12 2ZM4.63636 10.9814C4.63636 6.92296 7.93318 3.63297 12 3.63297C16.0668 3.63297 19.3636 6.92296 19.3636 10.9814C19.3636 11.683 19.1098 12.4957 18.6345 13.3851C18.1629 14.2678 17.5015 15.1735 16.756 16.0465C15.2647 17.7927 13.5002 19.3349 12.4364 20.208C12.1781 20.42 11.8219 20.42 11.5636 20.208C10.4998 19.3349 8.73533 17.7927 7.24403 16.0465C6.49851 15.1735 5.83714 14.2678 5.3655 13.3851C4.8902 12.4957 4.63636 11.683 4.63636 10.9814Z' fill-opacity='0.75'/%3E%3C/svg%3E%0A");
                }

                .dropdown {
                    &__control {
                        max-height: 40px;
                    }

                    &__indicators {
                        display: none;
                    }

                    &__value-container {
                        padding: 0;
                        font-size: 0;
                    }

                    &__menu {}

                    &__single-value {
                        min-width: 0;
                    }

                    &__option {}
                }
            }
        }
    }

    &_form {
        .dropdown {
            &__control {
                background: var(--grayscale-background, #F7F7FC);
                padding: 16px;
            }

            &__value-container {
                font-family: Roboto, "Arial", sans-serif;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.5px;
            }
        }
    }
}