@import "../../theme";
@import "../../mixins";

.group-preview {
    &_row {
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        justify-content: space-between;
    }

    &_clickable {
        cursor: pointer;
    }

    /*&__with-margin {
        margin: 16px 4px;
        @include media($tablet-breakpoint) {
            margin: 16px 20px;
        }
    }*/

    &__name {
        color: $gs-medium-em;
        /*font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;*/
        @include font-style(body2);

        margin-bottom: 8px;
    }

    &__info {
    }

    &-members {
        display: flex;
        align-content: center;
        align-items: center;

        &__list {
            margin-right: 8px;
        }

        &__count {
            color: $gs-medium-em;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
        }
    }
}
