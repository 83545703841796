@import "../../theme";
@import "../../mixins";

.sidebar-right {
    max-width: 378px;
    min-width: calc(26.25vw - 24px);

    @include media($desktop-breakpoint) {
        position: absolute;
        transition: margin-right 0.3s ease-out;
        margin-right: -378px;

        body.sidebar-right-open & {
            position: relative;
            margin-right: 0;
        }

        body.sidebar-right-close & {
            position: relative;
            margin-right: -378px;
        }
    }

    @include media(1440) {
        min-width: 378px;
    }

    &__close-wrapper {
        @include unstyled();
        position: absolute;
        right: 0;
        top: 24px;
        color: $gs-medium-em;
        display: flex;
        align-items: center;
        cursor: pointer;

        .close {
            color: inherit;
            right: 0;
            top: 0;
            width: 16px;
            height: 16px;
        }
    }

    &__close-text {
        margin-right: calc(8px + 24px);
        min-height: 24px;
    }

    &__inner {
        position: sticky;
        top: 0;
        padding: 104px 0 24px 24px;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar { width: 0; }
    }

    .profile__list {
        margin-left: 30px;

        &_wrapped {
            margin-top: 40px;
            margin-bottom: 40px;
            @include media($tablet-breakpoint, down) {
                margin-left: 16px;
            }
        }
    }

    .person {
        margin-left: 30px;
    }
}

.profile__list {
    &.profile__list_wrapped {
        @include media($tablet-breakpoint, down) {
            margin-left: 15px;
        }
        .profile__list li {
            grid-column-gap: 15px;
        }
    }
}

body.sidebar-right-open,
body.sidebar-right-close {
    @include media($desktop-breakpoint) {
        overflow-x: hidden;
        overflow-y: auto;

        .card-full {
            max-width: 100%;
        }

        .card-full__body {
            grid-template-columns: 100%;
        }

        .card-full__right-col {
            opacity: 0;
        }
    }
}

@keyframes translate-from-right {
    0% {
        right: 378px;
    }
    100% {
        right: 0;
    }
}

@keyframes translate-to-right {
    0% {
        right: 0;
    }
    100% {
        right: 378px;
    }
}
