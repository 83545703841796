@import "../../theme";
@import "../../mixins";

.popup {
    @include font-style(body1);
    @include shadow(medium);
    position: absolute;
    z-index: 1;
    background: $gs-medium-em;
    color: $gs-surfaces;

    padding: 16px 24px;
    border-radius: 16px;

    p {
        margin-top: 6px;
    }
}
