@import "../../theme";
@import "../../mixins";

.city-selector {
    margin-left: auto;

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        display: none;

        .sidebar-open & {
            display: block;
        }
    }

    @include media($tablet-breakpoint) {
        margin-left: 0;
    }

    &--logged {
        margin: 0 auto;
    }

    &__btn {
        @include media($tablet-breakpoint) {
            color: $secondary-default;
            padding: 5px;
            margin-bottom: 10px;

            .city-selector--logged & {
                margin-left: -20px;
            }

            &:hover {
                color: $secondary-dark;
            }

            &:focus {
                background-color: $gs-background;
            }
        }

        .icon {
            @include media($tablet-breakpoint) {
                margin-right: 15px;
            }
        }
    }

    &__btn-text {
        justify-content: start;
        padding: 0;
        margin: 0;

        &_active {
            color: $secondary-default;
            justify-content: start;
            padding: 0;
            margin: 0;

            &:hover {
                color: $secondary-dark;
            }
        }
      
        &:hover {
            color: $secondary-default;
        }
    }
}
