@import "../../theme";
@import "../../mixins";

.sidebar {
    display: flex;
    position: fixed;
    left: -100%;
    min-height: 100vh;
    width: 100%;
    max-width: $sidebar-width-lg;
    transition: left 0.5s;
    z-index: 9;

    &::-webkit-scrollbar {
        display: none;
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        max-width: $sidebar-width-lg;
        top: 75px;

        .sidebar__item {
            max-width: $sidebar-width-short;
        }
    }

    .sidebar-open & {
        @include media($tablet-breakpoint, down) {
            left: 0;
        }

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            max-width: $sidebar-width-lg;
            top: 75px;

            @media only screen and (max-height: 950px) {
                overflow-y: auto;
                overflow-x: hidden;
                height: 100vh;
            }

            .sidebar__item {
                max-width: $sidebar-width-lg;
            }

            .sidebar__item.active {
                background-color: $primary-light;
                color: $secondary-dark;
                max-width: $sidebar-width-lg;
            }

            .sidebar__line {
                margin: 8px 24px;
            }
        }
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        left: 0;
        top: 51px;
        transition: max-width 0.5s;
        z-index: 20;

        min-height: calc(100vh - 51px);

        max-width: calc(72px + 24px + 24px);
    }

    @include media($tablet-breakpoint, down) {
        overflow-y: auto;
        height: 100vh;
    }


    @include media($desktop-breakpoint) {
        grid-area: sidebar;
        position: static;
        top: 0;
        max-width: $sidebar-width-lg;
    }

    &--logged {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            top: 72px;
            max-width: 72px;

            &.sidebar .logo {
                display: none !important;
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        padding: 56+16px 16px 16px 16px;

        .sidebar-open & {
            min-width: $sidebar-width-lg;

            @media screen and (max-height: 1075px) {
                min-height: calc(100vh + 300px);
            }
        }

        @media screen and (max-height: 950px) {
            overflow-y: scroll;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @include media($tablet-breakpoint) {
            padding: 16px 0 0 16px;
            max-width: 72px;

        }

        @include media($desktop-breakpoint) {
            padding: 36px 24px 24px 24px;
            min-width: $sidebar-width-lg;
            height: 100vh;
            position: sticky;
            top: 0;

        }
    }

    &__logo {
        display: inline-block;
        vertical-align: top;
        width: 126px;
        height: 56px;
        margin-bottom: 16px;

        @include media($desktop-breakpoint) {
            margin-bottom: 24px;
        }

        .sidebar--logged & {
            width: 75px;
            height: 32px;

            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                display: none;
            }
        }
    }

    &__text {
        @include font-style(h3);
        margin-bottom: 16px;
    }

    &__actions {
        @include unstyled();
        margin-left: -16px;

        @include media($desktop-breakpoint) {
            margin-left: -24px;
            margin-right: -24px;
        }
    }

    &__item {
        position: relative;
        color: $gs-medium-em;
        padding: 12px 24px;

        @include media($tablet-breakpoint) {
            padding: 24px;
        }

        @include media($desktop-breakpoint) {
            padding: 12px 24px;
        }

        &:hover {
            color: $secondary-dark;
        }

        &.active {
            background-color: $primary-light;
            color: $secondary-dark;

            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                max-width: calc(72px + 24px + 24px);
            }

            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                max-width: 72px;
            }


            //box-shadow: 0 0 0 8px $secondary-light;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 4px;
                border-radius: 0 4px 4px 0;
                background-color: $secondary-default;
            }


        }

        &--create {
            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                padding-top: 12px;
                margin-right: 4px;
            }

            @include media($tablet-breakpoint) {
                .sidebar-open & {
                    font-size: 14px;
                    padding: 0 12px;
                    margin-top: 12px;
                    margin-bottom: 12px;
                }
            }

            @include media($desktop-breakpoint) {
                margin-top: 12px;
                margin-bottom: 12px;
            }
        }

    }

    &__link {
        @include unstyled();
        @include font-style(body2);
        display: flex;
        align-items: center;
        cursor: pointer;

        text-decoration: none;
        //margin-left: 15px;
        color: inherit;
        text-align: left;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            span {
                opacity: 0;
                max-height: 0;
                transition: all 0.5s;
                // position: absolute;
                display: none;

                .sidebar-open & {
                    opacity: 1;
                    max-height: none;
                    // position: initial;
                    display: block;
                }
            }
        }

        .icon {
            margin-right: 16px;
        }

        &--create {
            @include media($tablet-breakpoint) {
                @include font-style(button);
                border-radius: 40px;
                padding: 20px 32px;
                justify-content: center;
                background-color: $primary-default;
                color: $gs-medium-em;
                transition: all 0.5s;

                &:hover {
                    background-color: $primary-dark;
                }

                &:focus {
                    opacity: 0.8;
                    outline: none;
                    box-shadow: 0 0 0 8px $primary-light;
                }
            }

            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                .sidebar:not(.active) & {
                    display: flex;
                    justify-content: center;
                    width: 56px;
                    height: 56px;
                    padding: 0;
                    border-radius: 50%;
                    margin-left: -16px;

                    .icon {
                        margin-right: 0;
                    }
                }
            }
        }

        &--relative {
            position: relative;
        }

        .tooltip {
            &__popup {
                visibility: visible;
                opacity: 1;

                &_right.popup {

                    @include media-range(0, $tablet-breakpoint) {
                        top: 50%;
                        transform: translateY(45%);
                        width: auto;
                        left: 0;
                    }

                    @include media-range($tablet-breakpoint, 647) {
                        top: 50%;
                        transform: translateY(70%);
                        left: 0;
                    }

                    @include media-range(648, 1279) {
                        top: 0;
                        transform: translateY(0%);
                        left: calc(100% + 19px);
                    }

                    @include media(1280) {
                        top: 0;
                        transform: translateY(-15%);
                        left: calc(100% + 19px);
                    }

                    .close__popover {
                        width: 16px;
                        right: 20px;
                        top: 16px;
                        position: absolute;
                        height: 26px;
                        padding: 0;
                        border: 0;
                        background-color: transparent;
                        color: #ffffff;
                        font-size: 0;
                        cursor: pointer;
                        outline: none;

                        .icon {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }

        .tooltip__btn {
            &_placement {
                &-right {

                    width: 24px;
                    left: calc(100% + 1px);
                    height: 12px;
                    text-align: left;
                    visibility: visible;
                    position: absolute;
                    opacity: 1;

                    background: {
                        repeat: no-repeat;
                        image: url("data:image/svg+xml,%3Csvg width='25' height='12' viewBox='0 0 25 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 0L24.5 12H0.5L12.5 0Z' fill='%2314142B' fill-opacity='0.75'/%3E%3C/svg%3E%0A");
                    }

                    @include media-range(0, $tablet-breakpoint) {
                        left: calc(25%);
                        top: 113%;
                        z-index: 1;
                    }

                    @include media-range($tablet-breakpoint, 647) {
                        left: calc(25%);
                        top: 104%;
                        z-index: 1;
                    }

                    @include media(648) {
                        top: auto;
                        left: calc(100% + 1px);
                        transform: rotate(270deg) translateX(40%)
                    }
                }
            }
        }
    }

    &__create-text {
        text-align: center;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            font-size: 14px;
            white-space: nowrap;

            .sidebar:not(.active) & {
                display: none;
            }
        }
    }

    &__logout {
        position: relative;
        color: $gs-medium-em;
        padding: 12px 24px;

        &:hover,
        &:focus {
            color: $secondary-dark;
        }

        @include media($tablet-breakpoint) {
            display: none;
        }

        @include media($tablet-breakpoint) {
            position: absolute;
            right: 16px;
            top: -50px;
            flex-direction: row-reverse;
        }

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            .sidebar:not(.active) & {
                display: none;
            }
        }

        @include media($desktop-breakpoint) {
            display: block;
            padding: 0;
            right: 24px;
            top: 36px;
        }

        .logout {
            &__link {
                @include media($tablet-breakpoint) {
                    flex-direction: row-reverse;
                }

                >span {
                    margin-right: 10px;
                }
            }
        }
    }

    &__about {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: auto;

        >* {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            .sidebar-open & {
                max-width: $sidebar-width-lg;

                @media screen and (max-height: 1075px) {
                    margin-top: 24px;
                }
            }
        }

        @media screen and (max-height: 950px) {
            margin-top: 24px;
        }

    }

    //TODO объединить все переходы в один media-range

    &__person {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            transition: all 0.5s;
            //max-height: 168px;
            max-height: 200px;

            .sidebar:not(.active) & {
                max-height: 0;
            }
        }
    }

    &__logo,
    &__text,
    &__person,
    &__about {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            opacity: 1;
            margin-left: none;

            .sidebar:not(.active) & {
                //display: none;
                opacity: 0;
                margin-left: -100px;
            }
        }
    }

    .header__slogan {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            //display: block !important;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            width: 188px;

            .sidebar-open & {
                opacity: 1;
                max-width: 188px;
                max-height: 500px;
                width: auto;
            }
        }
    }

    .logo {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            margin-bottom: 0;

            .sidebar-open & {
                margin-bottom: 16px;
            }
        }
    }

    .city-selector,
    .logo {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            display: block !important;
            max-height: 0;
            max-width: 1px;
            //overflow: hidden;
            opacity: 0;
            transition: max-height 0.3s, opacity 0.3s;

            .sidebar-open & {
                opacity: 1;
                max-height: 500px;
                max-width: none;
            }
        }
    }

    .city-selector--logged>.city-selector__btn {
        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            margin-left: -150px;

            .sidebar-open & {
                margin-left: -20px;
            }
        }
    }

    &__counter {
        @include font-style(caption);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gs-medium-em;
        background-color: $secondary-light;
        border-radius: 50%;
        font-size: 0;

        position: absolute;
        left: 14px;
        top: 3px;
        width: 10px;
        height: 10px;
        border: 2px solid $gs-background;

        .sidebar-open & {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: auto;
            width: 32px;
            height: 32px;
            font-size: 12px;
            border: none;
        }

        @include media($desktop-breakpoint) {
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            left: auto;
            width: 32px;
            height: 32px;
            font-size: 12px;
            border: none;
        }
    }

    &__line {
        border: 1px solid #D9DBE9;
        width: 72px;
        margin: 8px 0;

        @include media($desktop-breakpoint) {
            margin: 8px 24px;
        }
    }

    &__long {
        padding-right: 0;

        @include media($tablet-breakpoint) {
            padding-right: 0;
        }

        @include media($desktop-breakpoint) {
            padding-right: 0;
        }
    }
}

@import "sidebar-right";