@import "../../theme";
@import "../../mixins";

.game-controls {
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;

    @include media($tablet-breakpoint) {
        right: 44px;
        top: 40px;
    }

    @include media($desktop-breakpoint) {
        position: static;
        display: grid;
        grid-gap: 4px;
        margin-left: 0;
        margin-top: 24px;
    }

    &__cancel {
        @include media($desktop-breakpoint) {
            color: $error-default !important;
        }
    }

    > * {
        &:not(:last-child) {
            @include media($desktop-breakpoint, down) {
                margin-right: 24px;
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media($desktop-breakpoint) {
            @include font-style(button);
        }
    }

    .btn,
    a {
        color: $white;

        &:hover {
            color: $secondary-dark;
        }

        @include media($tablet-breakpoint) {
            width: 32px;
            height: 32px;
        }

        @include media($desktop-breakpoint) {
            color: $secondary-default;
            //width: auto;
            width: max-content;
            height: auto;
            justify-content: flex-start;
            padding: 20px 18px 20px 18px;

            > .icon {
                margin-right: 12px;
            }
        }

        > .icon {
            width: 24px;
            height: 24px;

            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                width: 32px;
                height: 32px;
            }
        }
    }

    .btn {
        &__span {
            @include media($desktop-breakpoint, down) {
                display: none;
            }
        }
    }

    &--groups {
        @include media($desktop-breakpoint) {
            position: sticky;
        }

        .btn {
            @include media($desktop-breakpoint, down) {
                padding: 0;
            }
        }

        .icon-container {
            @include media($desktop-breakpoint, down) {
                margin: 0;
            }
        }

        .btn,
        a {
            @include media($desktop-breakpoint, down) {
                color: $gs-medium-em;
                background: transparent;
            }
        }
    }
}
