@import "../../theme";
@import "../../mixins";

.avatar {
    margin: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($tablet-breakpoint, down) {
        justify-content: flex-start;
        margin: 32px 0;
    }

    &__img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        flex-shrink: 0;

        @include media($tablet-breakpoint, down) {
            width: 40px;
            height: 40px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__name {
        font-family: Comfortaa;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #14142b;

        @include media($tablet-breakpoint, down) {
            font-family: Roboto;
            font-size: 16px;
            line-height: 20px;
        }
    }

    &_col {
        flex-direction: column;
        .avatar__img {
            margin-right: 0;
            margin-bottom: 16px;
        }
    }

    &_size {
        &-small {
        }
        &-medium {
        }
        &-large {
        }
    }
}
