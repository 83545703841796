@import "../theme";
@import "../mixins";

.text-block {
    //text-align: inherit;
    margin-bottom: 24px;

    @include media($tablet-breakpoint, down) {
        text-align: left;
    }

    @include media($tablet-breakpoint) {
        margin-bottom: 32px;
    }

    h3 {
        @include font-style(h3);
        color: $gs-high-em;
        margin-bottom: 24px;
    }

    ul {
        @include unstyled();
        color: $gs-medium-em;

        li {
            display: flex;
            align-items: flex-start;

            .icon {
                width: 32px;
                height: 32px;
                margin-right: 16px;
                fill: #14142b;
                fill-opacity: 0.75;

                @include media($desktop-breakpoint) {
                    display: none;
                }
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}
