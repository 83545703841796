@import "../../theme";
@import "../../mixins";

.conditions {
    @include unstyled();
    display: grid;
    grid-gap: 32px;

    @supports not (grid-template-rows: min-content) {
        display: block;

        >* {
            &:not(:last-child) {
                @include media($tablet-breakpoint, down) {
                    margin-bottom: 32px;
                }
            }
        }
    }

    &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-default;
        color: $secondary-default;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        margin-right: 20px;

        .icon {
            width: 16px;
            height: 16px;
        }

        .card-full__conditions-second & {
            @include media($tablet-breakpoint) {
                background-color: transparent;
                color: $white;
            }
        }
    }

    &__first {
        @include unstyled();
        display: flex;
        flex-direction: column;
        align-items: center;

        >* {
            &:not(:last-child) {
                margin-bottom: 32px;
            }
        }
    }

    &__item {
        display: flex;

        .level-info {
            width: 15px;
            height: 15px;
            margin-left: 8px;
            margin-top: 8px;
        }
    }

    &__right-col {
        align-self: center;

        @include media($tablet-breakpoint, down) {
            color: $gs-medium-em;
        }

        >* {
            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }

        .paid {
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 1.5px;
        }
    }
}