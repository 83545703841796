@import "../../theme";
@import "../../mixins";

.about {
    position: relative;
    width: 100%;
    //padding: 16px;
    margin-left: auto;
    margin-right: auto;

    &__logo {
        grid-column: 1/-1;
        display: inline-block;
        vertical-align: top;
        width: 70px;
        height: 32px;
        margin-bottom: 40px;

        @include media($tablet-breakpoint) {
            width: 107px;
            height: 47px;
            margin-bottom: 32px;
        }

        @include media($desktop-breakpoint) {
            width: 126px;
            height: 56px;
        }
    }
    &__grid-first {
        margin-top: 56-16px;
        @include media($desktop-breakpoint) {
            display: grid;
            margin: auto;
            align-items: center;
            grid-template-columns: 24.58vw 53.33vw;
            grid-gap: 40px;
        }
    }

    &__grid-second {
        @include media($desktop-breakpoint) {
            display: grid;
            justify-content: center;
            //grid-template-columns: 354px 260px+94px*2 354px;
            grid-template-columns: 24.58vw 260px+94px * 2 24.58vw;
        }

        .img-container {
            display: flex;
            justify-content: center;
            max-height: 65vh;

            img {
                max-height: 100%;
                object-fit: contain;
            }
        }

        .text-block {
            @include media($desktop-breakpoint) {
                max-width: 354px;
            }
        }

        > div {
            &:nth-child(1) {
                @include media($desktop-breakpoint) {
                    text-align: right;
                    margin-left: auto;
                }
            }
        }

        > *:last-child {
            grid-column: 1 / 4;
            justify-content: center;
            margin-top: 40px;
        }
    }

    &__grid-last {
        display: flex;
        flex-direction: column;
        //grid-template-rows: 2fr 3fr auto;
        align-items: center;
        flex-grow: 1;
        margin-top: 80-16px;

        @include media(768) {
            margin-bottom: 80-24px;
        }

        > * {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            margin-bottom: 80px;

            @include media($tablet-breakpoint) {
                margin-bottom: 140px;
            }

            @include media($desktop-breakpoint) {
                margin-bottom: 100px;
            }

            &:nth-child(1) {
                flex-basis: 45%;
            }

            &:nth-child(2) {
                flex-basis: 45%;
            }

            &:last-child {
                margin-top: auto;
                margin-bottom: 0;
            }
        }
    }

    &__intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        @include media($tablet-breakpoint) {
            margin-bottom: 80px;
        }

        @include media($desktop-breakpoint, down) {
            margin-left: auto;
            margin-right: auto;
            max-width: 536px;
            text-align: center;
        }

        @include media($desktop-breakpoint) {
            margin-bottom: 0;
            margin-right: 40px;
            align-items: flex-start;
        }
    }

    &__apps {
        display: flex;
        justify-content: center;

        @include media($desktop-breakpoint) {
            justify-content: flex-start;
        }

        > *:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 100%;

        @include media($tablet-breakpoint) {
            flex-direction: row;
            justify-content: space-between;
        }

        @include media($desktop-breakpoint) {
            padding-left: 135px;
            padding-right: 135px;
        }

        > * {
            &:nth-child(1) {
                @include media($tablet-breakpoint, down) {
                    margin-bottom: 24px;
                }
            }
        }
    }

    &__free-service {
        display: flex;
        justify-content: center;

        @include media($tablet-breakpoint, down) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__image {
            width: 313px;
            height: 494px;
            position: relative;
            margin: 0 15px 0 15px;

            img {
                position: absolute;
                width: 200px;
                height: 433px;
            }

            &__first {
            }

            &__second {
                margin-top: 61px;
                right: 0;
            }

            @include media($tablet-breakpoint, down) {
                width: 200px;
                height: 315px;
                margin: 15px 0 15px 0;

                img {
                    width: 128px;
                    height: 278px;
                }

                &__first {
                }

                &__second {
                    margin-top: 39px;
                    right: 0;
                }
            }
        }

        &__organizer {
            margin-top: 61px;
            width: 260px;
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            align-self: start;

            img {
                width: 83px;
                height: 83px;
            }

            @include media($tablet-breakpoint, down) {
                margin: 0;
                width: 100%;
                flex-direction: column-reverse;
                justify-content: center;

                img {
                    width: 64px;
                    height: 64px;
                }
            }

            &__signature {
                display: flex;
                flex-direction: column;
                align-items: end;
                margin-right: 11px;
                @include media($tablet-breakpoint, down) {
                    margin: 0;
                    align-items: center;
                }
            }
        }

        &__players {
            width: 260px;
            margin-bottom: 61px;
            display: flex;
            flex-direction: column;
            align-items: start;
            align-self: end;

            img {
                width: 257px;
                height: 64px;
            }

            @include media($tablet-breakpoint, down) {
                margin: 0;
                width: 100%;
                flex-direction: column;
                align-items: center;

                img {
                    width: 185px;
                    height: 46px;
                }
            }

            &__signature {
                display: flex;
                flex-direction: column;
                align-items: start;
                @include media($tablet-breakpoint, down) {
                    margin: 0;
                    align-items: center;
                }
            }
        }
    }

    &__project-presentation {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__player-wrapper {
            overflow: hidden;
            border-radius: 32px;

            width: 1155px;
            height: 650px;

            @include media($desktop-breakpoint, "down") {
                width: 722px;
                height: 406px;
            }

            @include media($tablet-breakpoint, down) {
                border-radius: 16px;
                width: 329px;
                height: 185px;
            }
        }
    }

    &-form {
        background: $gs-background;
        border-radius: 32px;
        max-width: 1115px;
        margin: 0 auto;
        padding: 80px 118px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media($desktop-breakpoint, down) {
            max-width: 100%;
            margin: 0 30px;
            padding: 60px 80px;
        }

        @include media($tablet-breakpoint, down) {
            max-width: 100vw;
            margin: 0;
            padding: 60px 13px;
        }

        &__telegram {
            margin-left: 15px;
            width: 56px;
            height: 56px;
            background: #039be5;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            > * {
                width: 56px;
                height: 56px;
            }
        }

        .form-input {
            width: 377px;
            max-width: 100%;
            @include media($desktop-breakpoint, down) {
                width: 277px;
            }

            @include media($tablet-breakpoint, down) {
                width: 229px;
            }
        }
    }
}

//Mouse scroll

div.MouseScroll {
    position: absolute;
    left: 50%;
    bottom: 25px;
    width: 100px;
    height: 100px;
    margin: 0 0 0 -50px;

    z-index: 2;

    button {
        @include unstyled();

        width: 100px;
        height: 100px;
    }

    svg {
        path.MouseScroll--chevron {
            animation: ChevronAnimation 3s ease infinite;
            transform: translateY(3px);
        }
    }
}

@keyframes ChevronAnimation {
    0% {
        transform: translateY(3px);
        opacity: 1;
    }
    50% {
        transform: translateY(8px);
        opacity: 0;
    }
    60% {
        transform: translateY(3px);
        opacity: 0;
    }
}
