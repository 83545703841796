@import "../../mixins";

.text-page {
    max-width: 746px;
    color: $gs-medium-em;

    p {
        @include font-style(body1);
        margin-bottom: 6px;
    }

    h3 {
        font-family: Comfortaa;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin: 24px 0 8px;
    }

    ul {
        margin: 6px;
        padding-left: 0;
        li {
            margin-bottom: 6px;
            list-style-type: none;
            &:before {
                content: "•";
                margin-right: 5px;
                margin-right: 1ex;
            }
        }
    }

    b {
        font-weight: bold;
    }
}
