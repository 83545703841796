@import "../../../theme";
@import "../../../mixins";

.form-group {
    margin-bottom: 24px;

    &:last-child {
        margin-bottom: 0;
    }

    &__label {
        @include font(Medium, Raleway);
        font-size: 14px;
        color: $gs-placeholder;
        line-height: 150%;
    }

    &--checkbox {
        margin-bottom: 16px;
    }

    &--row {
        @include media($tablet-breakpoint) {
            display: flex;
            align-items: flex-start;

            .form-group--checkbox {
                padding: 3px 0;
            }

            > * {
                margin-right: 32px;
                flex: 1;

                &:last-child {
                    margin-right: 0;
                }
            }

            .form-input {
                margin-bottom: 0;
            }
        }

        &-mobile {
            display: flex;
            align-items: flex-start;

            > * {
                margin-right: 12px;
                flex: 1;

                @include media($tablet-breakpoint) {
                    margin-right: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .form-input {
                margin-bottom: 0;
            }
        }
    }

}
