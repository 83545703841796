@import "../../theme";
@import "../../mixins";

.page,
.main-page {
    position: relative;
    left: 0;
    transition: left 0.3s;
    flex-grow: 1;

    @supports (-webkit-overflow-scrolling: touch) {
        /* CSS specific to iOS devices */
        transition: none;
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        margin-left: $sidebar-width-short;
        transition: margin-left 0.3s;

        .sidebar-open & {
            margin-left: $sidebar-width-tablet;
        }
    }

    @include media($desktop-breakpoint) {
        min-width: 100%;
    }

    .sidebar-open & {
        @include media($tablet-breakpoint, down) {
            left: $sidebar-width-lg;
        }
    }
}

.page {
    grid-area: main;
    min-width: 0;

    @include media($tablet-breakpoint, down) {
        .page__wrapper {
            margin-top: 4rem;
        }
    }

    @include media($tablet-breakpoint, down) {
        background-color: $gs-background;
    }

    @include media($tablet-breakpoint) {
        height: 100%;
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        margin-left: $sidebar-width-short;
        //min-width: calc(100vw - #{$sidebar-width-short});
        flex-grow: 1;

        .sidebar-open & {
            min-width: 100vw;
            margin-left: $sidebar-width-tablet + 24px;
        }
    }

    @include media($desktop-breakpoint) {
        padding-right: 24px;
        padding-bottom: 16px;
    }

    &--card-full {
        @include media($desktop-breakpoint) {
            padding-bottom: 32px;
        }
    }

    &--created {
        @include media($desktop-breakpoint) {
            padding-bottom: 32px;
        }
    }

    &--no-header {
        @include media($tablet-breakpoint, down) {
            z-index: 10;

            .page__wrapper {
                margin-top: 0;
            }
        }
    }

    &__wrapper {
        //min-height: 100%;

        @include media($desktop-breakpoint) {
            display: flex;
            min-height: 100%;

            >* {
                flex-grow: 1;
            }

            >.profile--view {
                flex: 1 0 26.25%;
                margin-left: 24px;
                max-width: 26.25%;
            }

            >.sportcourts-rent__map_view {
                flex: 1 0 43%;
                max-width: 43%;
            }
        }
    }

    &_collapsed {
        height: 0;
        max-height: 0;
        overflow: hidden;
    }

    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 24px;
        margin: -40px auto 0 auto;

        @include media($tablet-breakpoint) {
            margin-top: -120px;
        }

        >* {
            max-width: 424px;
        }
    }

    &__title {
        @include font-style(h2);
    }
}

.main-page {
    grid-area: main;
    height: 100%;
    padding: 0;
    position: relative;

    display: grid;
    grid-template-rows: min-content 1fr;

    @include media($tablet-breakpoint, down) {
        padding-top: 56+16px;
        //min-width: 100vw;

        @supports not (grid-template-rows: min-content) {
            display: flex;
            flex-direction: column;
            height: auto;
        }
    }

    @include media-range($tablet-breakpoint, $desktop-breakpoint) {
        margin-left: calc(#{$sidebar-width-short} - 24px);
        min-width: calc(100vw - #{$sidebar-width-short});

        .sidebar-open & {
            min-width: 100vw;
            margin-left: $sidebar-width-tablet;
            margin-top: 67+28px;
        }
    }

    @include media($desktop-breakpoint) {
        margin-left: -24px;
    }

    &__wrapper {
        display: flex;
        //align-items: center;
        flex-grow: 1;

        >.infinite-scroll-component__outerdiv {
            flex-grow: 1;
        }
    }
}

.personal-page {
    align-self: start;
}

.main-page_with-intro {
    grid-template-rows: min-content min-content 1fr;

    .filters-parent {
        justify-items: center;
        grid-template-columns: 1fr;
        grid-template-areas:
            "sport"
            "dates";

        @media screen and (max-width: 1280px) {
            justify-items: start;
        }

        .filters {
            .dropdown-parent {
                @include media($tablet-breakpoint, down) {
                    display: block;
                }
            }
        }
    }

    .filters__dates {
        @media screen and (max-width: 1280px) {
            width: 100%;
        }
    }

}

.page-notifications {
    .content__body {
        .infinite-scroll-component__outerdiv {
            flex-grow: 1;
            margin-bottom: auto;
        }
    }
}

.list-page-paper {
    height: 100%;

    &__main {
        min-height: calc(100% - 98px);

        @include media($tablet-breakpoint, down) {
            min-height: calc(100% - 166px);
        }
    }

    &__blacklist {
        position: sticky;
        bottom: 16px;
    }

    &_empty-blacklist {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .list-page-paper__main {
            min-height: 100%;
        }
    }
}

.games-list__date {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    color: $gs-label;
    margin: 24px 0;
}

.page-schools {
    z-index: 10;

    .page__wrapper {
        @include media($desktop-breakpoint) {
            display: block;
        }
    }

    .not-found {
        @include media($desktop-breakpoint) {
            margin: 0;
            padding: 50px 20px;
        }
    }

    .header {
        display: none;
    }
}
