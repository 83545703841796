@import "../../theme";
@import "../../mixins";

.group {
    &__title {
        @include font-style(h1);

        @include media($tablet-breakpoint) {
            @include font-style(h2);
        }
    }

    &__create {
        @include unstyled();
        color: $gs-medium-em;

        @include media($tablet-breakpoint) {
            @include font-style(button);
            padding: 16px 32px;
            background-color: $gs-surfaces;
            color: $secondary-default;
            transition: all 0.5s;

            &:hover {
                color: $secondary-dark;
            }

            &:focus {
                background-color: $gs-background;
                outline: none;
            }
        }

        .icon {
            @include media($tablet-breakpoint) {
                margin-right: 16px;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        @include media($tablet-breakpoint) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px;
            border-radius: 8px;
            background-color: $gs-background;
            margin-bottom: 12px;
        }

        &_alert {
            background-color: #ffe7e5;
            color: $error-dark;
            border-radius: 8px;
            padding: 16px;

            @include media($tablet-breakpoint, down) {
                margin-bottom: 0;
            }
        }

        h3 {
            @include media($tablet-breakpoint, down) {
                margin-bottom: 8px;
            }
        }

        p {
            @include font-style(caption);
        }
    }

    &__grid {
        @include media($desktop-breakpoint) {
            position: relative;
            display: grid;
            align-items: flex-start;
            grid-template-columns: minmax(60%, 1fr) 330px;
            grid-column-gap: 24px;
        }
    }
}
