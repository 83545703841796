@import "../../../theme";
@import "../../../mixins";

.form-input-icon {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    .form-input__input:focus + & {
    }

    .form-input--select &{
        width: 12px;
        height: 12px;
    }

    &--btn {
        @include unstyled();
        width: 16px;
        height: 16px;
        pointer-events: initial;
        cursor: pointer;
    }
}
