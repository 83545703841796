@import "../../theme";
@import "../../mixins";

.stat {

    &__title {
        @include font-style(body2);
        margin-bottom: 16px;
        color: $gs-medium-em;
    }

    &__items {
        @include unstyled();
        display: grid;
        grid-column-gap: 8px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include media-range(768, $desktop-breakpoint) {
            grid-column-gap: 24px;
        }

        @include media-range($desktop-breakpoint, 1700) {
            grid-column-gap: 4px;
        }

        > li {

            &:nth-child(1) {
                background-color: $primary-default;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px;
            border-radius: 8px;

            background-color: $gs-background;

            @include media($desktop-breakpoint) {
                background-color: $white;
            }
        }
    }

    &__digit {
        @include font-style(h3);
        margin-bottom: 8px;
        color: $gs-high-em;

        font-size: 24px !important;
        line-height: 32px !important;
    }
}
