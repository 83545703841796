$white: #ffffff;
$black: #000000;

// secondary
$secondary-default: #ff9800;
$secondary-dark: #c66900;
$secondary-light: #ffc947;

// primary
$primary-default: #fff176;
$primary-dark: #cabf45;
$primary-light: #ffffa8;


// error
$error-default: #FF5252;
$error-dark: #C50E29;
$error-light: #FF867F;

// success
$success-default: #84FF76;
$success-dark: #4CCB46;
$success-light: #BAFFA7;


//grayscale
$gs-high-em: #14142b;
$gs-medium-em: rgba(20, 20, 43, 0.75);
$gs-label: #9f9eb0;
$gs-placeholder: rgba(94, 92, 128, 0.38);
$gs-disabled-alfa: rgba(129, 127, 148, 0.38);
$gs-disabled: #cfced6;

$gs-outline: #d9dbe9;
$gs-background: #f7f7fc;
$gs-surfaces: #ffffff;

$colors: (
    white: #ffffff,
    black: #000000,
    primary-default: #fff176,
    primary-dark: #cabf45,
    primary-light: #ffffa8,
    secondary-default: #ff9800,
    secondary-dark: #c66900,
    secondary-light: #ffc947,
    error-default: #FF5252,
    error-dark: #C50E29,
    error-light: #FF867F,
    success-default: #84FF76,
    success-dark: #4CCB46,
    success-light: #BAFFA7,
    gs-high-em: #14142b,
    gs-medium-em: rgba(20, 20, 43, 0.75),
    gs-label: #9f9eb0,
    gs-placeholder: rgba(94, 92, 128, 0.38),
    gs-disabled: rgba(129, 127, 148, 0.38),
    gs-outline: #d9dbe9,
    gs-background: #F7F7FC,
    gs-surfaces: #ffffff,
);

