@import "../../theme";
@import "../../mixins";

.profile {
    &--edit {
        @include media($desktop-breakpoint) {
            order: -1;
        }
    }

    &__title {
        @include font-style(h1);

        @include media($desktop-breakpoint) {
            @include font-style(caption);
            color: $gs-label;
        }
    }

    &__heading {
        @include font-style(h1);

        @include media($desktop-breakpoint) {
            @include font-style(h2);
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        padding: 32px 16px;
    }

    &__form {
        border-radius: 16px;
        background-color: $white;
        padding: 16px;

        @include media($tablet-breakpoint, down) {
            overflow: hidden;
        }

        @include media($tablet-breakpoint) {
            padding: 40px;
        }

        @include media($desktop-breakpoint) {
            padding: 56px;
        }
    }

    &__form-grid {
        display: grid;
        grid-gap: 16px;
        //align-items: center;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 16px !important;
                }
            }
        }

        @include media($tablet-breakpoint) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 24px;
            height: 100%;
            grid-template-rows: repeat(8, min-content) 1fr;

            >* {
                &:nth-child(1) {
                    grid-column: 1/-1;
                }
            }

            >.btn-group {
                grid-column: 1/-1;
            }

            .profile {
                &__city {
                    grid-column: 1/-1 !important;
                }

                &__height {
                    grid-column: 2/3;
                }

                &__calendar {
                    grid-column: 1/2;
                }

                &__delete-btn {
                    grid-column: 2/3;
                }
            }
        }

        @include media($desktop-breakpoint) {
            grid-template-columns: 86px minmax(0, 1fr) 36px minmax(0, 1fr);
            grid-column-gap: 24px;
            grid-row-gap: 24px;
            height: 100%;
            grid-template-rows: repeat(8, min-content) 1fr;

            >* {
                &:nth-child(1) {
                    grid-column: 1/2;
                }

                &:nth-child(2) {
                    grid-column: 2/4;
                    align-self: center;
                }

                &:nth-child(3) {
                    grid-column: 4/5;
                    align-self: center;
                }

                &:nth-child(2n + 5) {
                    grid-column: 1/3;
                }
            }


            .profile {
                &__city {
                    grid-column: 1/-1 !important;
                }

                &__height {
                    grid-column: 3/5;
                }

                &__calendar {
                    grid-column: 1/3;
                }

                &__delete-btn {
                    grid-column: 3/-1;
                }

                &__socials {
                    grid-column: 3/5;
                }
            }
        }

        >* {
            margin-bottom: 0;
        }

        p {
            max-height: 999999px;
        }

        .form-input--file {
            @include media($tablet-breakpoint, down) {
                margin-bottom: 8px;
            }
        }

        .btn-password-reset {
            padding: 0;
        }

        .btn-submit {
            grid-column: 1/-1;
        }
    }

    &__gender {
        display: grid;
        grid-gap: 16px;

        @supports not (grid-template-rows: min-content) {
            display: block;

            >* {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }

        @include media($tablet-breakpoint, down) {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .radio-group {
            @include media($tablet-breakpoint, down) {
                display: flex;
                justify-content: space-between;
            }

            @include media($tablet-breakpoint) {
                display: flex;

                >* {
                    &:nth-child(1) {
                        margin-right: 24px;
                    }
                }
            }
        }
    }

    &__phone-edit {
        display: flex;
        justify-content: space-between;
    }

    &__list {
        @include unstyled();
        margin-left: 14px;

        @include media($tablet-breakpoint) {
            margin-left: 72-44px;
        }

        li {
            display: grid;
            grid-template-columns: min-content minmax(0, 1fr);
            grid-column-gap: 20px;
            align-items: center;

            margin-bottom: 32px;

            .icon-container {
                grid-column: 1/2;
                grid-row: span 2;
            }

            >* {
                grid-column: 2/3;
            }

            @supports not (grid-template-rows: min-content) {
                display: flex;

                >* {
                    &:nth-child(1) {
                        margin-right: 20px;
                    }

                    &:nth-child(2) {
                        flex-grow: 1;
                    }
                }
            }
        }

        .station.station_detail {
            span {
                color: $gs-medium-em;
            }

            .station-row {
                margin: 0
            }

            .icon-container {
                grid-column: 1/2;
                grid-row: span 1;
            }
        }
    }

    &__phone-change {
        display: flex;
        flex-direction: column;

        >* {
            &:nth-child(1) {
                margin-bottom: 16px;
            }

            &:nth-child(2) {
                display: flex;

                @include media($tablet-breakpoint, down) {
                    justify-content: space-between;
                }
            }
        }

        @include media($desktop-breakpoint) {
            grid-column: span 2;
        }

        >* {
            &:first-child {
                grid-column: 1/-1;
            }
        }

        >.btn-text {
            padding: 8px;
        }
    }

    &__delete-btn {
        justify-self: end;
        align-self: end;
    }

    &__phone {
        @include font-style(body1);
    }

    &__param {
        @include font-style(overline);
        color: $gs-label;
        margin-bottom: 4px;
    }

    &__value {
        @include font-style(body1);
        color: $gs-medium-em;
        display: inline-flex;

        .tooltip__btn {
            display: flex;
            align-items: center;
        }

        svg {
            margin-left: 4px;
        }

        &--height {
            &:empty {
                &::after {
                    content: none;
                }
            }

            &::after {
                // \0441\043c = см
                content: "\0441\043c";
                margin-left: 4px;
            }
        }

        a {
            color: $secondary-default;

            &:hover {
                color: $secondary-dark;
            }
        }
    }

    &__control-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            @include font-style(button);
            text-align: center;
            cursor: pointer;
            transition: all 0.3s;
            background: white;
            outline: none;
            border-radius: 40px;
            color: $secondary-default;
            padding: 20px 32px;

            &:hover {
                color: $secondary-dark;
            }

            &:focus {
                background-color: $gs-background;
            }

            &:disabled {
                color: $gs-disabled;
            }
        }

        span {
            display: none;

            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                display: block;
            }
        }

        >.icon {
            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                margin-right: 15px;
            }
        }

        &--cancel {
            margin-right: 32px;
            margin-left: 0;

            @include media($tablet-breakpoint) {
                margin-right: 0;
                margin-left: auto;
                order: 1;
            }
        }
    }

    &__submit-btn {
        width: 100%;

        @include media($desktop-breakpoint) {
            width: auto;
            margin-left: auto;
        }
    }

    &__paragraph {
        &--col {
            margin-top: -8px;

            @include media($tablet-breakpoint) {
                margin-top: -16px;
                grid-column: 1/-1 !important;
            }

            @include media($desktop-breakpoint) {
                margin-top: -24px;
                grid-column: 2/5 !important;
            }
        }
    }

    &__socials-container {
        display: flex;
        align-items: center;
        margin-top: 16px;

        @include media($tablet-breakpoint, down) {
            justify-content: space-between;
        }

        .profile__socials-icon {
            color: #7294C7;

            &_disabled {
                color: #D9DBE9;
            }
        }
    }
}