@import "../../theme";
@import "../../mixins";

.content {
    display: grid;
    grid-template-rows: min-content 1fr;
    min-height: 100vh;

    >* {
        min-width: 0;
    }

    @supports not (grid-template-rows: min-content) {
        display: flex;
        flex-direction: column;

        >* {
            &:nth-child(2) {
                flex-grow: 1;
            }
        }
    }

    @include media($desktop-breakpoint) {
        min-height: 100%;
    }

    &__top {
        position: relative;
        min-height: 152px;

        @include media($tablet-breakpoint) {
            min-height: 244px;
        }

        @include media($desktop-breakpoint) {
            min-height: 316px;
        }

        >* {
            position: relative;
            z-index: 10;
        }
    }

    &__head {
        display: flex;
        align-items: center;
        padding: 16px;

        >.icon-container {
            margin-right: 35px;
        }

        @include media($tablet-breakpoint) {
            padding: 24px 16px 24px 56px;
        }

        @include media($desktop-breakpoint) {
            min-height: 80px;
            padding: 32px 16px 32px 56px;
        }

        &--col-3 {
            >.icon-container {
                margin-right: 0;

                &:nth-child(1) {
                    margin-right: 32px;
                }

                &:last-child {
                    margin-left: auto;
                }
            }
        }

        .profile--view & {
            @include media($desktop-breakpoint) {
                padding-left: 0;
            }
        }
    }

    &__body {
        background-color: $white;
        padding: 32px 16px;
        border-radius: 16px 16px 0 0;

        @include media($tablet-breakpoint) {
            padding: 40px 44px;
            @include shadow(large);
        }

        @include media($desktop-breakpoint) {
            border-radius: 16px;
            padding: 56px;
        }

        &_transparent-bg {
            background: transparent;
            box-shadow: none !important;
            padding-bottom: 0;
        }

        .profile--view & {
            @include media($tablet-breakpoint, down) {
                box-shadow: none !important;
            }

            @include media($desktop-breakpoint) {
                padding-left: 54-24px;
                background-color: transparent;
                box-shadow: none !important;
            }

            @include media-range($desktop-breakpoint, 1700) {
                padding-left: 0px;
                padding-right: 0px;
            }
        }

        &--col-508,
        .password-change & {
            @include media($tablet-breakpoint) {
                display: grid;
                grid-auto-rows: min-content;
            }

            @include media(768) {
                grid-template-columns: 508px;
            }
        }

        .parallax-sheet & {
            @include media($desktop-breakpoint) {
                overflow-y: auto;
            }
        }

        &--column {
            display: flex;
            flex-direction: column;

            &__placeholder {
                flex-grow: 1000;
            }

            &__footer {
                position: sticky;
                bottom: 16px;
            }
        }

        &--about {
            border-radius: 16px;
        }

        &--with-slider {
            border-radius: 16px;
            padding-left: 0;
            padding-right: 0;

            @include media($desktop-breakpoint) {
                padding: 3% 0;
            }

            .about__grid-second {
                padding: 0 16px;

                @include media($tablet-breakpoint) {
                    padding: 40px 92px;
                }
            }
        }

        &--group {
            @include media($tablet-breakpoint) {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            @include media($desktop-breakpoint) {
                align-items: flex-start;
                flex-direction: row;
            }

            >.btn-group {
                @include media($tablet-breakpoint) {
                    display: flex;
                    margin-top: 24px;
                    margin-left: auto;
                }

                @include media($desktop-breakpoint) {
                    margin-left: 40px;
                    margin-top: 0;
                }
            }
        }

        &--relative {
            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                position: relative;
            }
        }
    }

    &__title {
        @include media($tablet-breakpoint, down) {
            max-width: calc(100vw - 160px);
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &--about {
            border-radius: 16px;
        }

        &--with-slider {
            border-radius: 16px;
            padding-left: 0;
            padding-right: 0;

            @include media($desktop-breakpoint) {
                padding: 3% 0;
            }

            .about__grid-second {
                padding: 0 16px;

                @include media($tablet-breakpoint) {
                    padding: 40px 92px;
                }
            }
        }

    }

    &__subtitle {
        @include unstyled();
        display: inline-flex;
        align-items: center;
        margin-bottom: 40px;

        >.icon-container {
            margin-right: 20px;
        }
    }

    &__page-h1 {
        font-family: Comfortaa, "Arial", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0.25px;

        @include media($tablet-breakpoint, down) {
            @include font-style(h1);
        }
    }

}