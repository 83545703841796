@import "./ReactToastify";
@import "../../theme";
@import "../../mixins";

body {
    .Toastify {
        &__toast-container {
            width: 100%;
            max-width: calc(100vw - 16px);
            left: 8px;
            top: 8px;
            transform: none;

            @include media($tablet-breakpoint) {
                max-width: calc(100vw - 48px - 72px);
                left: $sidebar-width-short + 24px;
                top: 24px;
                transform: none;
                transition: left 0.3s;

                .sidebar-open & {
                    left: $sidebar-width-tablet + 24px + 24px;
                }
            }

            @include media($desktop-breakpoint) {
                max-width: calc(100vw - 56 * 2px - 24px - #{$sidebar-width-lg});
                left: $sidebar-width-lg + 56px;
                transition: left 0.3s;
            }
        }

        &__toast {
            display: flex;
            @include shadow(medium);
        }

        &__toast--info {
            background: $primary-default;
        }

        //Используется для Уведомлений
        &__toast--warning {
            background-color: $secondary-light;
            border-radius: 16px;

            .Toastify__toast-body {
                margin: 0;
                min-width: 100%;

                .notification {
                    background: none;
                }
            }
        }

        &__toast-body {
            @include font-style(body2);
            margin: auto;
            color: $gs-medium-em;
        }

        &__toast--default {
            background: none;
            box-shadow: none !important;

            .Toastify {
                &__toast {
                    box-shadow: none;
                }

                &__toast-body {
                    box-shadow: none;
                    display: flex;
                    flex-grow: 1;
                    margin: 0;
                }
            }

            .notification {
                position: absolute;
                left: 0;
                top: 0;
                flex-grow: 1;
                min-width: 100%;
            }
        }
    }
}
