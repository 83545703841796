@import "../../theme";
@import "../../mixins";

.background-block {
    margin-bottom: 24px;

    @include media($tablet-breakpoint) {
        border-radius: 16px;
        background-color: $gs-background;
        padding: 32px;
        margin-bottom: 40px;
    }

    @include media($tablet-breakpoint, down) {
        border-radius: 16px;
        background-color: $gs-background;
        padding: 16px;
        margin-bottom: 40px;
    }

    &:empty {
        padding: 0;
    }
}
