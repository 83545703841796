@import "../../../theme";
@import "../../../mixins";

.form-select-parent {
    display: flex;
    align-items: center;
    //background-color: $gs-background;
    padding: 10px 20px 10px 20px;
    position: relative;
    //min-height: 56px;
    border-radius: 8px;


    //TODO в профиле также сделать нормальные селекты
    .profile & {
        background-color: $gs-background;
    }

    &__right {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 23px;
        min-height: 36px;

        input[type="date"] {
            background-color: transparent;
            border: none;
            min-height: 30px;
        }
    }
}

.form-select-parent {
    .form-select {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;


        &__control {
            padding: 0;
            font-size: inherit;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            outline: 0 !important;
            position: relative;
            transition: all 100ms;
            border: 1px solid transparent;
            //background-color: transparent;
            min-height: 20px;
            background-color: $gs-background;

            .profile & {
                background-color: transparent;
            }

            &:hover {
                //border: 1px solid transparent;
            }

            &--menu-is-open {
                z-index: 0;
                background-color: $white;
                border: 2px solid $primary-default;

                .profile & {
                    background-color: transparent;
                }
            }
        }

        &__indicators {
            margin-right: 20px;
        }

        &__indicator {
            display: flex;
            padding: 0;
            //margin-top: -5px;
        }

        &__value-container {
            //padding: 0 !important;
            padding: 26px 16px 0 64px;
            @include font-style(body1);
        }

        &__input input,
        &__placeholder,
        &__single-value {
            padding: 0;
        }

        &__single-value {
            position: static;
            margin-top: 10px;
        }

        &__indicator-separator {
            display: none;
        }

        &__menu {
            position: absolute;
            background-color: $white;
            @include shadow(medium);
            z-index: 1;
            top: 100%;
            width: 100%;
            border: none;
            padding-right: 0;
            min-width: 250px;
            //right: 0;

            @include media($tablet-breakpoint) {
                margin: 4px 0 0 0;
                padding-right: 3px;
                border-radius: 8px;
            }

            &-list {
                max-height: 300px;
                overflow-y: auto;
                padding-bottom: 16px;
                padding-top: 16px;
                margin-right: 8px;
                position: relative;
                box-sizing: border-box;

                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    background-color: #F7F7FC;
                    border-radius: 4px;
                    margin: 16px 0;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: #D9DBE9;
                }

                &__menu {
                    overflow-y: auto;
                }

                /*&__menu::-webkit-scrollbar-track {
                    padding: 2px 0;

                }

                &__menu::-webkit-scrollbar {
                    width: 8px;
                }

                &__menu::-webkit-scrollbar-thumb {

                }*/

            }
        }

        &__option {
            display: block;
            text-align: left;
            width: 100%;
            padding: 12px 24px;
            cursor: pointer;
            background-color: transparent;

            @include media($tablet-breakpoint, down) {
                &:hover,
                &:focus {
                    background-color: $gs-background;
                }

                &:active {
                    background-color: transparent;
                }

                &--is-selected {
                    background-color: $gs-background;
                }
            }


            &:active {
                background-color: transparent;
            }

            &--is-selected {
                font-weight: bold;
            }

            &--is-disabled {
                font-weight: $gs-disabled;
            }
        }
    }
}


.form-select-address {
    .form-select {
        &__menu {
            position: absolute;
            background-color: $white;
            @include shadow(medium);
            z-index: 20;
            top: 100%;
            width: 100%;
            border: none;
            padding-right: 0;
            min-width: 204px;
            right: 0;
            max-height: 300px;
            overflow-y: auto;
            margin-bottom: 16px;
            margin-top: 16px;
            box-sizing: border-box;
            border-radius: 16px;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: $white;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
            }


            > * {
                display: block;
                text-align: left;
                width: 100%;
                padding: 12px 24px;
                color: $gs-high-em;
                cursor: pointer;
                background-color: transparent;

                @include media($tablet-breakpoint, down) {
                    &:hover,
                    &:focus {
                        background-color: $gs-background;
                    }

                    &:active {
                        background-color: transparent;
                    }
                }

                &:hover,
                &:focus {
                    font-weight: bold;
                }

                &:active {
                    background-color: transparent;
                }
            }
        }
    }
}
