.menu {
    padding: 16px 0;
    background: #ffffff;
    box-shadow: 0 20px 24px rgba(17, 17, 17, 0.06);
    border-radius: 8px;

    &_small {
        width: 300px;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        margin-left: 0;
        list-style-type: none;
        padding: 12px 24px;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #14142b;

        &_clickable {
            cursor: pointer;

            &:hover {
                background-color: #f7f7fc;
            }
        }
    }
}
