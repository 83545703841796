@import "form-input/form-input";
@import "form-input/select";
@import "dropdown/dropdown";
@import "form-group/form-group";
@import "fieldset/fieldset";
@import "segmented-input/segmented-input";
@import "add-city/add-city";

.form {
    &__legend {
        @include font-style(overline);
        color: $gs-label;
        margin-bottom: 10px;

        @include media($tablet-breakpoint) {
            margin-bottom: 16px;
        }
    }
}

.form-file {
    margin-bottom: 28px;

    &__load {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    &__icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    input[type="file"] {
        position: absolute;
        opacity: 0;
        z-index: -10;

    }

    &__loaded {
        display: flex;
        align-items: center;

        &::before {
            content: "";
            width: 12px;
            height: 8px;
            margin-right: 10px;
            background: {
                position: 0 0;
                size: 100%;
                repeat: no-repeat;
                image: url("/images/check.svg");
            }
        }

        &-text {
            color: $black;
            margin-right: 8px;

            .error & {
                color: $gs-disabled;
            }
        }
    }
}
