@import "../theme";
@import "../mixins";

svg {
    fill: currentColor;
}

.icon {
    color: $gs-medium-em;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

*:hover>.icon,
*:focus>.icon,
.icon:hover,
.icon:focus {
    color: $gs-medium-em;
}

.icon.inherit-color,
*:hover>.icon.inherit-color,
*:focus>.icon.inherit-color,
.icon.inherit-color:hover,
.icon.inherit-color:focus {
    color: inherit;
}

.icon.inherit-size {
    width: 100%;
    height: 100%;
}

.icon-container {
    display: inline-block;
    width: 24px;
    height: 24px;

    &_s-32 {
        width: 32px;
        height: 32px;

        .icon {
            width: 32px;
            height: 32px;
        }
    }

    &_s-20 {
        width: 20px;
        height: 20px;

        .icon {
            width: 20px;
            height: 20px;
        }
    }

    &_s-16 {
        width: 16px;
        height: 16px;

        .icon {
            width: 16px;
            height: 16px;
        }
    }

    &_s-14 {
        width: 14px;
        height: 14px;

        .icon {
            width: 14px;
            height: 14px;
        }
    }

    .icon {
        vertical-align: top;
    }

    &_error {
        color: $error-default;
        align-items: center;
        display: flex;
    }
}