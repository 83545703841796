@import "../../theme";
@import "../../mixins";

.sportcourts {

    .content__head {
        @include media($desktop-breakpoint) {
            padding: 28px 56px 19px;
        }
    }

    .content__body {
        padding: 71px 56px 64px;

        @include media($desktop-breakpoint, down) {
            padding: 44px;
        }

        @include media($tablet-breakpoint, down) {
            padding: 16px;
        }
    }

    &__list {
        display: grid;
        gap: 48px;

        @include media($desktop-breakpoint, down) {
            padding-bottom: 100px;
        }
    }

    &__info-input {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    &__address-input {

        @include media($tablet-breakpoint, down) {
            .two-step-input__button {
                padding: 0;
            }
        }

        .form-input {
            @include media($tablet-breakpoint, down) {
                background-color: #f7f7fc;
            }
        }

        .form-input__input {
            background-color: #f7f7fc;
        }

        .form-input__label {
            color: $gs-placeholder;
        }

        .btn {
            background-color: #f7f7fc;
        }

        .form-input--filled {
            .address-container {
                flex-direction: column;
                align-items: start;
                gap: 4px;
            }
        }

    }

}

.sportcourts-card {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    align-items: center;

    @include media($tablet-breakpoint) {
        grid-template-columns: minmax(min-content, 250px) 1fr;
        gap: 32px;
    }

    @include media($desktop-breakpoint) {
        grid-template-columns: minmax(min-content, 330px) 1fr;
    }

    &__title {
        @include font-style(h4);
        color: $gs-medium-em;

        @include media($desktop-breakpoint) {
            @include font-style(h3);
        }

    }

    &__info {
        list-style: none;
        padding: 0;
        margin: 8px 0 0;
        display: grid;
        gap: 4px;

    }

    &__info-item {
        display: flex;
        gap: 8px;
        color: $gs-label;

    }

    &__info-text {
        @include font-style(caption);
    }

    &__img {
        background-image: url(../../../images/classic_volleyball.png);
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        width: 100%;
        height: 192px;
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
            width: 100%;
            min-height: 100%;
            object-position: center;
            object-fit: cover;

        }

        @include media($tablet-breakpoint) {
            height: 146px;
        }

        @include media($desktop-breakpoint) {
            height: 192px;
        }
    }

    &__btn-group {
        display: flex;
        gap: 8px;
        margin-top: 8px;
        flex-flow: wrap;

        @include media($tablet-breakpoint, down) {
            margin-top: 20px;
        }

        .btn--grey {
            background-color: $white;
        }
    }

}