@import "../../theme";
@import "../../mixins";

.unordered-list {
    margin: 16px 0;
    padding-left: 0;
    list-style: none;

    &__item {
        padding: 22px 16px 22px 20px;
        margin-left: 10px;

        @include media($tablet-breakpoint, down) {
            margin-left: 0;
        }

        &:before {
            content: "";
            background-image: url("/images/icons/done.svg");
            display: inline-block;
            height: 20px;
            width: 20px;
            vertical-align: middle;
            margin-right: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}
