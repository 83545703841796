.collapse {
    position: relative;

    &__content {
        display: none;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 100;

        &_active {
            display: block;
        }
    }

    &-list {
        margin: 16px 0;
        color: #14142b;
        padding: 0;

        &__item {
            list-style-type: none;
            padding: 15px 27px;

            &_pointer {
                cursor: pointer;
            }
        }
    }
}
