.school {
    position: relative;
    display: block;
    z-index: 10;

    &__head {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
        background-image: linear-gradient(
                180deg,
                rgba(20, 20, 43, 0.15) 0%,
                rgba(20, 20, 43, 0.9) 100%
            ),
            url(../../../images/about/school-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: darkgray;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
        min-height: 450px;
        overflow: hidden;
        padding: 48px 32px 24px 118px;
        margin-top: 16px;

        @media screen and (max-width: 1040px) {
            padding: 40px 44px 24px;
            min-height: 373px;
        }

        @media screen and (max-width: 600px) {
            padding: 16px;
        }

        &_page {
            display: grid;
            align-content: space-between;
        }
    }

    &__btn-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    &__btn-group {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &__btn-adapt {
        @media (max-width: 768px) {
            padding: 12px 16px;
        }

        @media (max-width: 425px) {
            font-size: 9px;
            line-height: 16px;
            padding: 8px 16px;
        }
    }

    &__back {
        color: white;
        width: 32px;
        height: 32px;
    }

    &__form {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 20px;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    &__container-data {
        display: grid;
        align-content: space-between;
    }

    &__form-header-img {
        align-self: self-end;
    }

    &__logo {
        margin: 0;
        display: flex;

        .form-input__avatar {
            height: 68px;
            width: 68px;

            img {
                height: 68px;
                width: 68px;
            }
        }

        &_small {
            height: 48px;
            width: 48px;

            img {
                height: 48px;
                width: 48px;
            }
        }
    }

    &__input-title {
        background: rgba(156, 155, 179, 0.38);
        border-radius: 4px;
        padding: 0 4px;
        margin: 8px 0;
        outline: none;
        border: none;
        min-height: 64px;
        max-width: 450px;
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;
        color: #ffffff;
        resize: none;
        overflow: hidden;

        @media (max-width: 670px) {
            font-size: 32px;
            line-height: 40px;
            height: 40px;
            width: 284px;
        }

        &::placeholder {
            font-family: "Comfortaa";
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 64px;
            color: #d9dbe9;

            @media (max-width: 670px) {
                font-size: 32px;
                line-height: 40px;
            }
        }
    }

    &__input-info {
        background: rgba(156, 155, 179, 0.38);
        border-radius: 4px;
        padding: 4px;
        margin: 8px 0;
        outline: none;
        border: none;
        display: block;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #ffffff;
        resize: none;
        overflow: hidden;

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #d9dbe9;
        }
    }

    &__label-input {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #d9dbe9;
        position: absolute;
        top: 3px;
        left: 3px;
    }

    &__input-control {
        height: 28px;
        position: relative;
    }

    &__prefix {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #ffffff;
        display: block;
        padding: 3px;
    }

    &__info-list {
        display: flex;
        color: white;
        gap: 24px;
        list-style: none;
        padding: 0;
        margin-top: 24px;
    }

    &__info {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
        gap: 8px;
        color: white;
    }

    &__bg-load {
        align-self: end;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: end;
    }

    &__bg-load-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        letter-spacing: 0.4px;
        color: #f7f7fc;
    }

    &__error-container {
        background: #ffe7e5;
        box-shadow: 0px 20px 24px rgba(17, 17, 17, 0.06);
        border-radius: 0px 0px 16px 16px;
        position: sticky;
        min-height: 68px;
        left: 0px;
        top: 0px;
        z-index: 20;
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 24px;
    }

    &__input-game-types {
        margin-bottom: 24px;
        display: grid;
        gap: 16px;
        padding: 0;
    }

    &__error {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        letter-spacing: 0.5px;
        color: rgba(20, 20, 43, 0.75);

        @media (max-width: 670px) {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__title {
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 64px;
        color: white;
        max-width: 920px;
        overflow-wrap: anywhere;

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 40px;
            max-width: 628px;
            overflow-wrap: anywhere;
        }

        @media (max-width: 670px) {
            font-size: 32px;
            line-height: 40px;
            max-width: 328px;
            overflow-wrap: anywhere;
        }
    }

    &__types {
        margin-bottom: 24px;
    }

    &__type {
        color: white;
    }

    &__address {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #ffffff;
        mix-blend-mode: normal;
    }

    &__container {
        display: grid;
        align-content: space-between;
        height: 100%;
    }

    @media screen and (min-width: 1280px) {
        .page__wrapper {
            min-height: unset;
        }
    }

    &__content {
        background: #ffffff;
        box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 32px;
        padding: 48px 0 116px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 80px;
        height: 100%;

        @media screen and (max-width: 1040px) {
            gap: 56px;
        }

        @media screen and (max-width: 600px) {
            padding: 32px 0 58px;
            gap: 40px;
        }
    }

    &__nav-bar {
        width: 100%;
        padding: 0 117px;

        @media screen and (max-width: 1040px) {
            padding: 0 44px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;
        }

        &_sticky {
            display: flex;
            align-items: center;
            gap: 32px;
            padding: 8px 0;
            background-color: #f7f7fc;
            position: sticky;
            top: 0;
            left: 0;
            z-index: 100;
            width: 100%;
            margin: 0;

            @media screen and (max-width: 600px) {
                padding: 8px 0 8px 16px;
            }
        }
    }

    &__nav-bar-links {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(5, max-content);
        gap: 24px;
        padding: 0;
        margin: 0;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__nav-bar-link {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #9f9eb0;

        &_active {
            color: #14142b;
        }
    }

    &__about {
        min-height: 28px;
        width: 100%;
        height: 100%;
        padding: 0 117px;

        @media screen and (max-width: 1040px) {
            padding: 0 44px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;
        }
    }

    &__input-about {
        padding: 4px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: rgba(20, 20, 43, 0.75);
        background: rgba(214, 213, 227, 0.38);
        border: none;
        min-height: 100%;
        width: 100%;
        height: 100%;
        // max-height: 1500px;

        &:focus {
            background-color: #f7f7fc;
            border: none;
        }

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #9f9eb0;
        }
    }

    &__video {
        width: 100%;
        padding: 0 117px;
        display: grid;
        justify-items: center;

        @media screen and (max-width: 1040px) {
            padding: 0 44px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;
        }
    }

    &__video-container {
        background: #f7f7fc;
        border-radius: 16px;
        padding: 121px;
        max-width: 923px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 1040px) {
            max-height: 354px;
            max-width: 608px;
            padding: 49px;
        }

        @media screen and (max-width: 600px) {
            max-height: 354px;
            max-width: 608px;
            padding: 40px 25px;
        }
    }

    &__video-icon {
        background: #ffffff;
        border-radius: 50%;
        height: 80px;
        width: 80px;
        background-image: url("../../../images/icons/chain.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 32px 32px;
    }

    &__video-caption {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.5px;
        color: #9f9eb0;
        padding-top: 6px;
    }

    &__input-video {
        outline: none;
        border: none;
        height: 64px;
        background: #ffffff;
        border-radius: 8px;
        padding: 22px 16px;
        margin: 24px 0;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: rgba(20, 20, 43, 0.75);
        max-width: 510px;
        width: 100%;

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: rgba(94, 92, 128, 0.38);
        }

        @media screen and (max-width: 600px) {
            max-width: 275px;
        }
    }

    &__video-btn {
        padding: 0 31px;
    }

    &__modal-text {
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #14142b;
        max-width: 300px;
        text-align: center;
    }

    &__video-iframe-container {
        position: relative;
        width: 100%;
        min-height: 523px;
        max-width: 923px;
        overflow: hidden;

        @media screen and (max-width: 1040px) {
            min-height: 354px;
            max-width: 608px;
        }

        @media screen and (max-width: 600px) {
            min-height: 328px;
            max-width: 608px;
        }
    }

    &__video-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 16px;
    }

    &__delete-video {
        z-index: 2;
        position: absolute;
        top: 32px;
        right: 32px;
        padding: 16px;

        @media screen and (max-width: 600px) {
            padding: 8px;
            top: 16px;
            right: 16px;
        }
    }

    &__slider-container {
        width: 100%;
        max-width: 100%;
    }

    &__slider {
        .slick-track {
            gap: 24px;

            @media screen and (max-width: 1040px) {
                gap: 16px;
            }

            @media screen and (max-width: 600px) {
                gap: 8px;
            }
        }
    }

    &__slider-add-photo {
        width: 100%;
        min-height: 457px;
        height: 100%;
        background: #f7f7fc;
        border-radius: 16px;
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1040px) {
            min-height: 287px;
        }

        @media screen and (max-width: 600px) {
            min-height: 200px;
        }
    }

    &__slider-photo {
        height: 100%;
        width: 100%;
        border-radius: 16px;
        max-height: 457px;
        object-fit: cover;
        height: 462px;

        @media screen and (max-width: 1040px) {
            max-height: 287px;
        }

        @media screen and (max-width: 600px) {
            max-height: 200px;
        }
    }

    &__slider-photo-full {
        border-radius: 16px;
    }

    .slick-slide {
        height: unset;

        div {
            height: 100%;
            position: relative;
        }
    }

    &__slider-dots {
        list-style: none;
        display: flex !important;
        gap: 4px;
        padding: 0;
        justify-content: center;

        .slick-active {
            #dot {
                background: #ff9800;
                width: 30px;
                border-radius: 10px;
            }
        }
    }

    &__custom-dot {
        border: none;
        border-radius: 50%;
        background: #f7f7fc;
        width: 10px;
        height: 10px;

        &_active {
            background: #ff9800;
            width: 30px;
        }
    }

    &__slider-load-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #9f9eb0;
    }

    &__trainers {
        width: 100%;
        padding: 0 117px;

        @media screen and (max-width: 1040px) {
            padding: 0 44px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;

            .school__btn-add-trainer {
                width: 100%;
            }
        }
    }

    &__btn-add-trainer {
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &__section-title {
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #14142b;
        margin-bottom: 24px;

        @media screen and (max-width: 600px) {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 16px;
        }

        &_trainings {
            font-size: 32px;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 0.25px;

            @include media($desktop-breakpoint) {
                font-size: 48px;
                font-weight: 700;
                line-height: 64px;
                margin-bottom: 0;
            }

            @media screen and (max-width: 600px) {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
            }
        }
    }

    &__trainers-list {
        display: grid;
        gap: 24px;

        @media screen and (max-width: 1040px) {
            gap: 16px;
        }

        &_view {
            grid-template-columns: 1fr 1fr;
            align-items: flex-start;

            @media screen and (max-width: 1040px) {
                grid-template-columns: 1fr;
            }
        }
    }

    &__trainer-container {
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: 24px;
        align-items: center;

        .person__avatar {
            width: 94px;
            height: 94px;

            img {
                width: 94px;
                height: 94px;
            }

            @media screen and (max-width: 1040px) {
                width: 72px;
                height: 72px;

                img {
                    width: 72px;
                    height: 72px;
                }
            }
        }
    }

    &__trainer-info {
        display: grid;
        grid-auto-columns: 1fr;
        justify-items: flex-start;
        gap: 4px;

        .btn--medium {
            padding: 8px 16px 8px 0;
        }
    }

    &__trainer-name {
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(20, 20, 43, 0.75);
    }

    &__input-trainer {
        background: rgba(214, 213, 227, 0.38);
        border-radius: 4px;
        padding: 4px;
        outline: none;
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: rgba(20, 20, 43, 0.75);
        border: none;
        width: 128px;
        resize: none;
        vertical-align: middle;
        overflow-y: hidden;

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: rgba(94, 92, 128, 0.38);
            vertical-align: middle;
        }

        &_disabled {
            background: none;
            color: #9f9eb0;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            padding: 0 4px;
        }
    }

    &__trainer-position {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        color: #9f9eb0;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        padding: 0 4px;
        width: 100%;
    }

    &__groups {
        width: 100%;
        padding: 0 117px;
        display: grid;
        gap: 24px;

        @media screen and (max-width: 1040px) {
            padding: 0 44px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;
        }
    }

    &__type-accordion {
        background: #ffffff;
        box-shadow: 0px 4px 8px rgb(17 17 17 / 6%);
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 16px;
        cursor: pointer;
    }

    &__accordion-info {
        display: flex;
    }

    &__groups-count {
        background: #ffc947;
        border-radius: 16px;
        width: 100px;
        height: 32px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 43, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__type-arrow {
        transform: rotate(90deg);
        transition: transform 0.2s ease;
    }

    &__type-name {
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #14142b;
        margin-left: 10px;

        @media screen and (max-width: 600px) {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__game-type-content {
        height: 0;
        visibility: hidden;
        opacity: 0;
        margin-top: 24px;
        transition: visibility 0.2s ease, opacity 0.2s ease, height 0.2s ease;

        &_open {
            visibility: visible;
            opacity: 1;
            height: 100%;
        }
    }

    &__groups-title-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }

    &__groups-container {
        display: grid;
        gap: 24px;
        margin-bottom: 24px;

        &_line {
            padding-top: 24px;
            border-top: 1px solid #d9dbe9;
        }
    }

    &__group-info {
        display: grid;
        gap: 8px;
    }

    &__groups-btn {
        display: flex;
        gap: 24px;
    }

    &__input-group-title {
        background: rgba(214, 213, 227, 0.38);
        border-radius: 4px;
        padding: 0 4px;
        outline: none;
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #14142b;
        border: none;
        width: 147px;
        resize: none;
        vertical-align: middle;
        overflow-y: hidden;

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #9f9eb0;
            padding: 4px 0;
        }
    }

    &__input-group-description {
        background: rgba(214, 213, 227, 0.38);
        border-radius: 4px;
        padding: 4px;
        outline: none;
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: rgba(20, 20, 43, 0.75);
        border: none;
        width: 576px;
        resize: none;
        vertical-align: middle;
        overflow-y: hidden;

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #9f9eb0;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__groups-status {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #9f9eb0;
    }

    &__select-indicator {
        width: 16px;
        height: 16px;
    }

    &__prices {
        background: #f7f7fc;
        border-radius: 16px;
        padding: 24px;
        display: grid;
        gap: 16px;
        margin: 56px 0;
    }

    &__prices-title {
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(20, 20, 43, 0.75);
    }

    &__prices-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 140px;

        @media screen and (max-width: 1040px) {
            grid-template-columns: 1fr;
            gap: 24px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;
        }
    }

    &__prices-info {
        display: grid;
        gap: 24px;
        height: min-content;

        &_view {
            gap: 0;
        }
    }

    &__input-price-title {
        background: rgba(214, 213, 227, 0.38);
        border-radius: 4px;
        padding: 4px;
        outline: none;
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #14142b;
        border: none;
        width: 92px;
        resize: none;
        overflow-y: hidden;

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #9f9eb0;
        }
    }

    &__input-price-description {
        background: rgba(214, 213, 227, 0.38);
        border-radius: 4px;
        padding: 4px;
        outline: none;
        border: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: #14142b;
        border: none;
        resize: none;
        vertical-align: middle;
        overflow-y: hidden;

        @media screen and (max-width: 1040px) {
            width: 100%;
        }

        &::placeholder {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #9f9eb0;
        }
    }

    &__playgrounds {
        width: 100%;
        padding: 0 117px;

        @media screen and (max-width: 1040px) {
            padding: 0 44px;
        }

        @media screen and (max-width: 600px) {
            padding: 0 16px;

            .school__btn-add-playground {
                width: 100%;
            }
        }

        .map {
            height: 328px;

            .ymaps-2-1-79-inner-panes {
                border-radius: 16px;
            }
        }
    }

    &__playgrounds-btn-group {
        display: flex;
        gap: 24px;
        width: max-content;
        margin-bottom: 24px;

        @media screen and (max-width: 1040px) {
            gap: 16px;
            margin-bottom: 16px;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    &__btn-del-playground {
        padding: 12px 24px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
        gap: 12px;
        border: none;
        white-space: nowrap;

        span {
            width: max-content;
        }

        &_active {
            background: #fff176;
        }
    }

    &__placemark {
        background-color: white;
        color: rgba(20, 20, 43, 0.75);
        padding: 4px;
        border-radius: 100px;
        display: flex;
        gap: 8px;
        align-items: center;
        width: max-content;
        max-width: 155px;
        overflow: hidden;

        &_selected {
            background: #fff176;
        }
    }

    &__placemark-logo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0;
    }

    &__placemark-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: rgba(20, 20, 43, 0.75);
        margin: 0;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__scroll-container {
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__input-disabled {
        background: none;
    }

    &__details {
        .person--profile {
            margin-bottom: 0px;
        }

        .school__types {
            justify-content: center;
            margin: 8px 0 24px;
        }
    }

    &__trainings {
        margin-top: 80px;

        @media screen and (max-width: 600px) {
            margin-top: 45px;
        }
    }

    &__trainings-mobile {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
    }

    &__caption {
        @include font-style(caption);
    }
}
