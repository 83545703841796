@import "../theme";
@import "media";

@mixin font-face($family, $path, $weight, $style) {
    @font-face {
        font-family: $family;
        src: url("#{$path}.woff2") format("woff2");
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@mixin font($font: Roboto, $weightInit: Regular) {
    font-family: $font, "Arial", sans-serif;
    //font-style: $style;

    $weight: map_get(
        (
            Thin: 100,
            Light: 300,
            Regular: normal,
            Medium: 500,
            SemiBold: 600,
            Bold: bold,
            ExtraBold: 800,
            Black: 900,
        ),
        $weightInit
    );

    font-weight: $weight or $weightInit;
}

@mixin font-style($name: body1) {
    $style: map_get($font-styles, $name);
    $font: nth($style, 1);

    $sizes320: nth($style, 2);
    $sizes600: nth($style, 3);
    $sizes1280: nth($style, 4);

    @include font(nth($font, 1), nth($font, 2));

    font-size: nth($sizes320, 1);
    line-height: nth($sizes320, 2);

    @if (nth($sizes320, 3) != normal) {
        letter-spacing: nth($sizes320, 3);
    }

    //Повторящиеся размеры не переопределяем
    @if ($sizes600 != repeatPrev) {
        @include media($tablet-breakpoint) {
            font-size: nth($sizes600, 1);
            line-height: nth($sizes600, 2);

            @if (nth($sizes600, 3) != normal) {
                letter-spacing: nth($sizes600, 3);
            }
        }
    }

    @if ($sizes1280 != repeatPrev) {
        @include media($desktop-breakpoint) {
            font-size: nth($sizes1280, 1);
            line-height: nth($sizes1280, 2);

            @if (nth($sizes1280, 3) != normal) {
                letter-spacing: nth($sizes600, 3);
            }
        }
    }

    @if (nth($style, 5) == true) {
        text-transform: uppercase;
    }
}
