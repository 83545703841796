@import "../../theme";
@import "../../mixins";

$burger-width: 24px;
$burger-height: 24px;
$burger-line-width: 2px;


.burger {
    @include unstyled();
    position: relative;
    font-size: 0;
    color: rgba(20, 20, 43, 0.75);;
    width: $burger-width;
    height: $burger-height;
    cursor: pointer;


    &::before {
        content: "";
        display: block;
        position: absolute;
        width: $burger-width;
        height: $burger-width;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__line {
        position: absolute;
        top: 50%;

        .header__burger.active & {
            background: transparent;
        }

        &,
        &::before,
        &::after {
            display: block;
            position: absolute;
            color: inherit;
            left: 100%;
            width: $burger-width;
            height: $burger-line-width;
            background: currentColor;
            transform: translate(-50%, -50%);
            transition: 0.3s;
        }

        & {
            width: 50%;
            left: 10px;
        }

        &::before,
        &::after {
            content: "";

            .header__burger.active & {
                border-radius: 1px;
            }
        }

        &::before {
            top: -8px;
            transform: translate(-50%, 0);

            .header__burger.active & {
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }

        &::after {
            top: 8px;
            transform: translate(-50%, 0);
            opacity: 0;

            .header__burger.active & {
                top: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                opacity: 1;
            }
        }
    }

}

