@import "../../theme";
@import "../../mixins";

.report {
    &__item {
        // display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 12px;
        border-radius: 16px;

        &-main {
            align-items: center;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr max-content max-content;
            gap: 12px;
        }

        &-user {
            // flex-grow: 1;
        }

        .select-paid {
            // flex-grow: 1;
        }

        &-controls {
            display: flex;
            gap: 12px;
            align-items: center;
        }

        &-tags {
            margin-top: 16px;
            margin-bottom: 12px;
            flex-basis: 100%;

            .tag-list__add-list>.menu {
                max-width: 100%;

                @include media($tablet-breakpoint, down) {
                    width: 100vh;
                }
            }
        }

        .fs-caption,
        .radio-check-box {
            margin-left: auto;
        }

        .report__item-tags {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: $gs-outline;

            .report__item-tags {
                display: block;
            }
        }

        &--disabled {
            .person {
                &__name {
                    color: $gs-disabled;
                }

                &__avatar {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(129, 127, 148, 0.38);
                    }
                }
            }
        }
    }
}