@import "../../theme";
@import "../../mixins";

.card-full {
    position: relative;

    @include media($tablet-breakpoint) {
        margin-top: 16px;
        @include shadow(large);
    }

    @include media($desktop-breakpoint) {
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
    }

    &__head {
        //position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(128px + 16px);
        padding: 16px;

        background: {
            image: linear-gradient(
                    180deg,
                    rgba(20, 20, 43, 0.15) 0%,
                    rgba(20, 20, 43, 0.9) 100%
                ),
                var(--bg-image);
            repeat: no-repeat;
            size: cover;
            position: top center;
            color: darkgray;
        }

        @include media($tablet-breakpoint) {
            padding: 24px 44px;
            height: 316px;

            border-top-left-radius: 32px;
            overflow: hidden;
        }

        @include media($desktop-breakpoint) {
            padding: 48px 72px 32px 72px;
            //height: 316px;

            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
        }

        @include media(1440) {
            padding: 48px 118px 32px 118px;
        }

        &.fixed {
            display: flex;
            height: auto;
            position: sticky;
            top: 0;
            //z-index: 20;
            z-index: 110;
            border-radius: 0;
            background: $gs-background;

            @supports not (position: sticky) {
                position: fixed;
            }

            @include media($tablet-breakpoint) {
                background: $white;
            }

            @include media($desktop-breakpoint) {
                padding: 24px 44px;
            }

            .card-full {
                &__top {
                    margin-bottom: 0;

                    > p {
                        display: none;
                    }
                }

                &__back {
                    top: 16px;
                    z-index: 20;

                    @include media($tablet-breakpoint) {
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    > a,
                    > div,
                    button {
                        color: $gs-high-em;
                    }

                    .icon {
                        @include media($desktop-breakpoint) {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &__title {
                    color: $gs-high-em;
                    margin-bottom: 0;

                    @include media($tablet-breakpoint) {
                        margin-left: 56px;
                        font-size: 20px;
                        line-height: 24px;
                    }

                    @include media($desktop-breakpoint) {
                        margin-left: 172-44px;
                    }
                }

                &__conditions-second {
                    display: none;
                }
            }
        }
    }

    &__body {
        display: flex;
        background-color: $white;
        padding: 32px 16px;
        //overflow: hidden;

        @include media($tablet-breakpoint, down) {
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            margin-top: -16px;
        }

        @include media($tablet-breakpoint) {
            padding: 40px 44px;
        }

        @include media($desktop-breakpoint) {
            padding: 56px 72px;
            border-bottom-left-radius: 32px;
            border-bottom-right-radius: 32px;
        }

        @include media(1440) {
            padding: 56px 118px;
        }

        &--grid {
            display: grid;

            @supports not (grid-template-rows: min-content) {
                display: block;
            }

            > * {
                min-width: 0;
            }
        }

        &--grid-desktop {
            @include media($desktop-breakpoint) {
                position: relative;
                align-items: flex-start;

                grid-template-columns: minmax(60%, 1fr) 330px;
                grid-column-gap: 40px;

                > * {
                    grid-column: 1/2;
                }
            }
        }

        &--created {
            display: flex;
            flex-direction: column;

            @include media($desktop-breakpoint) {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: minmax(60%, 1fr) 330px;
                grid-column-gap: 40px;

                .game-created__left {
                    min-width: 0;
                    grid-column: 1/2;
                }

                .game-created__right {
                    min-width: 0;
                    grid-column: 2/3;
                }
            }
        }
    }

    &__back {
        position: absolute;
        left: 16px;
        top: 16px;
        color: $white;

        @include media($tablet-breakpoint) {
            left: 44px;
            top: 40px;
        }

        @include media($desktop-breakpoint) {
            left: 72px;
            top: 48px;
        }

        @include media(1440) {
            left: 118px;
        }

        .icon {
            @include media($desktop-breakpoint) {
                width: 32px;
                height: 32px;
            }
        }

        > a,
        button {
            color: $white;
            line-height: inherit;

            &:hover {
                color: $primary-default;
            }
        }
    }

    &__top {
        margin-top: auto;
        margin-bottom: 16px;
        margin-left: 20%;

        @include media(360) {
            margin-left: 72-16px;
        }

        @include media($tablet-breakpoint) {
            margin-left: 0;
        }
    }

    &__title {
        position: relative;
        @include font-style(h1);
        color: $white;
    }

    &__conditions-first {
        margin-bottom: 32px;
        margin-left: 4px;

        @include media($tablet-breakpoint) {
            margin-bottom: 40px;
            margin-left: 20px;
        }
    }

    &__conditions-second {
        @include unstyled();
        display: grid;
        grid-gap: 32px;
        margin-bottom: 32px;
        margin-left: 4px;

        @include media($tablet-breakpoint) {
            color: $white;
            margin-bottom: 0;
            margin-left: 0;
        }

        .conditions {
            @include media-range($tablet-breakpoint, 800) {
                display: grid;
                grid-gap: 16px;
                grid-template-columns: 50% 50%;
            }

            @include media(800) {
                display: flex;
                margin-bottom: 0;
            }
        }
    }

    &__left-col {
        @include media($desktop-breakpoint, down) {
            margin-bottom: calc(112px - 40px);
        }

        @include media($desktop-breakpoint) {
            grid-row: 1/-1;
        }

        > .person {
            @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                margin-left: 20px;
            }

            @include media($desktop-breakpoint, down) {
                border: none;
                margin-bottom: 56px;
                padding-bottom: 0;
            }

            + .card-full__desc {
                margin-top: 0;
            }
        }
    }

    &__right-col {
        z-index: 1;

        @include media($desktop-breakpoint, down) {
            order: -1;
        }

        @include media($desktop-breakpoint) {
            grid-column: 2/3;
            position: sticky;
            top: 72px;

            transition: opacity 0.3s ease-out;
            opacity: 1;
        }
    }

    &__organizer {
        cursor: pointer;

        @include media($tablet-breakpoint) {
            padding-bottom: 8px;
            border-bottom: 1px solid $gs-outline;
            margin-bottom: 40px;
        }

        @include media($desktop-breakpoint) {
            //grid-column: 2/3;
            margin-top: 40px;
            margin-left: 20px;
            padding: 0;
            border: none;
        }
    }

    &__desc {
        margin-top: 16px;
        margin-bottom: 40px;

        @include media($tablet-breakpoint) {
            padding: 38px 0;
            border-top: 1px solid $gs-outline;
            border-bottom: 1px solid $gs-outline;
        }

        @include media($desktop-breakpoint) {
            padding-top: 0;
            border-top: 1px solid transparent;
        }
    }

    &__buttons {
        &_two {
            margin-top: 24px;
            position: fixed;
            bottom: 30px;
            left: 16px;
            width: 100%;
            max-width: calc(100% - 32px);
            z-index: 10;
            display: flex;
            gap: 24px;

            @include media($tablet-breakpoint) {
                left: calc(44px + 56px);
                max-width: calc(100% - 144px);
                bottom: 24px;
            }

            @include media($desktop-breakpoint) {
                position: static;
                max-width: none;
            }

            @include media($tablet-breakpoint, down) {
                gap: 8px;
            }

            .sidebar-open & {
                @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                    left: calc(44px + 56px + 260px);
                }
            }

            .btn {
                width: 100%;

                &--small {
                    padding: 20px 32px;
                }
            }

            @include media($desktop-breakpoint) {
                // position: sticky;
                // top: 0;
                display: grid;
                gap: 24px;
            }

            .c-gs-disabled {
                margin-top: 16px;
            }
        }

        > .btn {
            position: fixed;
            bottom: 30px;
            left: 16px;
            max-width: calc(100% - 32px);
            z-index: 10;

            @include media($tablet-breakpoint) {
                left: calc(44px + 56px);
                max-width: calc(100% - 144px);
                bottom: 24px;
            }

            @include media($desktop-breakpoint) {
                position: static;
                max-width: none;
            }

            .sidebar-open & {
                @include media-range($tablet-breakpoint, $desktop-breakpoint) {
                    left: calc(44px + 56px + 260px);
                }
            }

            &--send {
                @include media($desktop-breakpoint, down) {
                    position: absolute;
                }
            }
        }

        .btn {
            width: 100%;

            &--small {
                padding: 20px 32px;
            }
        }

        @include media($desktop-breakpoint) {
            position: sticky;
            top: 0;
            display: grid;
            gap: 24px;
        }

        &_with-text {
            @include media($desktop-breakpoint, down) {
                position: fixed;
                bottom: 0;
                left: 72px;
                background: white;
                width: calc(100% - 72px);
                min-height: 127px;
                text-align: center;
            }

            @include media($tablet-breakpoint, down) {
                left: 0;
                width: 100%;
                min-height: 136px;
            }
        }
    }

    &__subtitle {
        @include font-style(h3);
        margin-bottom: 16px;

        @include media($tablet-breakpoint) {
            margin-bottom: 24px;
        }
    }

    &__text-content {
        color: $gs-medium-em;
        white-space: pre-wrap;

        h4 {
            font-weight: normal;
        }

        > * {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }

    &__report {
        margin-bottom: 40px;

        @include media($tablet-breakpoint) {
            border-radius: 16px;
            background-color: $gs-background;
            padding: 32px;
        }
    }

    &__map {
        .map {
            height: 184px;

            @include media($tablet-breakpoint) {
                height: 318px;
            }

            > ymaps {
                height: 184px;

                @include media($tablet-breakpoint) {
                    height: 318px;
                }
            }
        }
    }

    &__newsletter {
        background-color: #f7f7fc;
        padding: 24px;
        border-radius: 16px;
        display: grid;
        margin-bottom: 48px;
        order: -2;

        @include media($desktop-breakpoint) {
            order: initial;
        }

        &-footer {
            display: flex;
            gap: 16px;
            margin-top: 24px;
        }

        &-title {
            @include font-style(h3);
            margin-bottom: 10px;
        }

        &-price {
            @include font-style(h4);
        }

        &-content {
            display: grid;
            align-content: space-around;
        }
    }
}
