@import "../../theme";
@import "../../mixins";

.radio-tab {
    display: block;

    input[type="radio"] {
        position: absolute;
        z-index: -10;
        opacity: 0;

        + label {
            @include font-style(body2);
            color: $gs-label;
            position: relative;
            display: flex;
            align-items: center;
            padding: 8px 0 16px 0;
            min-height: 50px;
            cursor: pointer;
            margin-bottom: 16px;


            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                color: $gs-medium-em;
            }

            &:focus {
                color: $gs-medium-em;
            }
        }

        &:checked {
            + label {
                color: $gs-high-em;

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 8px;
                    width: 4px;
                    height: 4px;
                    background: $gs-high-em;
                    border-radius: 50%;
                    transform: translateX(-50%);
                }
            }

            &:hover {
                > label {
                    &::before {
                        border-color: $secondary-dark;
                        background: $secondary-dark;
                    }
                }
            }

            &:focus {
                > label {
                    &::before {
                        border-color: $secondary-default;
                        background: $secondary-default;
                    }
                }
            }
        }

        &:disabled {
            + label {
                cursor: default;
                color: $gs-disabled;

                &::before {
                    //background: $gs-high-em;
                }
            }

            &:checked {
                + label {
                    color: $gs-disabled;
                    &::before {
                        background: $gs-disabled;
                    }

                }
            }
        }
    }
}
