@import "../../../theme";
@import "../../../mixins";

$input-count: 5;

.segmented-input {
    &__wrapper {
        display: flex;
    }

    &__input {
        @include font-style(h1);
        background-color: $gs-background;
        color: $gs-medium-em;
        padding: 12px 16px;
        border-radius: 8px;
        border: 2px solid transparent;

        text-align: center;
        outline: none;
        max-width: calc((100vw - 32px - 12px * 4) / #{$input-count});


        @include media($tablet-breakpoint) {
            max-width: calc((100vw - 32px - 12px * 4 - #{$sidebar-width-short} - 44px * 2) / #{$input-count});
        }

        @include media(768) {
            min-width: 50px;
            max-width: 104px;
        }

        &:not(:last-child) {
            margin-right: 12px;
        }

        &:focus-within {
            background-color: $white;
            border-color: $primary-default;
        }

        .form-input--invalid & {
            border-color: $error-default;
        }
    }
}
