@import "../../theme";
@import "../../mixins";

$z-index: 9000;
$right-sidebar-width: 378px;

.modal {
    display: none;
    position: fixed;
    z-index: $z-index;
    width: 100%;
    max-width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include media($tablet-breakpoint, false) {
        @supports (-webkit-overflow-scrolling: touch) {
            height: 100%;
        }
    }

    @include media($desktop-breakpoint, down) {
        overflow-x: hidden;
        overflow-y: scroll;
    }

    &--open {
        display: block;
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: none;
        outline: none;
        cursor: default;
        background-color: $gs-placeholder;

        opacity: 0;
        transition: opacity 0.3s ease-out;

        .modal--open & {
            opacity: 1;
        }
    }

    &__wrapper {
        width: 100%;
        position: relative;
        top: 0;

        @include media($tablet-breakpoint, down) {
            min-height: 100%;
            display: flex;
        }

        @include media($tablet-breakpoint) {
            //padding: 50px 0;
            min-height: 100%;
        }

        @include media($desktop-breakpoint) {
            //padding: 100px 0;
        }
    }

    &__link-logo {
        position: absolute;
        left: 13.88vw;
        top: 10.16vh;
        width: 188px;
        height: 74px;
        z-index: 10;
    }

    &__container {
        position: relative;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        background: #fff;
        z-index: 10;
        padding: 68px 24px 24px 24px;
        @include shadow(large);

        border-top-right-radius: 16px;
        border-top-left-radius: 16px;

        opacity: 0;
        transition: opacity 0.3s;

        .modal--open & {
            opacity: 1;
        }

        @include media($tablet-breakpoint) {
            height: auto;
            max-width: 550px;
            padding: 56px 96px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 16px;
        }

        @include media($tablet-breakpoint, down) {
            align-self: end;
        }
    }

    &__content {
        width: 100%;
    }

    &__body {
        margin: 0 auto;

        >button[type="submit"],
        >.btn-group-col,
        >.btn {
            @include media($tablet-breakpoint, down) {
                position: absolute;
                bottom: 24px;
                left: 50%;
                transform: translateX(-50%);
                max-width: calc(100vw - 32px);
            }
        }

        .text-block {
            margin-bottom: 32px;

            &-title {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.15px;
            }
        }

        .button-row {
            display: flex;
            width: 100%;
            justify-content: center;
        }
    }

    &__title {
        @include font-style(h2);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gs-high-em;
        margin-bottom: 24px;

        &:empty {
            margin: 0;
        }
    }

    &__close {
        color: $gs-disabled;
        width: 18px;
        height: 18px;
        right: 27px;
        top: 27px;
    }

    &__privacy-policy {
        @include font-style(caption);
        color: $gs-label;

        a {
            color: inherit;
        }
    }

    &__bottom-btn {
        @include media($tablet-breakpoint, down) {
            position: absolute;
            bottom: 24px;
            left: 16px;
            max-width: calc(100vw - 32px);
        }
    }

    &__bottom-btns {
        @include media($tablet-breakpoint, down) {
            position: absolute;
            bottom: 24px;
            left: 16px;
            max-width: calc(100vw - 32px);
            width: 100%;
        }
    }

    &--small {
        .modal {
            &__small-title {
                text-align: center;

                @include media($tablet-breakpoint) {
                    margin-bottom: 24px;
                }
            }

            &__title {
                text-align: center;
                margin-bottom: 0;
            }

            &__wrapper {
                display: flex;
                align-items: flex-start;

                @include media($tablet-breakpoint) {
                    padding: 200px 0;
                }
            }

            &__container {
                @include media($tablet-breakpoint, down) {
                    margin-top: auto;
                    height: auto;
                    //max-height: 40vh;
                }
            }

            &__close {
                @include media($tablet-breakpoint, down) {
                    display: none;
                }
            }
        }
    }

    &--full {
        .modal {
            &__small-title {
                text-align: center;

                @include media($tablet-breakpoint) {
                    margin-bottom: 24px;
                }
            }

            &__title {
                text-align: center;
                margin-bottom: 0;
            }

            &__wrapper {
                display: flex;
                align-items: flex-start;

                @include media($tablet-breakpoint) {
                    padding: 200px 0;
                }
            }

            &__container {
                max-width: calc(100% - 112px);
                padding: 72px 56px 56px;
                //top: 122px;
                //bottom: 122px;
                left: 56px;
                right: 56px;
                transform: translate(0%, -50%);

                @include media($tablet-breakpoint, down) {
                    margin-top: auto;
                    height: auto;
                }
            }

            &__close {
                @include media($tablet-breakpoint, up) {
                    display: none;
                }
            }
        }
    }

    &--small-col {
        .modal {
            &__wrapper {
                display: flex;

                @include media($tablet-breakpoint) {
                    padding: 120px 0;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                align-items: center;

                >button[type="submit"],
                >.btn-group-col,
                >.btn {
                    @include media($tablet-breakpoint, down) {
                        position: static;
                        transform: none;
                        max-width: none;
                    }
                }
            }

            &__container {
                @include media($tablet-breakpoint, down) {
                    margin-top: auto;
                    height: auto;
                }

                @include media($tablet-breakpoint) {
                    max-width: 492px;
                }
            }

            &__btn_group {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &--min-padding {
        .modal {
            &__container {

                @include media($tablet-breakpoint) {
                    max-width: 492px;
                    padding: 56px 32px 32px;
                }
            }

            &__content {
                padding: 40px 24px 24px;
            }

            &__body {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &--new-design {
        .modal {
            &__title {
                @include media($tablet-breakpoint) {
                    font-weight: 400;
                }
            }

            &__close {
                width: 16px;
                height: 16px;
                color: #14142B;
                top: 32px;
                right: 32px;
            }

            &__content {
                @include media($tablet-breakpoint, down) {
                    padding: 0;
                }

                .btn-group-row {
                    width: 100%;

                    .btn--primary {
                        width: inherit;
                    }

                }
            }
        }
    }

    &--withdrawal {
        .modal {
            &__container {
                @include media($desktop-breakpoint) {
                    max-width: 628px;
                    padding: 56px 32px 32px;

                }

                @include media($desktop-breakpoint, down) {
                    max-width: 492px;
                    padding: 56px 32px 32px;
                }

                @include media($tablet-breakpoint, down) {
                    max-width: unset;
                }
            }

            &__content {

                @include media($desktop-breakpoint) {
                    padding: 40px 64px 64px;
                }

                @include media($desktop-breakpoint, down) {
                    padding: 40px 24px 24px;
                }

                @include media($tablet-breakpoint, down) {
                    padding: 24px 0 0;
                }
            }

            &__title {
                @include media($tablet-breakpoint) {
                    font-weight: 400;
                }

                @include media($tablet-breakpoint, down) {
                    margin-bottom: 16px;
                }
            }

            &__close {
                width: 16px;
                height: 16px;
                color: #14142B;
                top: 32px;
                right: 32px;
            }

            &__body {
                @include media($tablet-breakpoint, down) {
                    .reg__form {
                        grid-row-gap: 32px;
                    }
                }
            }
        }
    }

    &--booking {
        .modal {
            &__container {
                max-width: 923px;

                @media screen and (max-width: 1024px) {
                    max-width: 628px;
                }
            }

            &__content {
                padding-top: 16px;
            }

            &__body {
                display: flex;
                flex-direction: column;
                align-items: initial;
                gap: 24px;

                @include media($tablet-breakpoint, down) {
                    gap: 32px;
                }

                .btn-group-row {
                    .btn {
                        width: 100%
                    }
                }

                .radio-container {
                    display: grid;
                    gap: 16px;

                    .fieldset__legend {
                        margin-bottom: 0;
                    }
                }

                .playground-name {
                    font-weight: 700;
                }

                .sportcourt__caption_gray {
                    margin-bottom: 24px;

                    @include media($tablet-breakpoint, down) {
                        margin-bottom: 16px;
                    }
                }
            }

            &__title {
                font-weight: 400;
            }

            &__close {
                width: 16px;
                height: 16px;
                color: #14142B;
                top: 32px;
                right: 32px;
            }

            @include media($tablet-breakpoint, down) {
                &__container {
                    padding-top: 48px;
                    padding-bottom: 0px;
                }

                &__content {
                    padding: 24px 0;
                    // padding-top: 24px;
                }
            }
        }
    }

    &--booking-hint {
        .modal {

            &__container {
                padding-top: 48px;
                // padding-bottom: 0px;
            }

            &__content {
                padding: 0px;
            }

            &__body {
                display: grid;
                gap: 24px;

                @include media($tablet-breakpoint, down) {
                    .sportcourt__booking-hint-title {
                        color: $gs-medium-em;
                    }

                    .sportcourt__booking-hint-subtitle:not(.sportcourt__booking-hint-edit-btn) {
                        color: $gs-medium-em;
                    }

                    .btn-group-row {
                        gap: 16px;
                        justify-content: center;

                        .btn--primary {
                            width: max-content;
                        }
                    }
                }
            }


        }
    }

    &--max-w-628 {
        .modal {
            &__container {
                @include media($tablet-breakpoint) {
                    max-width: 628px !important;
                }
            }
        }
    }

    &--slider {
        .modal {
            &__container {
                width: 100%;
                max-width: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                transform: none;
                background: transparent;
                padding: 0;
            }

            &__content {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            &__body {
                width: 100%;
                height: 620px;
                overflow: hidden;
                max-height: 80vh;
            }

            &__close {
                color: $white;
            }
        }

        &--full {
            .modal {
                &__container {
                    @include media($tablet-breakpoint, down) {
                        align-self: unset;
                    }
                }

                &__body {
                    @media screen and (max-width: 767px) {
                        max-height: 40%;
                    }
                }
            }
        }
    }

    &--city {
        .modal {
            &__title {
                text-align: center;
                margin-bottom: 24px;
            }

            &__wrapper {
                display: flex;

                @include media($tablet-breakpoint) {
                    padding: 120px 0;
                }
            }

            &__body {
                color: $gs-medium-em;

                .grid-3-col {
                    height: auto;
                }
            }

            &__container {
                max-width: 923px;

                @include media($tablet-breakpoint, down) {
                    margin-top: auto;
                    border-radius: 16px 16px 0 0;
                    position: relative;
                    padding: 56px 24px 94px 24px;
                    height: auto;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 24px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 8px;
                        width: 48px;
                        height: 8px;
                        background-color: $gs-outline;
                    }
                }
            }

            &__close {
                @include media($tablet-breakpoint, down) {
                    display: none;
                }
            }

            &__btn_group {
                display: flex;
                align-items: center;
                justify-content: center;

                @include media($tablet-breakpoint, down) {
                    flex-direction: column;
                }

                .city-selector__btn {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--success {
        .modal {
            &__container {
                width: 440px;
            }

            &__btn_group {
                justify-content: center;
                display: flex;

                .btn {
                    width: 125px;
                }
            }
        }
    }

    &--small-city {
        .modal {
            &__wrapper {
                display: flex;

                @include media($tablet-breakpoint) {
                    padding: 120px 0;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                align-items: center;

                >button[type="submit"],
                >.btn-group-col,
                >.btn {
                    @include media($tablet-breakpoint, down) {
                        position: static;
                        transform: none;
                        max-width: none;
                    }
                }

                @include media($tablet-breakpoint, down) {
                    .btn--text {
                        margin-bottom: 0;
                        padding: 0;
                        justify-content: flex-start;
                    }

                    .btn--link {
                        color: $secondary-default;
                    }
                }
            }

            &__container {
                max-width: 623px;

                @include media($tablet-breakpoint, down) {
                    margin-top: auto;
                    height: auto;
                }
            }

            &__btn_group {
                display: flex;
                align-items: center;
                justify-content: center;

                @include media($tablet-breakpoint, down) {
                    flex-direction: column;
                }

                .city-selector__btn {
                    margin-bottom: 0;
                }
            }
        }
    }

    &--delete-profile {
        .modal {
            &__btn_group {
                display: flex;
                justify-content: space-around;
            }
        }
    }

    &--error-video {
        .modal {
            &__item {
                display: grid;
                justify-items: center;
                gap: 24px;
            }
        }
    }

    &--delete-video {
        .modal {
            &__body {
                display: grid;
                justify-content: center;
                gap: 24px;
            }

            &__btn_group {
                display: flex;
                gap: 24px;
            }
        }
    }

    &--school-add-type {
        .modal {
            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    &--filter {
        .modal {
            &__body {
                display: grid;
                gap: 16px;
            }
        }

        .btn-group {
            display: grid;
            gap: 16px;
        }
    }

    &--add-sportcourt {
        .modal {

            &__title {
                font-weight: 400;
            }

            &__overlay {
                height: 1000%;

                @include media($tablet-breakpoint, down) {
                    height: 100%;
                }
            }

            &__container {
                @include media($tablet-breakpoint) {
                    height: auto;
                    max-width: 550px;
                    padding: 106px 56px 56px;
                    position: absolute;
                    left: 50%;
                    top: 10%;
                    transform: translate(-50%);
                    border-radius: 16px;
                }
            }

            &__close {
                width: 16px;
                height: 16px;
                color: #14142B;
                top: 32px;
                right: 32px;
            }

            &__body {
                .game-editor__sportcourts-grid .fieldset__group {
                    gap: 24px;
                }

                .btn-group-col {
                    margin-top: 0;
                }

                .game-editor__sportcourt-container {
                    gap: 24px;
                }
            }
        }
    }

    &--players-search {
        .modal {
            &__container {
                @include media($tablet-breakpoint, down) {
                    max-height: 100%;
                    overflow-y: auto;
                }
            }
        }
    }

    &--keyboard {
        .modal {
            &__container {
                @include media($tablet-breakpoint, down) {
                    overflow-y: auto;

                    min-height: calc(var(--screenHeight) - 120px);

                    //min-height: 180vw;

                    @supports (-webkit-overflow-scrolling: touch) {
                        overflow-y: auto;
                        min-height: auto;
                    }
                }
            }
        }
    }

    &--mobile-page {
        @include media($tablet-breakpoint, down) {
            .modal {
                &__container {
                    background: $gs-background;
                    padding: 16px 16px 24px 16px;
                    border-radius: 0;
                }

                &__body {
                    background: $white;
                    border-radius: 16px;
                    padding-top: 24px;
                    margin-left: -16px;
                    margin-right: -16px;
                    padding-left: 16px;
                    padding-right: 16px;
                    box-shadow: 0 524px 0 500px $white;
                }

                &__title {
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    margin-left: 72-16px;
                    @include font-style(h1);
                }

                &__close {
                    top: 16px;
                    left: 16px;
                    right: auto;
                    width: 24px;
                    height: 24px;

                    background: {
                        image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-back' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.673 4.294a1.205 1.205 0 00-1.578 0L7.327 11.29A.957.957 0 007 12c0 .266.118.522.327.71l7.768 6.996a1.205 1.205 0 001.578 0 .936.936 0 000-1.421L9.693 12l6.98-6.285a.936.936 0 000-1.42z'%3E%3C/path%3E%3C/svg%3E");
                        repeat: no-repeat;
                        size: 24px 24px;
                    }

                    &::before,
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    &--mobile-page-no-head {
        @include media($tablet-breakpoint, down) {
            .modal {
                &__container {
                    padding-top: 0;
                }

                &__close {
                    display: none;
                }
            }
        }
    }

    &--tablet-page {
        @include media($desktop-breakpoint, down) {
            @include media($tablet-breakpoint) {
                left: auto;
                transition: max-width 0.3s;
                max-width: calc(100vw - #{$sidebar-width-short});

                .sidebar-open & {
                    left: calc(260px + 24px);
                }
            }

            .modal {
                &__overlay {
                    display: none;
                }

                &__container {
                    background: $gs-background;
                    padding: 16px 16px 24px 16px;
                    border-radius: 0;
                    position: static;
                    transform: none;
                    max-width: none;

                    @include media-range($tablet-breakpoint,
                        $desktop-breakpoint ) {
                        background: $white;
                        padding: 24px 44px;
                    }

                    @include media($tablet-breakpoint, down) {
                        align-self: unset;
                    }
                }

                &__body {
                    background: $white;
                    border-radius: 16px;
                    padding-top: 24px;
                    margin-left: -16px;
                    margin-right: -16px;
                    padding-left: 16px;
                    padding-right: 16px;
                    box-shadow: 0 524px 0 500px $white;
                }

                &__title {
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    margin-left: 72-16px;
                    @include font-style(h1);
                    font-size: 20px !important;
                    line-height: 24px !important;
                }

                &__close {
                    top: 16px;
                    left: 16px;
                    right: auto;
                    width: 24px;
                    height: 24px;

                    background: {
                        image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-back' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.673 4.294a1.205 1.205 0 00-1.578 0L7.327 11.29A.957.957 0 007 12c0 .266.118.522.327.71l7.768 6.996a1.205 1.205 0 001.578 0 .936.936 0 000-1.421L9.693 12l6.98-6.285a.936.936 0 000-1.42z'%3E%3C/path%3E%3C/svg%3E");
                        repeat: no-repeat;
                        size: 24px 24px;
                    }

                    &::before,
                    &::after {
                        content: none;
                    }

                    @include media($tablet-breakpoint) {
                        top: 24px;
                        left: 44px;
                    }
                }
            }
        }
    }

    &--registration {
        @include media($tablet-breakpoint, down) {
            overflow: auto;
        }

        @include media-range($tablet-breakpoint, $desktop-breakpoint) {
            top: 16px;
        }

        &::before {
            @include media($desktop-breakpoint) {
                content: "";
                position: absolute;
                left: 13.88vw;
                top: 10.16vh;
                width: 188px;
                height: 74px;
                z-index: 10;

                background: {
                    repeat: no-repeat;
                    image: url("/images/logo-white.svg");
                    size: 188px 74px;
                }
            }
        }

        .modal {
            &__close {
                display: none;
            }

            &__wrapper {
                display: flex;
            }

            &__body {
                border-radius: 0;
                background: none;
                padding-top: 0;
                box-shadow: none;

                @include media($tablet-breakpoint) {
                    margin: 0;
                    padding: 0;
                }
            }

            &__container {
                //height: 100%;
                display: flex;
                flex-grow: 1;

                @include media($tablet-breakpoint) {
                    border-top-left-radius: 32px;
                }

                @include media($desktop-breakpoint) {
                    max-width: 55.69444%;
                    transform: none;
                    left: auto;
                    top: 16px;
                    right: 24px;
                    bottom: 16px;
                    padding: 68px 118px 160px 118px;
                    border-radius: 32px;
                    max-height: 100vh;
                    overflow: auto;
                }

                @include media($tablet-breakpoint, down) {
                    align-self: unset;
                }

            }

            &__content {
                display: flex;
                flex-grow: 1;
            }

            &__body {
                display: flex;
                flex-grow: 1;
            }

            &__reg {
                display: flex;
                flex-grow: 1;
            }

            &__overlay {
                display: none;
            }
        }
    }

    &--sign {
        .modal {
            &__body {
                //align-items: center;
            }

            &__wrapper {
                @include media($desktop-breakpoint) {
                    background: {
                        repeat: no-repeat;
                        color: #d65c45;
                        position: 0 0;
                        size: cover;
                        image: url("/images/profile-bg.jpg");
                    }
                }
            }
        }
    }

    &--landlord {
        .modal {
            &__wrapper {
                @include media($desktop-breakpoint) {
                    background: {
                        repeat: no-repeat;
                        color: #d65c45;
                        position: 0 0;
                        size: cover;
                        image: url("/images/landlord-bg.png");
                    }
                }
            }
        }
    }

    &--sidebar {
        @include media($desktop-breakpoint) {
            .modal {
                max-width: $right-sidebar-width;

                &__title {
                    display: none;
                }

                &__container {
                    background: $gs-background;
                    @include shadow(large);
                    left: auto;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    transform: none;
                    border-radius: 0;
                    padding: 104px 24px 24px 24px;
                    max-width: 378px;
                    overflow-y: scroll;

                    animation-name: translate-from-right !important;
                    animation-duration: 0.3s;

                    body.modal-open--sidebar-right.modal-anim-close & {
                        animation-name: translate-to-right !important;
                    }
                }
            }
        }
    }

    &--avatar {
        .modal {
            &__body {
                display: flex;
                flex-direction: column;
            }

            &__container {
                @include media($tablet-breakpoint) {
                    max-width: 740px !important;
                }
            }
        }
    }

    &--mobile-map {
        max-width: 100vh;
        overflow: hidden;

        .modal {
            &__container {
                background: $gs-background;
                padding-bottom: 0;
                align-self: start;
            }

            &__content {
                height: 100%;
                display: flex;
            }

            &__body {
                box-sizing: border-box;
                /*height: 100%;*/
                background: $gs-background;
                display: flex;
                flex-direction: column;

                .adaptive-map {
                    flex-grow: 1;
                }
            }
        }
    }

    &--levels {
        .modal__body {
            &__overflow {
                overflow-y: auto;
                margin-bottom: 24px;

                .text-block {
                    margin-bottom: 24px;

                    &-title {
                        font-family: Comfortaa;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        margin-bottom: 8px;

                        @include media($tablet-breakpoint, down) {
                            font-size: 16px;
                            line-height: 20px;
                            margin-bottom: 12px;
                        }
                    }
                }

                .text-block:last-child {
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    margin-bottom: 0;
                }
            }
        }

        .modal__container {
            max-width: 628px;
        }
    }

    &--balloon {
        .modal {
            &__close {
                filter: drop-shadow(0px 32px 64px rgba(17, 17, 17, 0.08));
                color: #FFFFFF;
                width: 24px;
                height: 24px;
                top: 26px;
                right: 23px;

                &::after,
                &::before {
                    width: 84%;
                    color: inherit;
                    height: 2.2px;
                    background: currentColor;
                    border-radius: 16px;
                }
            }

            &__container {
                padding: 0;

            }
        }
    }

    &--cluster-balloon {
        .cluster-balloon__title {
            position: absolute;
            left: 16px;
            top: 24px;
            color: #14142B;
            font-family: Comfortaa;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.15px;
        }

        .modal {
            &__close {
                color: #14142BBF;
                width: 24px;
                height: 24px;
                right: 16px;
                top: 24px;

                &::after,
                &::before {
                    width: 84%;
                    color: inherit;
                    height: 2.2px;
                    background: currentColor;
                    border-radius: 16px;
                }
            }

            &__body {
                display: grid;
                gap: 16px;
            }

            &__container {
                padding: 72px 16px 16px;

            }

        }
    }
}

body.modal-open {
    //overflow: hidden;
    //position: static !important;
    //top: 0 !important;

    @include media($tablet-breakpoint, down) {
        min-width: 100vw;
    }

    .app {
        filter: blur(5px);
    }

    &--blur-off {
        overflow: initial;
        min-width: auto;
        position: static !important;

        .app {
            filter: none;
        }
    }

    &--blur-off-desktop-down {
        @include media($desktop-breakpoint, down) {
            overflow: initial;
            min-width: auto;

            .app {
                filter: none;
            }
        }
    }

    &--app-hide {
        .app {
            display: none;
        }
    }

    &--over-modal {
        position: relative;

        #modals-container {
            >* {
                &:nth-child(1) {
                    .modal {
                        filter: blur(5px);
                    }
                }
            }
        }
    }
}

html.modal-close,
html.modal-open {
    overflow: auto;

    @include media($tablet-breakpoint) {
        overflow: hidden;
    }
}

/* Sidebar-right animations */
//https://codyhouse.co/demo/side-team-member-bio/index.html

.app {
    @include media($desktop-breakpoint) {
        body.modal-open--sidebar-right.modal-anim-open & {
            transform: translateX(-$right-sidebar-width);
            animation-name: translate-to-left;
            animation-duration: 0.3s;
        }

        body.modal-open--sidebar-right.modal-anim-close & {
            animation-name: translate-from-left !important;
        }
    }
}

@keyframes translate-from-left {
    0% {
        transform: translateX(-$right-sidebar-width);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes translate-to-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-$right-sidebar-width);
    }
}

@keyframes translate-from-right {
    0% {
        transform: translateX($right-sidebar-width);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes translate-to-right {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX($right-sidebar-width);
    }
}

@import "animation-annie";
