@import "../../theme";
@import "../../mixins";

.players-list {
    display: grid;
    grid-row-gap: 32-14px;
    margin-bottom: 32px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 56px;
        max-width: 420px;

        > * {
            &:first-child {
                margin-right: auto;
            }
        }

        > .icon {
            margin-right: 32px;
        }

        &--skipped {
            .person__name {
                color: $gs-disabled;
            }

            &::after {
                content: "Не пришёл";
                @include font-style(caption);

                color: $gs-disabled;
                margin-left: auto;
            }
        }

        &--signed {
            .person__name {
                color: $gs-disabled;
            }

            p {
                color: $gs-disabled;
            }
        }

        &_wrap {
            flex-wrap: wrap;
        }

        &-icons {
            margin: 0 16px;
        }
    }

    &__title {
        @include font-style(body1);
        color: $gs-label;
        margin-bottom: 24px;
        @include media($tablet-breakpoint) {
            margin-bottom: 40px;
        }
    }

    &__right {
        display: flex;

        > .btn--text {
            width: fit-content;
            margin-left: auto;
        }

        > .icon {
            margin-right: 16px;
            margin-left: auto;
        }
    }

    &_no-gap {
        grid-row-gap: 0;
    }

    &--modal {
        margin-top: 24px;
        margin-bottom: 0;
        min-height: 100px;

        @include media($tablet-breakpoint) {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 415px;
        }

        .preloader-fix {
            .preloader {
                background-color: $gs-background;

                &-boundary {
                    position: relative;
                    top: 15px;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    @include media($tablet-breakpoint) {
                        top: 15px;
                    }
                }
            }
        }

        .players-list {
            &__item {
                &_with-padding {
                    padding: 16px 4px;
                    @include media($tablet-breakpoint) {
                        padding: 16px 20px;
                    }
                }
            }
        }
    }


    &__select-interface {
        flex-basis: 100%;
        flex-grow: 100;
        margin-top: 36px;
    }
}
