@import "../../theme";
@import "../../mixins";

.sportcourts-rent {
    padding-right: 0px;
    padding-bottom: 0px;

    .page__wrapper {
        height: calc(100vh - 124px);
        overflow: hidden;

        @include media($tablet-breakpoint, down) {
            height: calc(100vh - 64px);
            overflow: visible;
        }
    }

    @include media($tablet-breakpoint, down) {
        .page__wrapper {
            margin-top: 1rem;

            .btn-mobile {
                padding: 16px 32px;
                width: calc(100% - 31px);
                margin-left: 13px;
                margin-bottom: 8px;
            }
        }
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 24px;
        padding: 24px 0;
        padding-right: 16px;
        align-items: center;

        .filters {
            align-items: center;

            .sidebar__item {
                padding: 0;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-right: 24px;
        height: calc(100vh - 108px);
        overflow-y: scroll;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
            display: none;
        }

        &_hidden {
            display: none;
        }

        @include media($desktop-breakpoint, down) {
            padding-right: 8px;
            height: calc(100vh - 140px);
            padding-bottom: 60px;
        }

        @include media($tablet-breakpoint, down) {
            padding-left: 13px;
            padding-right: 18px;
            gap: 16px;
            height: 100%;
            overflow-y: initial;
        }

    }

    &__map_view {
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
        box-shadow: 0px 32px 64px 0px rgb(17 17 17 / 8%);
        overflow: hidden;
        display: block;

        @include media($tablet-breakpoint, down) {
            overflow: inherit;
            border-radius: 0;
        }

        &_hidden {
            display: none;
        }

        .map {

            [class^="ymaps-"] {
                [class^="ymaps-"][class*="balloon_layout_panel"] {
                    border-radius: 16px 16px 0 0;
                    background-color: none;
                    box-shadow: none;
                    padding: 0;
                }

                [class^="ymaps-"][class*="balloon__content"] {
                    margin: 0;
                    padding: 0;
                }

                [class^="ymaps-"][class*="balloon__close"] {
                    top: 26px;
                    right: 23px;

                    [class*="balloon__close-button"] {
                        width: 24px;
                        height: 24px;
                        background-image: url(../../../images/icons/close-white.svg);
                        opacity: 1;
                    }
                }

            }
        }
    }

    &__placemark {
        width: 19px;
        height: 19px;
        border-radius: 100px;
        border: 3px solid #FFF;
        background-color: #FF9800;
        box-shadow: 0px 20px 24px 0px rgba(17, 17, 17, 0.06);
        color: #FFF;

    }

    &__cluster-placemark {
        width: 45px;
        height: 45px;
        border-radius: 100px;
        border: 3px solid #FFF;
        background-color: #FF9800;
        box-shadow: 0px 20px 24px 0px rgba(17, 17, 17, 0.06);
        color: #FFF;

        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-feature-settings: 'clig' off, 'liga' off;

    }

    &__btn-group {
        gap: 13px;
        margin-bottom: 8px;

        .btn--small {
            padding: 8px 16px;
        }

        .mr-15 {
            margin-right: 12px;
        }

        @include media($tablet-breakpoint, down) {
            justify-content: space-around;
            margin-left: 13px;
            margin-right: 18px;
        }
    }

    &__button {
        color: $gs-medium-em;

        &:hover {
            color: $secondary-default;
        }

        &_active {
            color: $secondary-default;
        }
    }
}

.sportcourts-rent-card {
    display: grid;
    grid-template-columns: minmax(min-content, 240px) 1fr;
    box-shadow: 0px 20px 24px 0px rgba(17, 17, 17, 0.06);
    gap: 16px;
    align-items: center;
    background-color: white;
    border-radius: 16px;
    position: relative;

    @include media($tablet-breakpoint, down) {
        grid-template-columns: 1fr;
        gap: 32px;
    }

    &__title {
        @include font-style(h4);
        color: $gs-medium-em;

        @include media($tablet-breakpoint, down) {
            @include font-style(body2);
        }

    }

    &__img {
        background-image: url(../../../images/classic_volleyball.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        width: 100%;
        max-height: 184px;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            min-height: 100%;
            object-position: center;
            object-fit: cover;
        }

        @include media($tablet-breakpoint, down) {
            border-radius: 16px 16px 0px 0px;
            height: 176px;
        }
    }

    &__price {
        @include font-style(body2);
        color: $secondary-default;
        margin-top: 16px;

        @include media($tablet-breakpoint, down) {
            margin-top: 0;
            // left: 21px;
        }
    }

    &__booking {
        padding: 4px 8px;
        align-items: center;
        border-radius: 8px;
        background-color: #84FF76;
        @include font-style(caption);
        color: $gs-medium-em;
        position: absolute;
        top: 12px;
        left: 12px;

        @include media($tablet-breakpoint, down) {
            top: 26px;
            left: 21px;
        }
    }

    &__content {
        padding: 24px 0;

        @include media($tablet-breakpoint, down) {
            padding: 16px 24px;

            .card__meta {
                position: absolute;
                padding: 8px 12px;
                top: 144px;
                left: 21px;

                p {
                    margin-bottom: 0;
                }

                p:nth-child(2) {
                    color: #9F9EB0;
                }
            }
        }
    }


}