@import "../../theme";
@import "../../mixins";

.reg {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;

    &--not-found {
        .btn-group--bottom {
            @include media($tablet-breakpoint, down) {
                left: 0;
            }
            @include media($desktop-breakpoint) {
                display: flex;
            }
        }

        .btn {
            @include media($desktop-breakpoint) {
                width: auto;
                margin-right: auto;
            }
        }
    }

    &--landlord {
        .btn {
            @include media($desktop-breakpoint) {
                width: auto;
                justify-self: end;
            }
        }
    }

    &__top-buttons {
        width: 100%;

        @include media($tablet-breakpoint) {
            position: absolute;
            top: 0;
            right: 0;
            min-height: 56px;
        }

        .close {
            display: block;
            left: 0;
            right: auto;
            top: 0;
            width: 24px;
            height: 24px;

            background: {
                image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon-back' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.673 4.294a1.205 1.205 0 00-1.578 0L7.327 11.29A.957.957 0 007 12c0 .266.118.522.327.71l7.768 6.996a1.205 1.205 0 001.578 0 .936.936 0 000-1.421L9.693 12l6.98-6.285a.936.936 0 000-1.42z'%3E%3C/path%3E%3C/svg%3E");
                repeat: no-repeat;
                size: 24px 24px;
            }

            &::before,
            &::after {
                content: none;
            }

            @include media($tablet-breakpoint) {
                top: 8px;
            }
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //min-height: 56px;

        @include media($tablet-breakpoint) {
            margin-bottom: 56px;
        }

        &--extended {
            @include media($tablet-breakpoint, down) {
                min-height: 128-16px;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 56-32px;
        color: $gs-high-em;
        margin-bottom: 16px;

        @include media($tablet-breakpoint, down) {
            margin-left: 72-16px;
            max-width: calc(100vw - 72px - 16px);
        }

        @include media($tablet-breakpoint) {
            margin-top: Min(10%, 108px);
        }

        &--center {
            text-align: center;
        }
    }

    &__body {
        color: $gs-medium-em;
        display: flex;
        flex-grow: 1;
        //flex-direction: column;
        flex-wrap: wrap;

        @include media($tablet-breakpoint, down) {
            flex-direction: column;
            background: $white;
            border-radius: 16px;
            padding-top: 24px;
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
            box-shadow: 0 524px 0 500px $white;
        }

        &--row {
            flex-direction: row;

            @include media($tablet-breakpoint, down) {
                background: none;
                //margin-top: -96px;
                margin-bottom: -24px;
            }
        }
    }

    &__intro {
        margin-bottom: 16px;

        &--avatar {
            margin-bottom: 44px;
        }
    }

    &__form {
        display: grid;
        grid-row-gap: 16px;

        @include media($tablet-breakpoint) {
            grid-row-gap: 24px;
        }

        @supports not (grid-template-rows: min-content) {
            display: block;

            > * {
                &:not(:last-child) {
                    margin-bottom: 16px;

                    @include media($tablet-breakpoint) {
                        margin-bottom: 24px;
                    }
                }
            }
        }

        &--avatar {
            flex-grow: 0.7;

            @include media($tablet-breakpoint) {
                flex-grow: 1;
            }

            > .form-input {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &--lg-row {
            @include media($desktop-breakpoint) {
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 24px;
            }
        }

        &--small-row {
            gap: 16px;
        }

        .profile__gender {
            @include media($tablet-breakpoint, down) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    &__main {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        min-height: 70%;
        min-width: 100%;

        @include media($tablet-breakpoint) {
            margin-bottom: 24px;
        }

        @include media($tablet-breakpoint, down) {
            flex-grow: 1;
        }

        &--center {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 100%;

            .reg__submit {
                margin: 0 auto;
                width: fit-content;
            }
        }

        //.modal--sign & {
        //    min-height: auto;
        //}

        p {
            max-height: 999999px;
        }
    }

    &__footer {
        @include media($tablet-breakpoint, down) {
            //order: -1;
            margin-bottom: 24px;
        }

        @include media($tablet-breakpoint) {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--order {
            margin-top: 0;
            flex-grow: 1;

            @include media($tablet-breakpoint, down) {
                order: -1;
                flex-grow: 0;
            }
        }

        .modal--sign & {
            margin-top: auto;
            margin-left: auto;

            @include media($tablet-breakpoint) {
                margin-top: 0;
            }

            @include media($tablet-breakpoint, down) {
                width: 100%;

                .btn-group {
                    flex-grow: 1;
                }

                .btn {
                    min-width: 100%;
                }
            }
        }
    }

    &__progress {
        @include media($tablet-breakpoint) {
            flex-grow: 1;
            margin-right: 48px;
        }

        > .players-range {
            margin-bottom: 10px;
        }
    }

    &__skip {
        margin: 0 auto;

        @include media($tablet-breakpoint) {
            position: absolute;
            top: -3px;
            right: 0;
            z-index: 10;
        }

        @include media($desktop-breakpoint) {
            top: -8px;
        }
    }

    &__btn-group {
        align-self: center;
        @include media($tablet-breakpoint, down) {
            align-self: auto;
            left: 0;
        }
    }

    &__success {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: auto;
    }

    > .grid-row-24 {
        flex-grow: 1;
        grid-template-rows: min-content min-content;

        @include media($tablet-breakpoint, down) {
            display: flex;
            flex-direction: column;
            grid-gap: 0;
        }

        > .reg {
            &__top {
                @include media($tablet-breakpoint) {
                    margin-bottom: 56-24px;
                }
            }
        }
    }
}

.reg-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;

    max-width: 1920px;
    grid-column: 1/-1;
    grid-row: 1;
    z-index: 100;
    margin: 0 auto;

    @include media($desktop-breakpoint) {
        background: {
            repeat: no-repeat;
            color: #d65c45;
            image: url("/images/profile-bg.jpg");
        }
    }
}
