@import "../theme";
@import "../mixins";

.hidden {
    display: none !important;
}

.flex {
    display: flex;

    &.fww {
        flex-wrap: wrap;
    }

    &.jsb {
        justify-content: space-between;
    }

    &.jce {
        justify-content: flex-end;
    }

    &.jcc {
        justify-content: center;
    }

    &.aic {
        align-items: center;
    }

    &.asc {
        align-self: center;
    }

    &.fdc {
        flex-direction: column;
    }
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-grow-1-desktop-up {
    @include media($desktop-breakpoint) {
        flex-grow: 1;
    }
}

.hide-tablet-down {
    @include media($tablet-breakpoint, down) {
        display: none !important;
    }
}

.hide-tablet-up {
    @include media($tablet-breakpoint) {
        display: none !important;
    }
}

.hide-desktop-down {
    @include media($desktop-breakpoint, down) {
        display: none !important;
    }
}

.hide-desktop-up {
    @include media($desktop-breakpoint) {
        display: none !important;
    }
}

.m-x-auto {
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mb-4-16 {
    margin-bottom: 4px !important;

    @include media($tablet-breakpoint) {
        margin-bottom: 16px !important;
    }
}

.mb-8-24 {
    margin-bottom: 8px !important;

    @include media($tablet-breakpoint) {
        margin-bottom: 24px !important;
    }
}

.mb-16-24 {
    margin-bottom: 16px !important;

    @include media($tablet-breakpoint) {
        margin-bottom: 24px !important;
    }
}

.mb-24-40 {
    margin-bottom: 24px !important;

    @include media($tablet-breakpoint) {
        margin-bottom: 40px !important;
    }
}

.mb-32-40 {
    margin-bottom: 32px !important;

    @include media($tablet-breakpoint) {
        margin-bottom: 40px !important;
    }
}

.mb-56-80 {
    margin-bottom: 56px !important;

    @include media($tablet-breakpoint) {
        margin-bottom: 80px !important;
    }
}

.mb {
    $i: 4;

    @while $i < 41 {
        &-#{$i} {
            @include mb(#{$i});
        }

        $i: $i + 4;
    }
}

.mt {
    $i: 4;

    @while $i < 41 {
        &-#{$i} {
            @include mt(#{$i});
        }

        $i: $i + 4;
    }
}

.ml-20-tablet {
    @include media($tablet-breakpoint) {
        margin-left: 20px;
    }
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-15 {
    margin-right: 15px;
}

.mr-24 {
    margin-right: 24px !important;
}

.mr-32 {
    margin-right: 32px;
}

.pa-20 {
    padding: 20px;
}

.rounded-15 {
    border-radius: 15px;
}

.w-430-tablet-down {
    width: 430px;

    @include media($tablet-breakpoint, down) {
        width: 100%;
    }
}

.w-100-tablet-down {
    @include media($tablet-breakpoint, down) {
        width: 100%;
    }
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-100-max {
    width: 100%;

    @include media($tablet-breakpoint) {
        width: max-content;
    }
}

.w-125 {
    width: 125px;
}

.max-w-480 {
    max-width: 480px;
}

.max-w-568 {
    max-width: 568px;
}

.max-w-630 {
    max-width: 630px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center-tablet {
    @include media($tablet-breakpoint) {
        text-align: center;
    }
}

.text-center-mobile {
    @include media($tablet-breakpoint, down) {
        text-align: center;
    }
}

.text-nowrap {
    white-space: nowrap;
}

.position-relative {
    position: relative;
}

.grid-row-24 {
    display: grid;
    grid-row-gap: 24px;

    @supports not (grid-template-rows: min-content) {
        display: block;

        >* {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }
}

.grid-row-32 {
    display: grid;
    grid-row-gap: 32px;

    @supports not (grid-template-rows: min-content) {
        display: block;

        >* {
            &:not(:last-child) {
                margin-bottom: 32px;
            }
        }
    }
}

.grid-3-col {
    grid-template-columns: repeat(3, 1fr);
    max-height: 300px;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

    @include media($desktop-breakpoint, down) {
        max-height: none;
        height: auto;
    }

    .btn--medium {
        padding: 0;
        justify-content: flex-start;
        margin-bottom: 0;
    }
}

.grid-2-col {
    grid-template-columns: repeat(2, 1fr);

    .btn--medium {
        padding: 0;
        justify-content: flex-start;
        margin-bottom: 0;
    }
}

.grid-row-col-24 {
    display: grid;
    grid-row-gap: 24px;

    @supports not (grid-template-rows: min-content) {
        display: block;

        >* {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }
    }

    @include media($desktop-breakpoint) {
        display: flex;
        grid-gap: 0;

        >* {
            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }
}
