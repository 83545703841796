@import "../../theme";
@import "../../mixins";

.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    //padding: 0 32px;
    max-width: 1216px;
    margin: 0 auto;

    .modal--restaurant & {
        height: 100%;
    }
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;

    .modal--restaurant & {
        height: 100%;
    }
}

.slick-list:focus {
    outline: 0;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    top: 0;
    left: 0;
    display: flex;
    margin: 0 auto;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-slide {
    display: none;
    //float: left;
    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized {
    .slick-slide {
        display: block;
        outline: none;

        * {
            outline: none;
        }

        .modal--restaurant & {
            display: block;
            width: 100%;
        }
    }

    .modal--product & {
        .slick-slide {
            display: flex;
            max-width: 1216px;

            > div {
                width: 100%;
            }

            @include media($desktop-breakpoint, d) {
                margin: 0 4px;
            }

            &:not([class*="slick-current"]) {
                .modal__close {
                    display: none;
                }
            }

            .dish-slide {
                display: flex !important;
                align-items: flex-start;

                .product-item {
                    flex-basis: auto;
                    flex-shrink: 1;
                    flex-grow: 1;

                    @include media($desktop-breakpoint) {
                        flex-shrink: 0;
                        flex-grow: 0;
                        flex-basis: calc(100% - (176px * 2) - (32px * 2));
                        max-width: calc(100% - (176px * 2) - (32px * 2));
                    }
                }
            }
        }

        .slick-list {
            padding-top: 40px !important;
            margin-top: -40px !important;
        }
    }
}

.slick-arrow {
    &.slick-hidden {
        display: none;
    }

    &.slick-disabled {
        opacity: 0;
        visibility: hidden;
    }

    .modal--product & {
        top: 464px;
        transform: none;
    }
}

.slick-dots {
    @include unstyled();
    position: absolute;
    //display: flex;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    li {
        margin-right: 8px;
        float: left;

        &:last-child {
            margin-right: 0;
        }

        button {
            @include unstyled();
            font-size: 0;
            color: transparent;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $white;
            opacity: 0.5;
            cursor: pointer;
        }

        &.slick-active button {
            opacity: 1;
        }
    }
}

.slick-next,
.slick-prev {
    @include unstyled();
    position: absolute;
    display: block;
    z-index: 2;
    font-size: 0;
    line-height: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 24px;
    height: 24px;
    color: transparent;
    outline: none;
    cursor: pointer;
    background: {
        position: 0 0;
        color: transparent;
        repeat: no-repeat;
        size: 100%;
    }

    &:hover,
    &:focus {
        opacity: 0.8;
    }
}

.slick-prev {
    left: 0;
    background-image: url("/images/arrow-left.svg");
}

.slick-next {
    right: 0;
    background-image: url("/images/arrow-right.svg");
}

.slider-hall {
    height: 272px;
    margin: 0 -16px;
    width: calc(100% + 32px);
    margin-bottom: 25px;

    @include media($tablet-breakpoint) {
        height: 184px;
        margin: 30px -16px 0;
        width: calc(100% + 32px);
    }

    .slick-slider {
        height: 100%;
        max-width: none;
    }

    .slick-list {
        height: 100%;
    }

    .slick-track {
        display: flex;
        align-items: stretch;
        height: 100%;
    }

    .slick-slide {
        align-self: stretch;
        flex: 0;
        flex-basis: calc((100% - 32px * 2) / 3);
        margin: 0;

        @include media($tablet-breakpoint) {
            margin: 0;
            padding: 0 16px;
        }

        > div {
            height: 100%;
        }
    }

    .slick-arrow {
        width: 18px;
        height: 18px;
    }

    .slick-prev {
        background-image: url("/images/arrow-left-gray.svg");
        transform: translate(-15px, -50%);
    }

    .slick-next {
        background-image: url("/images/arrow-right-gray.svg");
        transform: translate(15px, -50%);
    }

    &__slide {
        outline: none;
        height: 100%;
        overflow: hidden;

        @include media($tablet-breakpoint) {
            border-radius: 6px;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: none;
            object-fit: cover;
        }
    }
}

.slider-hall-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;

    @include media($tablet-breakpoint) {
        margin-bottom: 60px;
    }

    &__text {
        @include media($tablet-breakpoint, down) {
            order: 2;
        }
    }
}
