@import "theme/colors";
@import "theme/typography";

$desktop-breakpoint: 1280;
$tablet-breakpoint: 600;

$shadows: (
    large: (0px 32px 64px rgba(17, 17, 17, 0.08)),
    medium: (0px 20px 24px rgba(17, 17, 17, 0.06)),
    small: (0px 4px 8px rgba(17, 17, 17, 0.06))
);


$shadow-icon: 0 15px 20px rgba(0, 0, 0, 0.2);
$shadow-button: 0 10px 20px rgba(66, 133, 244, 0.2);
$shadow-req: 0 20px 40px rgba(233, 30, 99, 0.2);


$sidebar-width-short: 72px;
$sidebar-width-tablet: 236px;
$sidebar-width-lg: 260px;


//$sidebar-width: 56px;
