@import "../../../theme";
@import "../../../mixins";

.fieldset {
    &__legend {
        @include font-style(overline);
        color: $gs-label;
        margin-bottom: 16px;
    }

    &__group {
        display: grid;
        grid-gap: 32px;

        @include media($tablet-breakpoint) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
