@import "../../../theme";
@import "../../../mixins";

.form-input__input {
    flex-grow: 1;
    width: 100%;
    padding: 18px 16px 8px 16px;
    font-size: inherit;
    resize: none;
    min-height: 56px;
    background-color: $gs-background;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;

    @include media($tablet-breakpoint) {
        min-height: 64px;
        padding: 24px 16px 8px 16px;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 56px $gs-background inset !important;

        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: 0 0 0 56px $gs-background inset !important;
        }
    }

    &::-ms-clear {
        display: none;
    }

    &:focus {
        background-color: $white;
        border: 2px solid $primary-default;
    }

    &:disabled {
        //border-color: $light_grey;
        cursor: default;
        //color: $gs-disabled;
        pointer-events: none;
    }

    &::placeholder {
        color: $gs-placeholder;
    }
}
