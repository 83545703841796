@import "../../theme";
@import "../../mixins";


/* Animations Annie */
//https://tympanus.net/Development/DialogEffects/annie.html

.modal__overlay {
    transition-duration: 0.3s;
}

body.modal-anim-open .modal--with-animation .modal__container {
    -moz-animation-duration: 0.5s;
    -moz-animation-iteration-count: 1;
}

body.modal-anim-open .modal--with-animation .modal__container,
body.modal-anim-open .modal--with-animation .modal__content {
    animation-name: anim-open;
}

body.modal-anim-open .modal--with-animation .modal__content,
body.modal-anim-close .modal--with-animation .modal__content {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

body.modal-anim-close .modal--with-animation .modal__container,
body.modal-anim-close .modal--with-animation .modal__content {
    animation-name: anim-close !important;
}

@keyframes anim-open {
    0% {
        opacity: 0;
        top: calc(50% + 50px);
    }
    100% {
        opacity: 1;
        top: 50%;
    }
}

@keyframes anim-close {
    0% {
        opacity: 1;
        top: 50%;
    }
    100% {
        opacity: 0;
        top: calc(50% + 50px);
    }
}

/* Inner elements animations */

@keyframes anim-elem {
    0% {
        opacity: 0;
        transform: translate3d(0, 25px, 0);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
