@import "../../../theme";
@import "../../../mixins";

.form-input {
    border: 0;
    padding: 0;
    //margin: 0 0 24px;
    position: relative;

    &.focus-within {
        //border: 2px solid red;

        .form-input__label {
            top: 12px;
            @include font-style(overline);
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &--file {
        //justify-content: center;
        margin-bottom: 24px;

        .form-input__label {
            position: static;
            display: none;
        }
    }

    &--with-icon {
        .form-input {
            &__input {
                padding-left: 56px;
                //padding: 24px 16px 8px 56px;
            }

            &__label {
                left: 58px;
            }
        }

        >.icon-container {
            position: absolute;
            left: 16px;
            z-index: 1;
            top: 16px;
            //top: 50%;
            //transform: translateY(-50%);

            @include media($tablet-breakpoint) {
                top: 20px;
            }
        }
    }

    &--with-cm {
        &::after {
            content: "";
            font: inherit;
            position: absolute;
            left: 89px;
            top: 24px;

            @media not all and (min-resolution: 0.001dpcm) {
                /* Safari 10.1+ */
                top: 29px;
            }

            @include media($tablet-breakpoint) {
                top: 31px;
            }
        }

        &.form-input--filled,
        &:focus-within {
            &::after {
                content: "\0441\043c";
            }
        }
    }

    &--is-invalid,
    &--invalid {
        .form-input {
            &__input {
                border-color: $error-default;
            }

            &__label {
                color: $error-dark;
            }
        }
    }

    &--empty {
        padding-top: 16px;
    }

    &_full-width {
        width: 100%;
    }

    &--select {
        cursor: pointer;

        &.form-input--invalid {
            .form-select {
                &__control {
                    border: 2px solid $error-default;
                }
            }
        }

        &--multi {
            .form-select-parent {
                padding: 0px;

                .form-select {
                    position: inherit;

                    &__placeholder {
                        font-family: Roboto, "Arial", sans-serif;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                        color: rgba(94, 92, 128, 0.38);
                        position: absolute;
                        top: 18px;
                        left: 16px;
                        z-index: 10;
                        transition: all 0.3s;
                        pointer-events: none;
                    }
                }
            }

        }

        .form-select-parent {
            min-height: 56px;

            @include media($tablet-breakpoint) {
                min-height: 64px;
            }

            .form-select {
                &__control {
                    min-height: 56px;
                    border-radius: 8px;

                    @include media($tablet-breakpoint) {
                        min-height: 64px;
                    }
                }

                &__value-container {
                    padding: 10px 20px 10px 20px;
                    min-height: 100%;
                }

                &__menu {
                    z-index: 20;
                    display: flex;
                    flex-direction: column;
                }

                &__option {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 48px;
                }

                &__single-value {
                    margin-top: 0;
                    position: absolute;
                    left: 15px;
                }
            }
        }

        &-with-tooltip {
            .flex {
                align-items: center;
                margin-top: 8px;
                cursor: pointer;

                .icon {
                    width: 13.33px;
                    height: 13.33px;
                    color: $gs-label;
                    margin-left: 8px;
                }
            }

            .fs-caption {
                color: $gs-label;
            }
        }
    }

    &--select-metro:first-child {
        @include media($tablet-breakpoint, down) {
            margin-bottom: 16px;
        }
    }

    &--textarea {
        &.form-input--filled {
            .form-input {
                &__label {
                    min-width: 90%;
                    background: $gs-background;
                    box-shadow: 12px -8px 0 0 $gs-background,
                        -12px -10px 0 0 $gs-background,
                        12px 0 0 4px $gs-background,
                        -12px 0 0 4px $gs-background;
                }
            }
        }

        &:focus-within {
            .form-input {
                &__label {
                    background: $white;
                    box-shadow: 12px -8px 0 0 $white, -9px -10px 0 0 $white,
                        12px 0 0 4px $white, 0 0 0 4px $white;
                }
            }
        }

        .form-input {
            &__input {
                padding: 26px 16px 14px 16px;
                min-height: 50px;
                resize: vertical;

                @include media($desktop-breakpoint) {
                    min-height: 150px;
                }
            }
        }
    }

    &--phone {
        .form-input {
            &__prefix {
                position: absolute;
                top: 10px;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                border: 1px solid transparent;
                padding: 18px 16px;
                line-height: 1.3;
                font-size: inherit;
            }

            &__label {}

            &__input {
                //background: transparent;
                padding-top: 28px;
                padding-bottom: 8px;

                &--phone {
                    padding-left: 34px;
                    padding-top: 24px;

                    &-long__prefix {
                        padding-left: 54px;
                    }

                    @include media($tablet-breakpoint) {
                        padding-top: 28px;
                    }
                }
            }
        }
    }

    &__message {
        @include font-style(caption);
        display: block;
        color: $gs-placeholder;
        margin-top: 8px;
        max-height: 999999px;

        &_error {
            color: $error-dark;
        }
    }

    &__required {
        //color: $red;
        font-size: 32px;
        vertical-align: middle;
        line-height: 0;
    }

    &__label {
        @include font-style(body1);
        color: $gs-placeholder;
        position: absolute;
        top: 18px;
        left: 16px;
        z-index: 10;
        //top: 50%;
        //transform: translateY(-50%);
        transition: all 0.3s;
        pointer-events: none;

        @include media($tablet-breakpoint) {
            top: 22px;
        }

        .form-input--filled:not(.form-input--select) &,
        .form-input:not(.form-input--select):focus-within &,
        .form-input.focus-within & {
            top: 12px;
            @include font-style(overline);
        }

        .form-input--select:not(.form-input--select-with-label).form-input--filled & {
            display: none;
        }

        &--is-invalid,
        &--invalid {
            color: $gs-disabled;
        }
    }

    &__textarea {
        min-height: 50px;
        resize: vertical;
    }

    &__input-group {
        position: relative;
        width: 100%;
    }

    &__clear {
        @include unstyled();
        position: absolute;
        right: 14px;
        top: 18px;
        //top: 50%;
        //transform: translateY(-50%);

        @include media($tablet-breakpoint) {
            top: 22px;
        }

        &--modal {
            top: 20px;
            display: none;

            .form-input.focus-within &,
            .form-input:focus-within &,
            .form-input--filled & {
                display: block;
            }
        }
    }

    &__prefix {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border: 1px solid transparent;
        padding: 18px 16px;
        background: transparent;
        color: #424242;

        line-height: 1.3;
        font-size: inherit;

        .form-input--filled &,
        .form-input:focus-within &,
        .form-input.focus-within & {
            display: block;
        }
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 64px;
        height: 64px;
        margin-right: 20px;
        border-radius: 50%;

        background: {
            image: url(/images/icons/camera.svg);
            repeat: no-repeat;
            position: center center;
            color: $primary-default;
        }

        @include media($tablet-breakpoint) {
            width: 86px;
            height: 86px;
            margin-right: 0;
        }

        img {
            max-height: 100%;
            position: absolute;
            object-fit: cover;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 64px;
            height: 64px;

            @include media($tablet-breakpoint) {
                width: 86px;
                height: 86px;
            }
        }
    }

    &__avatar-upload {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: $secondary-default;
        border-radius: 50%;

        position: absolute;
        right: -5px;
        bottom: -5px;

        &_white {
            background-color: white;
        }

        .icon {
            width: 16px;
            height: 16px;
        }
    }

    &:disabled {
        * {
            cursor: default;
        }
    }

    &__white-style {
        input {
            background-color: $white;
        }
    }

    &__header-img {
        background-color: white;
        width: 64px;
        height: 64px;

        background: {
            image: url(/images/icons/export.svg);
            repeat: no-repeat;
            position: center center;

        }
    }

    &--select-with-label {
        cursor: pointer;

        &.form-input--invalid {
            .form-select {
                &__control {
                    border: 2px solid $error-default;
                }
            }
        }

        .form-select-parent {
            min-height: 56px;

            @include media($tablet-breakpoint) {
                min-height: 64px;
            }

            .form-select {
                &__control {
                    min-height: 56px;
                    border-radius: 8px;

                    @include media($tablet-breakpoint) {
                        min-height: 64px;
                    }
                }

                &__value-container {
                    padding: 10px 20px 10px 20px;
                    min-height: 100%;
                }

                &__value-container--is-multi {
                    padding: 18px 20px 10px 7px;
                    min-height: 100%;
                }

                &__menu {
                    z-index: 20;
                    display: flex;
                    flex-direction: column;
                }

                &__option {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 48px;
                }

                &__single-value {
                    margin-top: 8px;
                    position: absolute;
                    left: 15px;
                }
            }
        }

        .form-input--select--multi {
            .form-select-parent {
                padding: 0px;

                .form-select {
                    position: inherit;

                    &__placeholder {
                        font-family: Roboto, "Arial", sans-serif;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                        color: rgba(94, 92, 128, 0.38);
                        position: absolute;
                        top: 18px;
                        left: 16px;
                        z-index: 10;
                        transition: all 0.3s;
                        pointer-events: none;
                    }
                }
            }

        }

    }
}

// focus-within  polyfill
// Почему-то &.focus-within не срабатывает. Дублирую стили полифилла

.form-input--filled {
    .form-input__label {
        @supports not (grid-template-rows: min-content) {
            top: 12px;
            @include font-style(overline);
        }
    }
}

//.focus-within {
.form-input.focus-within {
    .form-input__label {
        top: 12px;
        @include font-style(overline);
    }
}

//}

@import "control";
@import "input";
@import "icon";