@import "../mixins/font";

$fonts: (
    (Regular, normal, normal),
    (Bold, bold, normal),
    (Light, 300, normal),
    (Thin, 100, normal),
    (Medium, 500, normal),
    (SemiBold, 600, normal),
    (ExtraBold, 800, normal),
    (Black, 900, normal),
    (BoldItalic, bold, italic),
    (Italic, normal, italic),
    (LightItalic, 300, italic),
    (MediumItalic, 500, italic)
);

$fontsRoboto: (
    (Regular, normal, normal),
    (Medium, 500, normal),
    (Bold, 700, normal),
);

$fontsComfortaa: (
    (Regular, normal, normal),
    (Bold, bold, normal),
);

// TODO подключить шрифты через font-face взамен гугловых

@each $font, $weight, $style in $fontsRoboto {
    @include font-face("Roboto", "/fonts/Roboto-#{$font}", $weight, $style);
}

@each $font, $weight, $style in $fontsComfortaa {
    @include font-face("Comfortaa", "/fonts/Comfortaa-#{$font}", $weight, $style);
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/fonts/NunitoSans-Regular.woff2') format('woff2'),
        url('/fonts/NunitoSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}