@import "../../theme";
@import "../../mixins";

.person {
    &--profile {
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-gap: 16px;
        align-items: center;
        margin-bottom: 32px;

        @supports not (grid-template-rows: min-content) {
            display: flex;

            > * {
                &:nth-child(1) {
                    min-width: 40px;
                    margin-right: 16px;
                }

                &:nth-child(2) {
                    flex-grow: 1;
                }
            }
        }

        @include media($tablet-breakpoint) {
            grid-template-columns: auto;
            justify-content: center;
            margin-bottom: 40px;
        }

        .person {
            &__avatar {
                margin-bottom: 0;
            }

            &__name {
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                margin-bottom: 0;

                @include media($desktop-breakpoint) {
                    justify-content: center;
                }

                p {
                    &:nth-child(1) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &--row {
        display: flex;
        align-items: center;

        .person {
            &__avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                margin-right: 16px;
                margin-bottom: 0;
                margin-left: 0;
            }

            &__name {
                display: flex;
                flex-wrap: wrap;
                @include font-style(body2);
                margin-bottom: 0;
                color: $gs-medium-em;
                cursor: pointer;

                &:hover,
                &:focus {
                    color: $secondary-dark;
                }
            }
        }
    }

    &_disabled {
        .person__name {
            color: $gs-disabled-alfa;
        }
    }

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 16px auto;
        //margin-bottom: 16px;
        overflow: hidden;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex-shrink: 0;

        background: {
            image: url(/images/icons/camera.svg);
            repeat: no-repeat;
            position: center center;
            color: $primary-default;
        }

        .card & {
            background-size: 20px 18px;
        }

        @include media($tablet-breakpoint) {
            width: 80px;
            height: 80px;
        }

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: contain;
            overflow: hidden;
            font-size: 0;
            margin: -2px;
            max-height: 100%;

            @include media($tablet-breakpoint) {
                width: 80px;
                height: 80px;
            }
        }
    }

    &__name {
        text-align: center;
        margin-bottom: 24px;
        @include font-style(h4);

        p {
            font: inherit;

            &:not(:last-child) {
                margin-right: 4px;
            }
        }
    }

    &_full-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        .person__avatar {
            margin-bottom: 16px;
            width: 100%;
            height: auto;
            max-height: 330px;
            border-radius: 8px;
            background-color: none;
            background: none;

            img {
                width: auto;
                height: auto;
                min-width: 60%;
                max-height: inherit;
                border-radius: 8px;
                object-fit: contain;
                background: none;
            }
        }
    }
}
